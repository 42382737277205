import axios from 'axios'
import { BASEURL } from '../../config'

export const CreateUsers = (obj) => {
    return axios({
      url: `${BASEURL}/v1/users`,
      method: "POST",
      headers: {
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      data: {
       user_list:[obj]
      }
    })
    .then((response) => response.data.response)
    .catch((err) => err);
  };