import React, { useState, useEffect } from "react";
import { Link, withRouter,useHistory } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux'
import { Container, Row, Col, Card, Button,Dropdown, DropdownButton } from "react-bootstrap";

import axios from "axios";
import { BASEURL } from "../../config";

//api imports
import { walletApi } from '../../api/TransactionsApi';

//component imports
import Navbar from "./DashboardNavbar";
import details from "../../assets/Transactions.json";

// import RazorPay from '../RazorPay/RazorPay';

//action imports
// import { storeUserCountryData } from '../../actions/storingUserData'

//css imports
// import '../../assets/scss/Razorpay/razorpay.scss';
import "../../assets/scss/AddBalance/addBalance.scss";

//image imports
import transactionUpArrow from "../../assets/svg/transaction-up-arrow.svg";
import transactionDownArrow from "../../assets/svg/transaction-down-arrow.svg";
import calenderImg from "../../assets/svg/calenderIcon.svg";


const CharityTransactions = () => {
  const [transactionDetails, setTransactionDetails] = useState(details.transactions);
  const charityDetails = useSelector(state => state.newUserToken.cause_info);
  const userType = useSelector(state => state.newUserToken.user_type);

  // const [active, setActive] = useState("INCOMING");
  const [key, setKey] = useState("INCOMING");
  const [balance, setBalance] = useState("");
  const [countryCurrencySign, setCountryCurrencySign] = useState("")
  // let countryCurrencySign = ""
  let charityID;
  if(charityDetails){
    // countryCurrencySign = charityDetails.country_wallet_currency_sign
    charityID = charityDetails.cause_external_id
  }
  const tokenDetails = useSelector(state => state.tokenDetails)
  // const countriesList = useSelector(state => state.countriesList)
  // const userDetails = useSelector(state => state.profileDetailsById)
  // const userCountryDetails = useSelector(state => state.userCountryDetails)
  const userexternalid = useSelector(state => state.newUserToken.db_user_external_id)
  
  // let currencyCode = userCountryDetails[0].country_wallet_currency_sign
  // const dispatch = useDispatch();
  let currencySign;
  let walletBalance;
  const history = useHistory();

    useEffect(() => {
      const fetchBalance = async () => {
        if (userType === "charity") {
        const response = await walletApi(userexternalid,charityID);
        // currencySign = response.response.data.currency_sign;
        walletBalance = response.amount;
        currencySign = response.country_currency_sign;
        setBalance(walletBalance);
        setCountryCurrencySign(currencySign)
        }
      }
      fetchBalance();

      // console.log(transactionDetails);
    }, []);

  //   useEffect(() => {
  //     const fetchBalance = async () => {
  //       const response = await walletApi(tokenDetails.token, tokenDetails.user_external_id);
  //       // console.log(response);
  //       currencySign = response.response.data.currency_sign;
  //       walletBalance = response.response.data.wallet_balance;
  //       setCountryCurrencySign(currencySign)
  //       setBalance(walletBalance);
  //     }
  //     fetchBalance();

  //     // console.log(transactionDetails);
  //   }, [tokenDetails]);


  const incomingArrow = (
    <svg
      width="13"
      height="16"
      viewBox="0 0 13 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.57138 1L5.57138 12.865L1.52278 9.232C1.12907 8.878 0.543134 8.936 0.215346 9.36C-0.11337 9.785 -0.0595131 10.415 0.334204 10.769L5.90567 15.769C5.94931 15.808 6.00038 15.827 6.04867 15.856C6.08767 15.88 6.1211 15.909 6.16381 15.927C6.2706 15.972 6.38296 15.999 6.49624 15.999C6.49717 15.999 6.49903 16 6.49996 16C6.50089 16 6.50274 15.999 6.50367 15.999C6.61696 15.999 6.72932 15.972 6.8361 15.927C6.87882 15.909 6.91225 15.88 6.95125 15.856C6.99953 15.827 7.0506 15.808 7.09425 15.769L12.6657 10.769C12.8858 10.57 13 10.286 13 10C13 9.774 12.9294 9.547 12.7846 9.36C12.4568 8.936 11.8708 8.878 11.4771 9.232L7.42854 12.865L7.42854 1C7.42854 0.448 7.01253 0 6.49996 0C5.98738 0 5.57138 0.448 5.57138 1Z"
        fill="#9E9E9E"
      />
    </svg>
  );

  const outgoingArrow = (
    <svg
      width="13"
      height="16"
      viewBox="0 0 13 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.42862 15L7.42862 3.135L11.4772 6.768C11.8709 7.122 12.4569 7.064 12.7847 6.64C13.1134 6.215 13.0595 5.585 12.6658 5.231L7.09433 0.231C7.05069 0.192 6.99962 0.173 6.95133 0.144C6.91233 0.12 6.8789 0.0909996 6.83619 0.073C6.7294 0.0279999 6.61704 0.0010004 6.50376 0.0010004C6.50283 0.0010004 6.50097 0 6.50004 0C6.49911 0 6.49726 0.0010004 6.49633 0.0010004C6.38304 0.0010004 6.27068 0.0279999 6.1639 0.073C6.12118 0.0909996 6.08775 0.12 6.04875 0.144C6.00047 0.173 5.9494 0.192 5.90575 0.231L0.334288 5.231C0.114215 5.43 0 5.714 0 6C0 6.226 0.0705719 6.453 0.21543 6.64C0.543218 7.064 1.12915 7.122 1.52287 6.768L5.57146 3.135L5.57146 15C5.57146 15.552 5.98747 16 6.50004 16C7.01262 16 7.42862 15.552 7.42862 15Z"
        fill="#9E9E9E"
      />
    </svg>
  );

  // const handleSelect = (k) => {
  //   setKey(k);
  // };

  // const handleTransactions = (type) => {
  //   // console.log(type);
  //   if (type === "INCOMING") {
  //     setKey("INCOMING");
  //   } else {
  //     setKey("OUTGOING")
  //   }

  // }

  // let incomingTransactionDetails = Object.keys(transactionDetails).map(
  //   (obj, index) => {
  //     // console.log(obj)
  //     // if (transactionDetails[obj].type === "INCOMING") {

  //     let transactionDuration = transactionDetails[obj].transactionFromDateTime;
  //     let date = new Date(transactionDuration);

  //     let dateFormat = date.toDateString();
  //     let finalDate = dateFormat.split(' ');

  //     var hours = date.getUTCHours(); //UTC give you the hours without the timezone offset.
  //     var minutes = date.getUTCMinutes();
  //     var ampm = hours >= 12 ? 'pm' : 'am';
  //     hours = hours % 12;
  //     hours = hours ? '0' + hours : 12; // the hour '0' should be '12'
  //     minutes = minutes < 10 ? '0' + minutes : minutes;
  //     var strTime = hours + ':' + minutes + ' ' + ampm;

  //     let dayMonthYear = finalDate[2] + " " + finalDate[1] + " " + finalDate[3] + "," + " " + strTime

  //     return (
  //       <Row key={index} className="transaction-body">
  //         <Col
  //           sm={12}
  //           md={12}
  //           lg={12}
  //           className="transaction-content-wrapper"
  //         >
  //           <div className="transaction-content">
  //             <div className="transaction-content-column">
  //               <div className="img-transaction">
  //                 <img src={transactionDownArrow} alt="transaction-image" />
  //               </div>
  //               <div className="transaction-detail-content">
  //                 <p>Funds withdrawn</p>
  //                 <span style={{ textTransform: "uppercase" }}>
  //                   {dayMonthYear}
  //                 </span>
  //                 <div className="transaction-card-id">
  //                   <span className="transaction-card-end">
  //                     Bank Reference No. {" "}
  //                     {transactionDetails[obj].partial_card_number}
  //                   </span>
  //                   <br />
  //                   <span className="transaction-id">
  //                     Transaction ID {transactionDetails[obj].transactionId}
  //                   </span>
  //                 </div>
  //               </div>
  //             </div>
  //             <div className="transaction-amount">
  //               +{transactionDetails[obj].currency}
  //               {transactionDetails[obj].amount}
  //             </div>
  //           </div>
  //         </Col>
  //       </Row>
  //     );
  //     // }
  //   }
  // );

  // let userCountryDetails = countriesList.data.filter(country => {
  //   if (userDetails.data.country_name === country.country_name) {
  //     return country
  //   }
  // })

  // dispatch(storeUserCountryData(userCountryDetails))

  // if (!userCountryDetails) return null

  return (
    <>
      <div>
        <Navbar />
        <section style={{height:"700px"}} className="addBalance-content">
          <Container>

            <Row>
              <Col sm={12} md={12} lg={10} className="mx-auto">
                <Row className="my-3 ml-1 heading">
                  <Col >
                    <span className="mt-3" style={{ fontSize: "22px", fontWeight: "500", }}>Wallet</span>
                  </Col>
                </Row>
                <div>
                  <Card style={{padding:"2rem"}} className="pin-fav mb-4">
                    <Card.Body>
                      <div>
                        <Card.Text style={{ margin: "0" }}>
                          Wallet Balance
                      </Card.Text>
                        {/* <Card.Text>{userCountryDetails[0].country_currency_sign} {balance}</Card.Text> */}
                        <Card.Text>{countryCurrencySign} {balance}</Card.Text>
                      </div>
                     {/* <Link to="/withdraw">Withdraw</Link> */}
                    {balance ? <Button onClick={()=>history.push("/withdraw")}>Withdraw</Button> : <Button disabled onClick={()=>history.push("/withdraw")}>Withdraw</Button>}
                      {/* <RazorPay /> */}
                    </Card.Body>


                    {/* <Card.Body>
                      <div>
                        <Card.Text style={{ margin: "0" }}>
                          Stripe India
                      </Card.Text>
                        <Card.Text>{countryCurrencySign} {balance}$500</Card.Text>
                      </div>
                      <Link to="/pay">Withdraw</Link>
                    </Card.Body> */}

                    {/* <Card.Body>
                      <div>
                        <Card.Text style={{ margin: "0" }}>
                          India
                      </Card.Text>
                        <Card.Text>{userCountryDetails[0].country_currency_sign} {balance}</Card.Text>
                        <Card.Text>{countryCurrencySign} 0</Card.Text>
                      </div>
                      <Button disabled onClick={()=>history.push("/withdraw")}>Withdraw</Button>
                      <RazorPay />
                    </Card.Body> */}

                    {/* <Card.Body>
                      <div>
                        <Card.Text style={{ margin: "0" }}>
                          Rapyd
                      </Card.Text>
                        <Card.Text>-</Card.Text>
                      </div>
                      <Button disabled onClick={()=>history.push("/withdraw")}>Withdraw</Button>
                    </Card.Body> */}
                  </Card>
                  {/* <Row className="my-3 heading">
                    <Col >
                      <span className="mt-4 ml-1" style={{ fontSize: "22px", fontWeight: "500", }}>Recent Transactions</span>
                    </Col>
                    <div className="dropdown-container">
                        <img className="dropdown-arrow" src={calenderImg} alt="dropdownButtonArrow" />

                        <DropdownButton className="dropdown-btn" title={<span className='dropdown-span'>Filter</span>}>
                            <Dropdown.Item eventKey='Today'>Today</Dropdown.Item>
                            <Dropdown.Item eventKey='Week'>This week</Dropdown.Item>
                            <Dropdown.Item eventKey='Month'>This month</Dropdown.Item>
                            <Dropdown.Item eventKey='3 Months'>Last 3 months</Dropdown.Item>
                        </DropdownButton>
                    </div>
                  </Row>
 */}


                  {/* <div className="transaction-details">
                    <Row className="transaction-header">
                      <Col className="transaction">
                        <div className="transaction-detail-container" />
                          {incomingTransactionDetails}
                        </div>
                      </Col>
                    </Row>
                  </div> */}

                  {/* <Link className="transactionButton" to="/transactiondetails">See All Transactions</Link> */}
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </div>
    </>
  );
};

export default withRouter(CharityTransactions);
