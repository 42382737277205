import axios from 'axios'
import { BASEURL } from '../../config'

export const ListAllUsers = (usertype,Isactive) => {
    return axios({
      url: `${BASEURL}/v1/list-dashboard-users`,
      method: "POST",
      headers: {
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      data:{
          user_type:usertype,
          is_active:Isactive
      }
    })
    .then((response) => response.data.response.data)
    .catch((err) => err);
  };

  export const ListAllRoles = () => {
    return axios({
      url: `${BASEURL}/v1/roles/listAllRoles`,
      method: "POST",
      headers: {
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    })
    .then((response) => response.data.response.data)
    .catch((err) => err);
  };

  export const UpdateUserStatus = (userId,isActive) => {
    return axios({
      url: `${BASEURL}/v1/update/user-status`,
      method: "POST",
      headers: {
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      data:{
        db_user_external_id:userId,
        is_active:isActive
    }
    })
    .then((response) => response.data.response.data)
    .catch((err) => err);
  };


  export const UpdateUserRole = (userId,roleId) => {
    return axios({
      url: `${BASEURL}/v1/user_roles/updateUser_Role`,
      method: "POST",
      headers: {
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      data:{
        db_user_external_id:userId,
        role_external_id:roleId
    }
    })
    .then((response) => response.data.response)
    .catch((err) => err);
  };


  export const updateUserDataAPI = (userId,countryId,charityId) => {

    let obj;
    if(countryId && charityId){
      obj = {
        db_user_external_id:userId,
        country_external_id:countryId,
        cause_external_id:charityId,
    }
    }else if(countryId){
     obj = {
        db_user_external_id:userId,
        country_external_id:countryId,
    }} else if(charityId){
      obj = {
        db_user_external_id:userId,
        cause_external_id:charityId,
    }
    }

    return axios({
      url: `${BASEURL}/v1/update/user-status`,
      method: "POST",
      headers: {
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      data : obj
    
    })
    .then((response) => response.data.response)
    .catch((err) => err);
  };