import { All_COUNTRY_LIST } from '../../constants/action-types';

const initState = {}

const contriesListReducer = (state = initState, action) => {
    if (action.type === All_COUNTRY_LIST) {
        return {
            ...state,
            ...action.payload
        }
    }
    return state
}

export default contriesListReducer;