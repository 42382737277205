import React, { useState, useEffect } from "react";
import { useSelector,useDispatch } from "react-redux";
import axios from "axios"
// import { BASEURL } from '../../config'
import {
    Container,
    Row,
    Col,
    Button,
    Form,
    FormControl,
    InputGroup,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import axiosCreateContent from "../../axios/createContentAxios";

import NavBar from "../Dashboard/DashboardNavbar";
import arrowLeft from "../../assets/images/arrow-left.svg";
import close from "../../assets/images/x.svg";
import "../../assets/scss/CheckoutForm/checkoutForm.scss";

import secureTickMark from "../../assets/images/secure-tick-mark.svg";
import sheild from "../../assets/images/sheild.svg";
// import rapydlogo from "../../assets/svg/rapyd.svg";
import stripelogo from "../../assets/svg/stripe.svg";
import { adminCharityInfo } from "../../actions/AdminCharityInfo";

import LeanmoreFooter from "../LandingLearnMore/LeanmoreFooter";

import { payoutApi, walletApi } from "../../api/TransactionsApi"

const BodyStyle = () => {
    useEffect(() => {
        // document.body.classList.add(MODAL_OPEN_CLASS);
        document.getElementById("body").className = "bodyStyleModal";
        return () => {
            document.getElementById("body").className = " ";
        };
    }, []);
    return <></>;
};



const CheckoutForm = () => {
    const [open, setOpen] = useState(false);
    const [error, setError] = useState(false);
    const [amount, setAmount] = useState();
    const [redirectUrl, setRedirectUrl] = useState("");
    const [processingTo, setProcessingTo] = useState(false);
    const [balance, setBalance] = useState("");
    const [walletSign, setWalletSign] = useState("");
    const userexternalid = useSelector(state => state.newUserToken.db_user_external_id)
    const charityDetails = useSelector(state => state.newUserToken.cause_info);
    let charityID;
    if(charityDetails){
      charityID = charityDetails.cause_external_id
    }
  const dispatch = useDispatch();

  const adminCharityCurrencyCode = useSelector(state => state.AdminCharityInfoIDReducer.country_currency_code)
  const adminCharityCurrencySign = useSelector(state => state.AdminCharityInfoIDReducer.country_wallet_currency_sign)
    const userType = useSelector(state => state.newUserToken.user_type);

    const userDetails = useSelector((state) => state.userDetails);
    // const userCountryDetails = useSelector(state => state.countriesList.data)
    let walletBalance;
    let currencySign;
    useEffect(() => {
        const fetchBalance = async () => {
            const response = await walletApi(userexternalid,charityID);
            // currencySign = response.response.data.currency_sign;
            walletBalance = response.amount;
            currencySign = response.country_currency_sign;
            // console.log(walletBalance)
            setBalance(walletBalance);
            setWalletSign(currencySign)
        }
        fetchBalance();

        // console.log(transactionDetails);
    }, []);

    useEffect(() => {
        if (userType === "superadmin" || userType === "admin") {
            // console.log(charityID)
            dispatch(adminCharityInfo(charityID))
        }
    }, [charityID])

    //API Call
    const handleSubmit = async (e, amount) => {
        e.preventDefault();
        setProcessingTo(true);
        // axiosCreateContent({
        //     url: `/v1/charities/payout`,
        // const res = await payoutApi(amount, charityDetails.country_currency_code)
        let res = ""
        if (userType === "charity") {
            res = await payoutApi(amount, charityDetails.country_currency_code)
        }
          else if (userType === "superadmin" || userType === "admin") {
             res = await payoutApi(amount,adminCharityCurrencyCode)
          }
        // console.log({ res })
        if (res && res.data && res.data.response && res.data.response.statusCode === 200) {
            setOpen(true);
            setError(false);
        } else {
            setOpen(true);
            setError(true);
        }

    };
    // const handleSubmit = (e, amount) => {
    //     e.preventDefault();
    //     setProcessingTo(true);
    //     axiosCreateContent({
    //         url: `/v1/rapyd/checkout`,
    //         method: "POST",
    //         headers: {
    //             Accept: "application/json",
    //             "Access-Control-Allow-Origin": "*",
    //             // 'Authorization': `Bearer ${tokenDetails.token}`,
    //             // 'userexternalid': tokenDetails.user_external_id,
    //         },
    //         data: {
    //             amount: amount,
    //         },
    //         withCredentials: true,
    //     })
    //         .then((res) => {
    //             setRedirectUrl(res.data.response.data.redirectUrl);
    //             setOpen(true);
    //         })
    //         .catch((err) => {
    //             console.log(err);
    //             setError(true);
    //         });
    // };

    //check min and max load of user
    const handleSetAmount = (e) => {
        setAmount(e.target.value)
        if (e.target.value > balance) {
            e.target.setCustomValidity(`Withdrawal amount should not exceed your wallet balance`);
            // setAmount(e.target.value)
        }else{
            e.target.setCustomValidity("");
        }


        //     // console.log(e.target.value * 1);
        //     if (e.target.value * 1 < userDetails.country_min_load * 1) {
        //         // console.log('show')
        //         e.target.setCustomValidity(`Minimum balance update amount is ${userDetails.country_min_load}.`);
        //         setAmount(e.target.value)
        //     } else if (e.target.value * 1 > userDetails.country_max_load * 1) {
        //         e.target.setCustomValidity(`Maximum balance update amount is ${userDetails.country_max_load}`);
        //         setAmount(e.target.value)
        //     } else {
        //         // console.log('hide')
        //         e.target.setCustomValidity("");
        //         setAmount(e.target.value)
        //     }
    }

    const handleClose = () => {
        setProcessingTo(false);
        setOpen(false);
    };

    //Displaying the approriate currency symbol based on the users country

    let styles = open ? { display: "block" } : { display: "none" };

    return (
        <div style={{ position: "relative" }}>
            {processingTo && <BodyStyle />}
            {processingTo && <div className="modal-backdrop"></div>}

            <NavBar />
            <section className="stripePayment-content">
                <Container>
                    <Row>
                        <Col className="back-link mx-auto" sm={12} md={9} lg={9}>
                            <Link to="/transactions" style={{ textDecoration: "none" }}>
                                <img src={arrowLeft} alt="arrow-left" /> Back
              </Link>
                        </Col>
                        <Col className="mx-auto" sm={12} md={9} lg={9}>
                            <div className="stripe-your-pool-page">
                                <Row className="stripe-header">
                                    <Col>
                                        <p>Withdraw from your pool to your Bank Account</p>
                                    </Col>
                                </Row>
                                <Row className="stripe-body">
                                    <Col className="mx-auto" sm={8} md={8} lg={6}>
                                        <span className="mb-2" style={{ fontSize: "16px", float: "right" }}>Available Balance: {walletSign}{adminCharityCurrencySign && adminCharityCurrencySign}{balance}</span>
                                        <Form onSubmit={(e) => handleSubmit(e, amount)}>
                                            <Form.Label>Enter Amount</Form.Label>
                                            <InputGroup className="">
                                                <InputGroup.Prepend>
                                                    <InputGroup.Text id="amount-addon1">
                                                        {charityDetails && charityDetails.country_wallet_currency_sign}
                                                        {adminCharityCurrencySign && adminCharityCurrencySign}
                                                    </InputGroup.Text>
                                                </InputGroup.Prepend>

                                                <FormControl
                                                    type="number"
                                                    placeholder="0.00"
                                                    aria-label="amount"
                                                    aria-describedby="amount-addon1"
                                                    aria-required="true"
                                                    required
                                                    onChange={(e) => handleSetAmount(e)}
                                                    // onChange={(e) => setAmount(e.target.value)}
                                                    value={amount}
                                                    // min={`${userDetails.country_min_load}`}
                                                    max={`${balance}`}
                                                />
                                            </InputGroup>
                                            <Button
                                                className="blue-buttonActive"
                                                size="md"
                                                variant="primary"
                                                type="submit"
                                                disabled={!amount}
                                            >
                                                {processingTo ? "Processing..." : `Proceed`}
                                            </Button>
                                            <Link className="cancel-button" to="/transactions">
                                                Cancel
                      </Link>
                                        </Form>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                    <Row className="mt-3">
                        <Col sm={12} md={9} lg={9} className="mx-auto">
                            <div style={{ textAlign: "center" }}>
                                <img src={secureTickMark} alt="secure" />
                                <span>Safe &amp; secured payments by </span>
                                <img src={stripelogo} alt="stripe" />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12} md={9} lg={9} className="mx-auto">
                            <div style={{ textAlign: "center" }}>
                                <img src={sheild} alt="secure" />
                                <span>
                                    3D Secure Payment Processing
                </span>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            {/* <LeanmoreFooter /> */}

            <div id="messageModal" className="modal in" role="dialog" style={styles}>
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title" style={{ textAlign: "left" }}>
                            Withdrawn Successfully
              </h4>
                            <button type="button" className="close" onClick={handleClose}>
                                <img src={close} alt="close-button" style={{ width: "100%" }} />
                            </button>
                        </div>
                        <div className="modal-body">
                            <p>
                                The money has been transferred to your connected bank account.
              </p>
                        </div>
                        <div className="modal-footer">
                            <Link className="btn btn-secondary" to="/transactions">
                                Close
              </Link>

                            {/* <Button variant="primary" className="buttonActive">
                {loader ? "Loading..." : `Yes, I'm sure`}
              </Button> */}
                            {/* <a href={redirectUrl} className="btn btn-primary">
                                Yes, I'm sure
                            </a> */}
                        </div>
                    </div>
                </div>
            </div>

            {/* <dialog open={error}>
                <p>
                    There seems to be a problem. Don't worry your money has not been
                    deducted yet.!
            </p>
            </dialog> */}
            {error && <div id="messageModal" className="modal in" role="dialog" style={styles}>
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title" style={{ textAlign: "left" }}>
                                Withdrawn Un-Sucessfully
                            </h4>
                            <button type="button" className="close" onClick={handleClose}>
                                <img src={close} alt="close-button" style={{ width: "100%" }} />
                            </button>
                        </div>
                        <div className="modal-body">
                            <p>
                            There seems to be a problem. Don't worry your money is still in your wallet. Please <a href="/charity-form">Contact us</a>.
                        </p>
                        </div>
                        <div className="modal-footer">
                            <div className="btn btn-secondary" onClick={handleClose} >
                                Close
                            </div>

                            {/* <Button variant="primary" className="buttonActive">
                {loader ? "Loading..." : `Yes, I'm sure`}
              </Button> */}
                            {/* <a href={redirectUrl} className="btn btn-primary">
                                Yes, I'm sure
                            </a> */}
                        </div>
                    </div>
                </div>
            </div>}
        </div>
    );
};

export default CheckoutForm;

