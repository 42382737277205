import React, { useState, useEffect } from "react";
import { Button, Form } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";

//css imports
import "../../assets/scss/Contents/sharingOnMedia.scss";

//image imports
import x from "../../assets/svg/x.svg";
// import horizontalDots from "../../../assets/svg/moreDot-horizontal.svg";
import Loader from "../Loader";
import ToastMessage from "../ToastMessage";
import greenTickMark from "../../assets/svg/greenTickMark.svg";
import {ListAllRoles} from '../../api/Users/EditUsers'
import { loadCharityNames } from "../../actions/CharityList";
import {AddUserRole } from '../../api/Users/AddUserRole'
import { Redirect, useHistory } from "react-router-dom";


/*Style applied to body element */
const BodyStyle = () => {
    useEffect(() => {
        document.getElementById("body").className = "bodyStyleModal";
        return () => {
            document.getElementById("body").className = " ";
        };
    }, []);
    return <></>;
};
/*end**********************/

const AssignRoleModal = ({showReportModal,handleAssignRole,userId,username,email}) => {
    const countriesList = useSelector(state => state.countriesList.data);
    const charityData = useSelector((state) => state.charityNames.data);
    const [validationMsg, setValidationMsg] = useState("");
    const [validationMsgAssignRole, setValidationMsgAssignRole] = useState("");
    const [countryID, setCountryID] = useState("");
    const [charityExternalID, setCharityExternalID] = useState();
    const [Role,setRole] = useState("")
    const [Roles,setRoles] = useState("")
    const [UserName, setUserName] = useState("");
    const [Email, setEmail] = useState("");
    const [showToastMsgUserCreated, setShowToastMsgUserCreated] = useState(false);
    const [charity, setCharity] = useState();
    const [countryName, setCountryName] = useState("");
    const [showToastMsgRoleAssigned, setShowToastMsgRoleAssigned] = useState(false);
    const [RoleId,setRoleId] = useState("");

    
    const [reportOption, setReportOption] = useState("");
    const [reportDescription, setReportDescription] = useState("");
    const [showToastMsg, setShowToastMsg] = useState(false);
    const [commentTypes, setCommentTypes] = useState([]);

    const [showModal, setShowModal] = useState(()=>showReportModal ? showReportModal : false)
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        async function fetchData() {
          let allroles = await ListAllRoles()
          setRoles(allroles)
        }
        fetchData();
      }, []);

      useEffect(() => {
        if (countryID) {
          dispatch(loadCharityNames(countryID));
        }
      }, [countryID]);

    // const handleClose = () => {
    //     setShowModal(false)
    // }

    const handleCountry = (e) => {
        const name = e ? e.split(",") : "";
        let countryExternalId = name[0];

        if (countryExternalId !== "") {
            setCountryID(countryExternalId);
            Object.values(countriesList).map((country) => {
                if (country.country_external_id === countryExternalId) {
                    setCountryName(country.country_name);
                    setCharity("");
                    setCharityExternalID("");
                }
            });
        } else if (countryExternalId === "") {
            setCountryID("");
            setCountryName("");
            setCharity("");
            setCharityExternalID("");
        }
    };

    const handleRoleAssign = async (
        e,
        Role,
        countryID,
        charityExternalID
      ) => {
        e.preventDefault();
        let res = await AddUserRole(RoleId,userId);
        if (res.statusCode === 200) {
          setShowToastMsgRoleAssigned(true);
          setTimeout(() => {
            setShowToastMsgRoleAssigned(false);
            history.push('/edit-user')
            window.location.reload(true);
            handleAssignRole(false)
          }, 3000);
          setRoleId("");
        } else {
          setValidationMsgAssignRole("Something went wrong!Please try again")
        } 
      }

    let stylesReport = showModal
        ? { display: "block", overflowY: "scroll" }
        : { display: "none", overflow: "hidden" };
    return (
        <>

            {/*Report Modal*********************************************** */}
            {showModal && <BodyStyle />}
            {showModal && <div className="modal-backdrop"></div>}

            <div
                id=""
                className="modal in report-modal-container"
                role="dialog"
                style={stylesReport}
            >
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">Assign Role</h4>

                            <button
                                className="close"
                                onClick={() => handleAssignRole(false)}
                            >
                                <img src={x} alt="close-button" />
                            </button>
                        </div>
                        <div className="">
                            <div className="modal-body">
                                <Form className="profile-edit-form" 
                                onSubmit= {(e) =>
                                    handleRoleAssign(
                                        e,
                                        Role,
                                        countryID,
                                        charityExternalID
                                      )
                                    }>
                                    <Form.Group>
                                        <Form.Label className="profile-edit-label">User Name</Form.Label>
                                        <Form.Control value={username} readOnly type="name" required placeholder="Enter Username" />
                                    </Form.Group>

                                    <Form.Group >
                                        <Form.Label className="profile-edit-label">Email address</Form.Label>
                                        <Form.Control value={email} readOnly required type="email" placeholder="Enter email" />
                                    </Form.Group>

                                    <Form.Group >
                                        <Form.Label className="profile-edit-label">Select Role</Form.Label>
                                        <Form.Control required as="select"
                                            onChange={(e) => setRoleId(e.target.value)}
                                        >
                                            <option value="">Select Role</option>
                                            {Roles && Roles.map(
                                                (role, index) => (
                                                    <option
                                                        value={`${role.role_external_id}`}
                                                        key={role.role_external_id}
                                                    >
                                                        {role.type}
                                                    </option>
                                                )
                                            )}
                                        </Form.Control>
                                    </Form.Group>
                                    
                                   <span style={{ color: "red" }}>
                                        {validationMsgAssignRole}
                                    </span>
                                    <Button style={{ margin: "1rem 0px" }} variant="primary" type="submit" block>
                                        Submit
        </Button>
                                </Form>

                            </div>
                            {showToastMsgRoleAssigned ? (
                            <ToastMessage
                                image={greenTickMark}
                                message="Assigned Role Successfully"
                            />
                            ) : null}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AssignRoleModal;
