import React, { useState, useEffect } from "react";
import { Toast, Row, Col, Card, Button, Form, Dropdown } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import ToastMessage from "../../ToastMessage";

//css imports
import "../../../assets/scss/Contents/sharingOnMedia.scss";

//image imports
import x from "../../../assets/svg/x.svg";
import horizontalDots from "../../../assets/svg/moreDot-horizontal.svg";
// import { reportTypePostApi } from "../../../api/ReportPostAPI";
import { reportPostApi, reportPostApiAdmin } from "../../../api/DashboardAPI/ReportPostAPI";
import successIcon from "../../../assets/svg/successIcon.svg"

const CustomToggle = React.forwardRef(
  ({ userToken, userExternalId, onClick }, ref) => (
    // <a
    //   href=""
    //   ref={ref}
    //   onClick={(e) => {
    //     e.preventDefault();
    //     onClick(e);
    //   }}
    // >
    <Button ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }} id="report">Report</Button>
    // </a>
  )
);

let reportArrayList;




const reportpost = (
  <svg
    className="social-media-icon"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.3156 4H8.68444L4 8.68444V15.3156L8.68444 20H15.3156L20 15.3156V8.68444L15.3156 4ZM12 16.7111C11.36 16.7111 10.8444 16.1956 10.8444 15.5556C10.8444 14.9156 11.36 14.4 12 14.4C12.64 14.4 13.1556 14.9156 13.1556 15.5556C13.1556 16.1956 12.64 16.7111 12 16.7111ZM12.8889 12.8889H11.1111V7.55556H12.8889V12.8889Z"
      fill="#9E9E9E"
    />
  </svg>
);

const ReportPost = ({ contentId }) => {

  const [showReport, setShowReport] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [reportOption, setReportOption] = useState("");
  const [reportList, setReportList] = useState(null);
  const [reportDescription, setReportDescription] = useState("");
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [showBox, setShowBox] = useState(false);

  const tokenDetails = useSelector((state) => state.tokenDetails);
  // // console.log(tokenDetails);
  // const userExternalId = tokenDetails.user_external_id;
  const userType = useSelector(state => state.newUserToken.user_type);

  const handleSubmitReport = (e, reportOption, reportDescription) => {
    e.preventDefault();
    // reportPostApi(tokenDetails.token, tokenDetails.user_external_id, reportOption, contentExternalId, reportDescription)
    // alert();
    // console.log(`${reportOption},${tokenDetails.token},${tokenDetails.user_external_id}${contentExternalId},${reportDescription}`)
  };
  // const handleReportOption = (e) => {
  //   if (e.target.checked) {
  //     setDisabled(false);
  //     setReportOption(e.target.value);
  //   }
  // };

  const handleReport = async (e) => {
    if (userType === "charity") {
      const res = await dispatch(reportPostApi(contentId))
      if (res.data.response.statusCode === 200) {
        setShow(true)
        setShowBox(false)
        setTimeout(() => {
          setShow(false)
        }, 4000);
      }
      setShowReport(true);
    } else {
      const res = await dispatch(reportPostApiAdmin(contentId))
      if (res.data.response.statusCode === 200) {
        setShow(true)
        setShowBox(false)
        setTimeout(() => {
          setShow(false)
        }, 4000);
      }
      setShowReport(true);
    }

  };
  const confirmReport = (val) => {
    setShowBox(val)
  }
  let stylesReport = showReport
    ? { display: "block", overflow: "hidden" }
    : { display: "none", overflow: "hidden" };
  // let styleOthers = (reportOption === "others") ? { visibility: "visible", resize: "none" } : { visibility: "hidden", resize: "none" }
  return (
    <>
      {/* <Dropdown
        className="sharingOnMedia-main"
        alignRight
        title=""
        id="dropdown-menu-align-right"
      >
        <Dropdown.Toggle
          as={CustomToggle}
          userToken={tokenDetails.token}
          userExternalId={tokenDetails.user_external_id}
        />
        <Dropdown.Menu size="lg" className="sharingOnMedia-menu">
          
          <Dropdown.Item
            style={{ padding: "0 27px 0 18px" }}
            onClick={handleReport}
          >
            {reportpost}
            <span>Reporting the content will hide it from the platform, Do you want to proceed?</span>
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown> */}


      {showBox && <Card style={{ position: "absolute", width: "50%", top: "-20px", right: "0" }} className="reportCard">
        <span> <span style={{ color: "#757575", padding: "5px 0 0 5px", display: "inline-block" }}>{reportpost}Reporting this content will remove it from the platform. Do you want to proceed?</span></span>
        <span><button style={{ margin: "1rem 5px 5px .5rem", backgroundColor: "#2077b1", borderRadius: "4px", border: "none", padding: "5px", color: "white" }} onClick={handleReport}>Confirm</button>
          <button style={{ margin: "1rem 5px 5px 0", borderRadius: "4px", border: "1px solid #2077b1", padding: "5px", color: "#2077b1" }} onClick={() => confirmReport(false)}>Cancel</button>
        </span>
      </Card>}
      <Button onClick={() => confirmReport(true)} id="report">Report</Button>

      {show ? <ToastMessage image={successIcon} message="Successfully Removed" /> : null}

    </>
  );
};

export default ReportPost;
