import { useState, useEffect } from 'react'
import { BASEURL } from "../../config"
import axios from 'axios'
import { useSelector } from "react-redux";
import { ListItemText } from '@material-ui/core';

export default function usePagination(pageNumber, token, external_Id) {
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(false)
    const [contentsArray, setContentsArray] = useState([])
    const [hasMore, setHasMore] = useState(false)
    const userType = useSelector(state => state.newUserToken.user_type);
    let charityID = useSelector(state => state.CharityIDReducer.charityID);

    // if(!charityID && (userType === "superadmin" || userType === "admin")){
    //     charityID = "c316abdc-4f97-4393-8ed7-5423d61d6075"
    // }

    useEffect(() => {
        setContentsArray([])
    }, [charityID])

    useEffect(() => {
        setLoading(true)
        setError(false)
        // let cancel
        if ((userType == "superadmin" || userType == "admin" ||userType == "countryadmin") && charityID) {
            axios({
                method: "POST",
                url: `${BASEURL}/v1/adminDashboard/charity/list-contents`,
                data: {
                    cause_external_id: charityID,
                    pageNo: pageNumber
                },
                headers: {
                    'Accept': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    // 'Authorization': `Bearer ${token}`,
                    // 'userexternalid': external_Id
                },
                // cancelToken: new axios.CancelToken(c => cancel = c),
                withCredentials: true,
            }).then(response => {
                setLoading(true)
                setContentsArray(prevContentsArray => {
                    if (response && response.data && response.data.response && response.data.response.statusCode === 200) {
                        return [...prevContentsArray, ...response.data.response.data.contents]
                    } else {
                        return [...prevContentsArray]
                    }
                })
                // console.log(response.data.response.data.pagination_info.has_next_page)
                if(response && response.data && response.data.response && response.data.response.data)
                {
                    setHasMore(response.data.response.data.pagination_info.has_next_page)
                }
                setLoading(false)
            })
                .catch(e => {
                    // if (axios.isCancel(e)) return
                    setError(true)
                })
            // return () => cancel()
        } else if (userType == "charity") {
            axios({
                method: "POST",
                url: `${BASEURL}/v1/charityDashboard/content/listContent`,
                data: {
                    pageNo: pageNumber
                },
                headers: {
                    'Accept': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    // 'Authorization': `Bearer ${token}`,
                    // 'userexternalid': external_Id
                },
                // cancelToken: new axios.CancelToken(c => cancel = c),
                withCredentials: true,
            }).then(response => {
                setLoading(true)
                setContentsArray(prevContentsArray => {
                    if (response && response.data && response.data.response && response.data.response.statusCode === 200) {
                        return [...prevContentsArray, ...response.data.response.data.contents]
                    } else {
                        return [...prevContentsArray]
                    }
                })
                // console.log(response.data.response.data.pagination_info.has_next_page)
                if(response && response.data && response.data.response && response.data.response.data){
                    setHasMore(response.data.response.data.pagination_info.has_next_page)
                }
                setLoading(false)
            })
                .catch(e => {
                    // if (axios.isCancel(e)) return
                    setError(true)
                })
            // return () => cancel()
        } else if (userType == "superadmin" || userType == "admin" || userType == "countryadmin") {
            axios({
                method: "POST",
                url: `${BASEURL}/v1/adminDashboard/charity/list-all-contents`,
                data: {
                    pageNo: pageNumber
                },
                headers: {
                    'Accept': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    // 'Authorization': `Bearer ${token}`,
                    // 'userexternalid': external_Id
                },
                // cancelToken: new axios.CancelToken(c => cancel = c),
                withCredentials: true,
            }).then(response => {
                setLoading(true)
                setContentsArray(prevContentsArray => {
                    if (response && response.data && response.data.response && response.data.response.statusCode === 200) {
                        return [...prevContentsArray, ...response.data.response.data.contents]
                    } else {
                        return [...prevContentsArray]
                    }
                })
                // console.log(response.data.response.data.pagination_info.has_next_page)
                if(response && response.data && response.data.response && response.data.response.data){
                    setHasMore(response.data.response.data.pagination_info.has_next_page)
                }
                setLoading(false)
            })
                .catch(e => {
                    // if (axios.isCancel(e)) return
                    setError(true)
                })
            // return () => cancel()
        }


    }, [pageNumber, token, external_Id, charityID])
    return { loading, error, contentsArray, hasMore }
}