import React from "react";

import { withRouter } from "react-router-dom";

//image imports
import whiteBrandLogo from "../../assets/svg/white-brandLogo.svg";

import blueBrandLogo from "../../assets/svg/blue-brandLogo.svg";

//css imports
import { Navbar, Nav } from "react-bootstrap";
import "../../assets/scss/CharityFooter/CharityNavbar.scss";

const ContactUsButton = () => {
  return (
    <Navbar.Collapse className="justify-content-end">
      <Nav>
        <Nav.Link
          href="/charity-form"
          id="learn-more-page-signup-button"
        >
          Contact Us
        </Nav.Link>
      </Nav>
    </Navbar.Collapse>
  );
};

const LoginNavbar = (props) => {
  const path = props.location.pathname; //gives current path

  return (
    <section className="learn-more-nav-wrapper">
      <Navbar className="learn-more-nav">
        <Navbar.Brand href="/home" className="brand-logo">
          {/* {path === "/" ? < img src={whiteBrandLogo} alr="brand-logo" /> : <img src={blueBrandLogo} alr="brand-logo" />} */}
          <img src={blueBrandLogo} alr="brand-logo" />
        </Navbar.Brand>

        {/*the search bar is visible for learn more page  */}
        {/* {path !== "/profile-setup" && <ContactUsButton />} */}
        {/* <ContactUsButton /> */}
      </Navbar>
    </section>
  );
};

export default withRouter(LoginNavbar);
