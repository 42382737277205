import React, { useState, createRef } from "react";
import { Form, Button, Container, Row, Col, ToggleButtonGroup, ToggleButton } from 'react-bootstrap'
import { Link } from 'react-router-dom';

import CreateUser from './CreateUser'
import EditUsers from './EditUsers'
// import EditRoles from './EditRoles'
import NavBarDashboard from "../Dashboard/DashboardNavbar";


function Users() {
  const createUserRef = createRef();
  const EditUserRef = createRef();

  const [value, setValue] = useState(1);

  const handleChange = (val) => {
    setValue(val);
    val == 1
      ? createUserRef.current.classList.add("nav-admin-reports-active")
      : createUserRef.current.classList.remove("nav-admin-reports-active");
    val == 2
      ? EditUserRef.current.classList.add("nav-admin-reports-active")
      : EditUserRef.current.classList.remove("nav-admin-reports-active");
  }
  return (
    <>
      <NavBarDashboard />
      <section style={{ backgroundColor: "#F2F2F2" }} >
        <div className="admin-dashboard-navbar" style={{ display: "flex", justifyContent: "center", backgroundColor: "white", padding: "0.5rem" }}>
          <Link to="/users" ref={createUserRef} className="dashboard-navbar-elements nav-admin-reports-active" style={{ margin: "0px 1rem", textDecoration: "none"}} onClick={() => handleChange(1)}>Create New User</Link>
          <Link to="/edit-user" ref={EditUserRef} className="dashboard-navbar-elements" style={{textDecoration: "none"}} onClick={() => handleChange(2)}>Edit Users</Link>
        </div>
        <CreateUser />

      </section>
    </>
  );
}

//   <ToggleButtonGroupControlled />;


// const Users = () => {
//   return (

//     <CreateUser />

//   );
// };

export default Users;
