import React from "react";
import errorImage from "../assets/images/404-Error.png";
import { Container, Col, Row, Image, Button } from "react-bootstrap";

import "../assets/scss/pageNotFound.scss";

const PageNotFound = (props) => {
    const goBack = () =>  props.history.goBack();
    
  return (
    <section className="page-not-found-wrapper">
      <Container>
        <Row>
          <Col>
            <Image src={errorImage} />
            <p className="page-not-found-oops">Oops!</p>
            <p className="page-not-found-lost">Seems like you lost somewhere</p>
            <Button className="buttonActive" onClick={goBack}>Go Back</Button>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default PageNotFound;
