import { UPDATE_PASSWORD } from '../../constants/action-types';

const initState = {}

const UpdatePasswordReducer = (state = initState, action) => {
    if (action.type === UPDATE_PASSWORD) {
        return {
            ...state,
            ...action.payload
        }
    }
    return state
}

export default UpdatePasswordReducer;
