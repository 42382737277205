
import axios from 'axios'
import { BASEURL } from '../../config'

 export const PoolingRatio = (from,to) => {
    return axios({
      url: `${BASEURL}/v1/adminDashboard/pooling-ratio`,
      method: "POST",
      headers: {
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      data: {
        "from_date":from,
        "to_date":to
      }
    })
      .then((response) => response.data.response.data)
      .catch((err) => console.error(err));
  };
