import { USER_PROFILE_DATA } from '../../constants/action-types'

const initState = {}

const ProfileSetupReducer = (state = initState, action) => {
    if (action.type === USER_PROFILE_DATA) {
        return {
            ...state,
            ...action.payload
        }

    }
    return state
}
export default ProfileSetupReducer