import React, { useEffect, useState } from "react";
import { Card, Button, Container, Image, Form, Alert, Col, InputGroup } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import axios from "axios";

import { Redirect, Link } from "react-router-dom";

//CSS imports
import '../../../assets/scss/Login/enterPassword.scss';
// import LeanmoreFooter from "../../LandingLearnMore/LeanmoreFooter";
import LoginNavbar from "../../CharityNavbar/LoginNavbar";
import Footer from "../../CharityFooter/Footer.js";


//image import
import x from '../../../assets/svg/x.svg';

import { BASEURL } from '../../../config';

const SendLinkPassword = () => {
    const [Email, setEmail] = useState("");
    const [sendlinkbtn, setSendLinkBtn] = useState(false);
    const [sendLinktoEmail, setSendLinkToEmail] = useState(false);

    // const googleResponse = useSelector(state => state.googleResponse.response);
    // const FBResponse = useSelector(state => state.FbResponseData.response);

    const newUserToken = useSelector(state => state.newUserToken.data);
    const nexmoVerificationResponse = useSelector(state => state.nexmoVerificationResponse);

    const [isLoading, setIsLoading] = useState(true);
    const [userExternalId, setUserExternalId] = useState('');

    const [successMessage, setSuccessMessage] = useState('');
    const [error, setError] = useState(false);

    // useEffect(() => {
    //     if (FBResponse) {
    //         setIsLoading(false)
    //         let user_external_id = FBResponse.data.user_external_id ? FBResponse.data.user_external_id : newUserToken.response.data.user_external_id
    //         setUserExternalId(user_external_id)
    //     }

    //     else if (googleResponse) {
    //         setIsLoading(false)
    //         let user_external_id = googleResponse.data.user_external_id ? googleResponse.data.user_external_id : newUserToken.response.data.user_external_id
    //         setUserExternalId(user_external_id)
    //         // let token = googleResponse.data.login_type ? newUserToken.response.data.accessToken : googleResponse.data.accessToken
    //         // dispatch(getTokenDetails(user_external_id));
    //     }

    //     else if (nexmoVerificationResponse.response) {
    //         setIsLoading(false)
    //         let user_external_id = nexmoVerificationResponse.response.data.login_type ? newUserToken.response.data.user_external_id : nexmoVerificationResponse.response.data.user_external_id
    //         setUserExternalId(user_external_id)
    //         // let token = nexmoVerificationResponse.response.data.login_type ? newUserToken.response.data.accessToken : nexmoVerificationResponse.response.data.accessToken
    //         // dispatch(getTokenDetails(user_external_id));
    //     }
    // }, [])

    // useEffect(() => {
    //     if (Email.length > 0) {
    //         setSendLinkBtn(true);
    //         // setSendLinkToEmail(true);
    //     }
    //     else {
    //         setSendLinkBtn(false);
    //         setSendLinkToEmail(false);
    //     }
    // }, [Email])

    const sendLink = (e, user_email) => {
        e.preventDefault();
        // console.log(`${user_email} ${userExternalId}`)
        axios({
            method: "POST",
            url: `${BASEURL}/v1/login/resetpasswordlink`,
            headers: {
                "Content-Type": "application/json",
                "userexternalid": userExternalId
            },
            data: {
                user_email: user_email,
                user_external_id: userExternalId
            },
            withCredentials: true,
        })
            .then(password => {
                // console.log(password.data.response.data.successMessage)
                setSuccessMessage(password.data.response.data.successMessage)
                setError(password.data.reponse.error)
            })
            .catch(err => console.error(err))
    }


    return (
        <div className="password-container" >
            <LoginNavbar />
            <Container>
                <div className="row">
                    <div className="col-sm-12">
                        <Card className="password-card">
                            <Card.Header className="password-header">Reset Your Password
                             <Link to="/password" className="password-close">
                                    <img src={x} alt="close-button" /></Link></Card.Header>
                            <Card.Body>
                                <Card.Text className="email-text">
                                    Please enter your email address so we can send you a reset password link
                                    </Card.Text>
                                <Form className="password-form" onSubmit={(e) => sendLink(e, Email)}>
                                    <Form.Group className="email">
                                        <Form.Label className="password-form-label">Email address</Form.Label>

                                        <Form.Control size="lg" type="email" id="myInput" placeholder="Email address" onChange={e => setEmail(e.target.value)} value={Email} />
                                    </Form.Group>
                                    {successMessage ? <p>Reset link successfully sent</p> : null}
                                    {error ? <p>Something went wrong, try again later!</p> : null}
                                    {sendlinkbtn ? <Button className="password-enable-button" type="submit" style={{ marginTop: "40px" }}>Send link</Button> : <Button className="password-button" type="submit" style={{ marginTop: "40px" }}>Send link</Button>}
                                </Form>
                                {sendLinktoEmail && (
                                    <Redirect
                                        to={{
                                            pathname: "/reset-password"
                                        }}
                                    />
                                )}
                            </Card.Body>
                        </Card>
                    </div>
                </div>
            </Container>
            {/* <LeanmoreFooter /> */}
            <Footer />
        </div>
    )
}
export default SendLinkPassword;
