import axios from "axios";
import {
  STORE_USER_DATA,
  USER_INFO_DETAILS,
  VALIDATE_USERNAME,
  LOG_OUT
} from "../constants/action-types";
import { BASEURL } from "../config";


// export const getUserProfilePicture = (picture, contentType) => ({
//   type: "GET_USER_PROFILE_PICTURE",
//   payload: {
//     picture,
//     contentType,
//   },
// });

export const storeUserDetails = (userDetails) => ({
  type: STORE_USER_DATA,
  payload: userDetails,
});

export const logoutUser = () => ({
  type: LOG_OUT,
  payload:""
});

// export const showBalance = (show) => ({
//   type: "SHOW_BALANCE",
//   payload: show,
// });


// export const userBalance = () => (dispatch) => {
//   return axios({
//     url: `${BASEURL}/v1/users/wallets`,
//     method: "GET",
//     headers: {
//       Accept: "application/json",
//       "Access-Control-Allow-Origin": "*",
//       // 'Authorization': `Bearer ${token}`,
//       // 'userexternalid': user_external_Id,
//     },
//     withCredentials: true,
//   })
//     .then((res) =>
//       dispatch({
//         type: BALANCE_AMT,
//         payload: res.data.response.data,
//       })
//     )
//     .catch((err) => console.error(err));
// };

// export const validateUserName = (userName) => {
//   let cancel;
//   return axios({
//     method: "POST",
//     url: `${BASEURL}/v1/verifyusername`,
//     headers: {
//       "Content-Type": "application/json",
//     },
//     data: {
//       username: userName,
//     },
//     cancelToken: new axios.CancelToken((c) => (cancel = c)),
//     // withCredentials: true,
//   })
//     .then((response) => response)
//     .catch((e) => {
//       if (axios.isCancel(e)) return;
//       console.error(e);
//       return cancel;
//     });
// };

// export const storeUserDetails = (userName, Password, obj, nexmoPhoneNumber, facebook) => dispatch => {
//   if (!nexmoPhoneNumber && !facebook) {
//     axios({
//       url: `${BASEURL}/v1/users`,
//       method: 'POST',
//       headers: {
//         'Accept': 'application/json',
//         'Access-Control-Allow-Origin': '*'
//       },
//       data: {
//         user_email_address: obj.email,
//         user_full_name: obj.fullname,
//         user_country: obj.countryName,
//         user_date_of_birth: obj.date_of_birth,
//         login_type: "da5aef7c-7e43-4151-8da5-56bd9e86e455",
//         country_id: obj.countryExternalId,
//         username: userName,
//         user_gender: obj.gender,
//         user_state: obj.State,
//         user_password: Password,
//         // user_profile_pic: obj.SelectedProfile
//       },
//       withCredentials: true,
//     })
//       .then(response =>
//         dispatch({
//           type: USER_INFO_DETAILS,
//           payload: response
//         }))
//       .catch(err => console.error(err))

//   }
//   else if (facebook && !nexmoPhoneNumber) {
//     axios({
//       url: `${BASEURL}/v1/users`,
//       method: 'POST',
//       headers: {
//         'Accept': 'application/json',
//         'Access-Control-Allow-Origin': '*'
//       },
//       data: {
//         user_email_address: obj.email,
//         user_full_name: obj.fullname,
//         user_country: obj.countryName,
//         user_date_of_birth: obj.date_of_birth,
//         login_type: "4427d530-6772-4ce2-be7b-ef0c65e95414",
//         user_facebook_id: facebook.data.user_facebook_id,
//         country_id: obj.countryExternalId,
//         username: userName,
//         user_gender: obj.gender,
//         user_state: obj.State,
//         user_password: Password,
//         user_profile_pic: obj.SelectedProfile

//         // email_address: obj.email,
//         // name: obj.fullname,
//         // country: obj.countryName,
//         // facebook_id: facebook.response.data.facebook_id,
//         // date_of_birth: obj.date_of_birth,
//         // login_type: 3,
//         // username: userName,
//         // gender: obj.gender,
//         // state: obj.State,
//         // password: Password,
//       },
//       withCredentials: true,
//     })
//       .then(response =>
//         dispatch({
//           type: USER_INFO_DETAILS,
//           payload: response
//         }))
//       .catch(err => console.error(err))
//   }

//   else {
//     axios({
//       url: `${BASEURL}/v1/users`,
//       method: 'POST',
//       headers: {
//         'Accept': 'application/json',
//         'Access-Control-Allow-Origin': '*'
//       },
//       data: {
//         // email_address: obj.email,
//         // mobile_number: nexmoPhoneNumber,
//         // name: obj.fullname,
//         // country: obj.countryName,
//         // date_of_birth: obj.date_of_birth,
//         // login_type: 4,
//         // username: userName,
//         // gender: obj.gender,
//         // state: obj.State,
//         // password: Password

//         user_email_address: obj.email,
//         user_full_name: obj.fullname,
//         user_country: obj.countryName,
//         user_date_of_birth: obj.date_of_birth,
//         login_type: "278dddb0-ca63-472e-88fb-5f175ba81659",
//         country_id: obj.countryExternalId,
//         username: userName,
//         user_gender: obj.gender,
//         user_state: obj.State,
//         user_password: Password,
//         user_mobile_number: nexmoPhoneNumber,
//         user_profile_pic: obj.SelectedProfile
//       },
//       withCredentials: true,
//     })
//       .then(response =>
//         dispatch({
//           type: USER_INFO_DETAILS,
//           payload: response
//         }))
//       .catch(err => console.error(err))
//   }
// }
