import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Container, Row, Col, DropdownButton, Dropdown, Form } from 'react-bootstrap';
//component imports
import Loader from '../Loader'
// import Contents from './Contents/Contents';
import Contents from './Contents/Contents';
import dropdownButtonArrow from '../../assets/svg/dropdownButtonArrow.svg';

//actions imports
// import { getUserDetailsByUserId } from '../../../actions/userProfile_action';
// import { userAuthorized } from '../../../actions/isAuthorized';
// import { storeUserCountryData } from '../../../actions/storingUserData'

// CSS imports
// import '../../assets/scss/Contents/activityPage.scss';
// import '../../assets/scss/Dashboard/DashboardHome.scss';
import '../../assets/scss/Dashboard/ActivityPage.scss';
import "../../assets/scss/Dashboard/DropdownStyles.scss";

import { storeCountriesList } from "../../actions/countryList"
import { loadCharityNames } from "../../actions/CharityList"
import { getCharityID } from '../../actions/storeCharityID';
import { adminCharityInfo } from "../../actions/AdminCharityInfo";


const Activitypage = () => {
  const [countryID, setCountryID] = useState("");
  const [charityID, setCharityID] = useState("");
  const charityNames = useSelector((state) => state.charityNames.data);
  const userType = useSelector(state => state.newUserToken.user_type);
  const countriesList = useSelector(state => state.countriesList.data);
  const adminCharityName = useSelector(state => state.AdminCharityInfoIDReducer.cause_name)
  const adminCharityCountry = useSelector(state => state.AdminCharityInfoIDReducer.country_name)
  const [countryName, setCountryName] = useState(()=> adminCharityCountry ? adminCharityCountry : "");
  const [charity, setCharity] = useState(()=> adminCharityName ? adminCharityName : "");
  const countryAdminCountryId = useSelector(state => state.newUserToken.country_external_id);
  const countryAdminCountryName = useSelector(state => state.newUserToken.country_name);
  
  const dispatch = useDispatch();

  useEffect(() => {
    if (userType === "superadmin" || userType === "admin" || userType === "countryadmin") {
      if(charityID){
        dispatch(adminCharityInfo(charityID))
      }
    }
  }, [charityID])

  useEffect(()=>{
    if(countryAdminCountryName){
      setCountryName(countryAdminCountryName)
      dispatch(loadCharityNames(countryAdminCountryId))
    }
  },[])

  useEffect(() => {
    if (userType == "superadmin" || userType == "admin") {
      dispatch(storeCountriesList())
    }
  }, [])

  useEffect(() => {
    if (countryID) {
      dispatch(loadCharityNames(countryID))
    }
  }, [countryID])

  const handleCountry = (e) => {
    const name = e ? e.split(",") : "";
    let countryExternalId = name[0];

    if (countryExternalId !== "") {
      setCountryID(countryExternalId);
      Object.values(countriesList).map((country) => {
        if (country.country_external_id === countryExternalId) {
          setCountryName(country.country_name);
          setCharity("");
          setCharityID("");
        }
      });
    } else if (countryExternalId === "") {
      setCountryID("");
      setCountryName("");
      setCharity("");
      setCharityID("");
    }
  };

  const text_truncate = (str, length, ending) => {
    if (length == null) {
      length = 100;
    }
    if (ending == null) {
      ending = "...";
    }
    if (str.length > length) {
      return str.substring(0, length - ending.length) + ending;
    } else {
      return str;
    }
  };

  const handleCharity = (e) => {
    if (e !== null) {
      const charity = e;
      const name = charity.split(",");
      setCharityID(name[0]);
      setCharity(name[1]);
      dispatch(getCharityID(name[0]));
    } else {
      setCharity("");
      setCharityID("");
      dispatch(getCharityID(""));
    }
  };

  // console.log({ charityID })

  return (
    <div className="charity-activitypage-main">
      <Container className="Activitypage-container">
        <Row>
          <Col sm={12} md={10} lg={8} className="mx-auto" id="main-content-wrapper">
            <Row className="heading">
              <p className="posts">Posts</p>

              {/* <div className="dropdown-container">
                        <img className="dropdown-arrow" src={calenderImg} alt="dropdownButtonArrow" />

                        <DropdownButton className="dropdown-btn" onSelect={handleDate} title={<span className='dropdown-span'>{date ? date : 'Filter'}</span>}>
                            <Dropdown.Item className="dropdown-item" eventKey='Today'>Today</Dropdown.Item>
                            <Dropdown.Item className="dropdown-item" eventKey='Week'>This week</Dropdown.Item>
                            <Dropdown.Item className="dropdown-item" eventKey='Month'>This month</Dropdown.Item>
                            <Dropdown.Item className="dropdown-item" eventKey='3 Months'>Last 3 months</Dropdown.Item>
                        </DropdownButton>
                    </div> */}
              {(userType == "admin" || userType == "superadmin" || userType === "countryadmin") &&
                <>
                 <div style={{width:"26rem",display:"flex",justifyContent:"space-between"}}>
                  {/* ------ Country dropdown ------ */}
                  <div className="dropdown-container dropdown-country">
                    <img
                      className="dropdown-arrow"
                      src={dropdownButtonArrow}
                      alt="dropdownButtonArrow"
                    />

                    <DropdownButton
                  disabled = {countryAdminCountryId ? true : false}
                      className="dropdown-btn"
                      onSelect={handleCountry}
                      title={
                        <span className="dropdown-span">
                          {countryName
                            ? text_truncate(countryName, 18)
                            : "Country"}
                        </span>
                      }
                    >
                      <div className="dropdown-options">
                        <Dropdown.Item eventkey=" ">Country</Dropdown.Item>
                        {countriesList && Object.values(countriesList).map(
                          (countryData, index) => (
                            <Dropdown.Item
                              eventKey={`${countryData.country_external_id},${countryData.country_name}`}
                              key={countryData.country_external_id}
                            >
                              {countryData.country_name}
                            </Dropdown.Item>
                          )
                        )}
                      </div>
                    </DropdownButton>
                  </div>
                 
                  {/* ------ Charity dropdown ------ */}
                  <div className="dropdown-container dropdown-charity">
                    <img
                      className="dropdown-arrow"
                      src={dropdownButtonArrow}
                      alt="dropdownButtonArrow"
                    />

                    <DropdownButton
                      disabled={countryName ? false : true}
                      className="dropdown-btn"
                      onSelect={handleCharity}
                      title={
                        <span className="dropdown-span">
                          {charity ? text_truncate(charity, 25) : "Charity"}
                        </span>
                      }
                    >
                      <Dropdown.Item eventkey=" ">Charity</Dropdown.Item>
                      {charityNames && charityNames.map((cause, index) => {
                        return (
                          <Dropdown.Item
                            eventKey={`${cause.cause_external_id}, ${cause.cause_name}`}
                            key={cause.cause_external_id}
                          >
                            {cause.cause_name}
                          </Dropdown.Item>
                        )
                      })}
                    </DropdownButton>
                  </div>
                  </div>
                </>
              }
            </Row>
            <Contents countryID={countryID} />
          </Col>
        </Row>
      </Container>
    </div>

  );
};

export default Activitypage;


