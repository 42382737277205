import React, { useState, useEffect, useCallback } from "react";
import axios from 'axios'
import { BASEURL } from '../../config';
import {Row,Col,Table ,Button,Container,Dropdown,DropdownButton} from 'react-bootstrap'
import "../../assets/scss/DashboardReports/reportsStyles.scss"
import { useSelector, useDispatch } from "react-redux";
import calenderImg from "../../assets/svg/calenderIcon.svg";
import SelectDatepicker from 'react-select-datepicker';

import {LoadedAmount} from '../../api/AdminReportApis/LoadedAmount.js'

const GenerateReport = () => {
  const [date, setDate] = useState("");
  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");
  const [collectedAmt, setCollectedAmt] = useState("");
  const countriesList = useSelector(state => state.countriesList.data);
  const [fromDate, setFromDate] = useState(new Date());
  const [showFromDate, setShowFromDate] = useState(false);
  const [showToDate, setShowToDate] = useState();
  const [toDate, setToDate] = useState(new Date());
  const [show, setShow] = useState(false);
  const [error, setError] = useState(false);
  
  const [totalCollectedAmt, setTotalCollectedAmt] = useState("");

//   function Formatter(num) {
//     return Math.abs(num) > 999 ? Math.sign(num)*((Math.abs(num)/1000).toFixed(2)) + 'k' : Math.sign(num)*Math.abs(num)
// }


// var currentDate = new Date().toJSON().slice(0, 10) + " 01:00:00";
// console.log(currentDate)

useEffect(()=>{
  // let filterFromDate = new Date(fromDate).toJSON().slice(0, 10)
  // filterToDate.setHours(0);
  // filterToDate.setMinutes(0);
  // filterToDate.setSeconds(0);
  setFromDate(new Date(fromDate.toJSON().slice(0, 10) + " 00:00:00"))
},[])

useEffect(()=>{
  // let filterToDate = new Date(fromDate).toJSON().slice(0, 10)
  // filterToDate.setHours(0);
  // filterToDate.setMinutes(0);
  // filterToDate.setSeconds(0);
  setToDate(new Date(toDate.toJSON().slice(0, 10) + " 00:00:00"))
},[])

  const onFromDateChange = useCallback((date) => {
    setFromDate(date);
  }, []);

  const onToDateChange = useCallback((date) => {
    setToDate(date);
  }, []);

  if(fromDate && toDate){
    var offsetTimeFromDate = new Date(fromDate.getTime() - fromDate.getTimezoneOffset() * 60 * 1000);
    var offsetTimeToDate = new Date(toDate.getTime() - toDate.getTimezoneOffset() * 60 * 1000);
    var fromString = offsetTimeFromDate.toISOString().substring(0,10)
    var toString = offsetTimeToDate.toISOString().substring(0,10)
    var fileName = `${fromString}_${toString}`
  }

  // console.log(offsetTimeFromDate.toISOString().substring(0,10))
  // console.log(offsetTimeToDate.toISOString().substring(0,10))


  const handleRazorPay = () => {
    
  axios({
    url: `${BASEURL}/v1/adminDashboard/charity/razorypay-report`, //your url
    method: 'POST',
    responseType: 'blob', // important
    contentDisposition: 'attachment',
    data:{
            "from_date": offsetTimeFromDate,
            "to_date": offsetTimeToDate
    },
  }).then((response) => {
     const url = window.URL.createObjectURL(new Blob([response.data]));
     const link = document.createElement('a');
     link.href = url;
     link.setAttribute('download', `razorpay_${fileName}.xlsx`); 
    //  link.setAttribute('download', response.headers["content-disposition"].split("filename=")[1]);
    document.body.appendChild(link);
     link.click();
  });
  }

  const handleStripe = () => {
    axios({
      url: `${BASEURL}/v1/adminDashboard/charity/stripe-report`, 
      method: 'POST',
      responseType: 'blob', // important
    contentDisposition: 'attachment',
      data:{
        "from_date": offsetTimeFromDate,
        "to_date": offsetTimeToDate
      },
    }).then((response) => {
       const url = window.URL.createObjectURL(new Blob([response.data]));
       const link = document.createElement('a');
       link.href = url;
       link.setAttribute('download', `stripe_${fileName}.xlsx`); //or any other extension
       document.body.appendChild(link);
       link.click();
    }) }

    const handleCommissionStripe = () => {
      axios({
        url: `${BASEURL}/v1/adminDashboard/charity/gopoolit-commission-report`, 
        method: 'POST',
        responseType: 'blob', // important
      contentDisposition: 'attachment',
        data:{
          "from_date": offsetTimeFromDate,
          "to_date": offsetTimeToDate,
          "pool_source_id": 1
        },
      }).then((response) => {
         const url = window.URL.createObjectURL(new Blob([response.data]));
         const link = document.createElement('a');
         link.href = url;
         link.setAttribute('download', `commission_report_stripe_${fileName}.xlsx`); //or any other extension
         document.body.appendChild(link);
         link.click();
      });
      }

      const handleCommissionRazorPay = () => {
        axios({
          url: `${BASEURL}/v1/adminDashboard/charity/gopoolit-commission-report`, //your url
          method: 'POST',
          responseType: 'blob', // important
        contentDisposition: 'attachment',
          data:{
            "from_date": offsetTimeFromDate,
            "to_date": offsetTimeToDate,
            "pool_source_id": 3
          },
        }).then((response) => {
           const url = window.URL.createObjectURL(new Blob([response.data]));
           const link = document.createElement('a');
           link.href = url;
           link.setAttribute('download', `commission_report_razorpay_${fileName}.xlsx`); //or any other extension
           document.body.appendChild(link);
           link.click();
        });
        }
      
  const handleShowToDate = () => {
    setShowToDate(!showToDate)
    setShowFromDate(false)
    setShow(false)
    setError(false)
  }
  const handleShowFromDate = () => {
    setShowFromDate(!showFromDate)
    setShowToDate(false)
    setShow(false)
    setError(false)
  }
  

  const handleRange = async () => {
    setShowFromDate(false)
    setShowToDate(false)
    setShow(true)
    if(offsetTimeFromDate.toISOString().substring(0,10) === offsetTimeToDate.toISOString().substring(0,10)){
      setError(true)
      setShow(false)
    }
  }

    return (
      <div style={{backgroundColor:"#f2f2f2",fontFamily:"DM Sans", width:"100%",paddingTop:"4rem",minHeight:"700px"}}>
      <Container>
        
            <Row className="charities-adminreport">
            <Col lg={8} className="charities-table-header">Download Reports</Col>
            <Col lg={4} className="admin-dropdowns" style={{display:"flex",justifyContent:"flex-end"}}>
                
                {/* Filter Date */}
                {/* <div className="admin-dropdowns">
                  <div className="dropdown-container dropdown-month">
                      <img
                        className="dropdown-arrow"
                        src={calenderImg}
                        alt="dropdownButtonArrow"
                      />
  
                      <DropdownButton
                        className="dropdown-btn"
                        onSelect={handleDate}
                        title={
                          <span className="dropdown-span">
                            {date ? date : "Lifetime"}
                          </span>
                        }
                      >
                        <Dropdown.Item eventKey="today">Today</Dropdown.Item>
                        <Dropdown.Item eventKey="week">This week</Dropdown.Item>
                        <Dropdown.Item eventKey="month">This month</Dropdown.Item>
                        <Dropdown.Item eventKey="lifetime">Lifetime</Dropdown.Item>
                      </DropdownButton>
                    </div>
                    </div>
                 */}
                 <div className="date-container-charityList">
              <div onClick={handleShowFromDate} className="datePicker">{fromDate ? fromDate.toString().substring(4, 15) : "From"}
                <img className='dropdown-calIcon' src={calenderImg} alt='dropdownButtonArrow' />
              </div>
              {showFromDate && <div className="selectDatePicker">
                <SelectDatepicker
                  value={fromDate}
                  onDateChange={onFromDateChange}
                  minDate={new Date(2018, 0, 1)}
                  maxDate={new Date()}
                  showLabels={false}
                  monthNames={["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]}
                  format={"day/month/year"}
                />
              </div>
              }
              <div onClick={handleShowToDate} className="datePicker">{toDate ? toDate.toString().substring(4, 15) : "To"}
                <img className='dropdown-calIcon' src={calenderImg} alt='dropdownButtonArrow' />
              </div>
              {showToDate &&
                <div className="selectDatePicker">
                  <SelectDatepicker
                    value={toDate}
                    onDateChange={onToDateChange}
                    minDate={fromDate ? fromDate : new Date(2018, 0, 1)}
                    maxDate={new Date()}
                    showLabels={false}
                    monthNames={["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]}
                    format={"day/month/year"}
                  />
                </div>
              }
            <Button onClick={handleRange} className="go ml-2">Generate</Button>
            </div>
           </Col>
          </Row>
          {error ? <h4 style={{color:"red",justifyContent:"center",display:"flex",margin:"3rem"}}>Please select a valid date range</h4> : (show && <h4 style={{color:"green",display:"flex",justifyContent:"center",margin:"3rem"}}>Generated Reports Successfully!</h4>) }
          {show && <Row style={{marginTop:"4rem"}}>
            <Col sm={3}><Button variant="link" onClick={handleStripe}>Stripe</Button></Col>
            <Col sm={3}><Button variant="link" onClick={handleRazorPay}>RazorPay</Button></Col>
            <Col sm={3}><Button variant="link" onClick={handleCommissionStripe}>GoPoolit Commission - Stripe</Button></Col>
            <Col sm={3}><Button variant="link" onClick={handleCommissionRazorPay}>GoPoolit Commission - RazorPay</Button></Col>
          </Row>}
          </Container>
          </div>
    );
   
};

export default GenerateReport;


