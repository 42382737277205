import React from 'react';
import '../../assets/scss/CharityFooter/CharityFooter.scss';
import { Navbar, Nav } from 'react-bootstrap';

const Footer = () => {
    return (
        <footer className="footer-wrapper">
            <Navbar>
                <Nav className="footer-wrapper-items">
                    <Nav.Link href="https://www.gopoolit.com/ourPeople" target="_blank">Our People</Nav.Link> <span className="footerDot" style={{ margin: "0 0.5rem" }}>•</span> {/*<span className="footerPipeline">|</span>*/}
                    {/* <Nav.Link href="/charityContact">Are you a non-profit?</Nav.Link> <span className="footerDot" style={{ margin: "0 0.5rem" }}>•</span> <span className="footerPipeline">|</span> */}
                    <Nav.Link href="/charity-form">Contact</Nav.Link> <span className="footerDot" style={{ margin: "0 0.5rem" }}>•</span>  {/*<span className="footerPipeline">|</span>*/}
                    <Nav.Link href="https://www.gopoolit.com/faqs" target="_blank">FAQ</Nav.Link> <span className="footerDot" style={{ margin: "0 0.5rem" }}>•</span>  {/*<span className="footerPipeline">|</span>*/}
                    <Nav.Link href="https://www.gopoolit.com/privacy" target="_blank">Policies</Nav.Link> <span className="footerDot" style={{ margin: "0 0.5rem" }}>•</span>  {/*<span className="footerPipeline">|</span>*/}
                    <Nav.Link href="https://www.gopoolit.com/terms"  target="_blank">Terms</Nav.Link>
                </Nav>
                <Nav className="footer-wrapper-copyright ml-auto">
                    <span>&#169; 2020 GoPoolit Ltd. All rights reserved</span>
                </Nav>
            </Navbar>
        </footer>
    )
}
export default Footer;