import { GET_USER_COUNTRY_DETAILS } from '../../constants/action-types'

const initState = []

const UserCountryDetailsReducer = (state = initState, action) => {
    if (action.type === GET_USER_COUNTRY_DETAILS) {
        return {
            ...state,
            ...action.payload
        }

    }
    return state
}
export default UserCountryDetailsReducer