import axios from 'axios';
import { GET_ADMIN_CHARITY_INFO } from '../constants/action-types';
import { BASEURL } from '../config';

export const adminCharityInfo = (causeID) => (dispatch) => {
    return axios({
        url: `${BASEURL}/v1/adminDashboard/charity-info`,
        method: "POST",
        headers: {
            Accept: "application/json",
            "Access-Control-Allow-Origin": "*",
            // Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            // userexternalid: `${localStorage.getItem("user_external_id")}`,
        },
        data: {
            cause_external_id: causeID
        }
    })
        .then((response) =>
            dispatch({
                type: GET_ADMIN_CHARITY_INFO,
                payload: response.data.response.data,
            })
        )
        .catch((err) => err);
};