import { combineReducers } from 'redux'

// import storingGoogleDataReducer from './Google/storingGoogleData'
// import GoogleResponseReducer from './Google/GoogleResponse';

// import storeFBDataReducer from './Facebook/storingFBData'
// import FBResponseReducer from './Facebook/FBResponse'

// import nexmoOTPReducer from './Nexmo/nexmoOTP'
// import storingPhoneNumberReducer from './Nexmo/storingPhoneNumber'
// import NexmoResponseReducer from './Nexmo/nexmoResponse'

import ProfileSetupReducer from './ProfileSetup/profile';
import ProfilePicUpdateReducer from './ProfileSetup/updatePicture';

import ActivityReducer from './Activity/activityContent';
import ActivityGenerateTokenReducer from './Activity/activityGenerateToken';

import TempPasswordDataReducer from './tempPassword';
import CharityNamesReducer from './CharityNames/CharityNames';
import CountriesListReducer from './CountriesList/countriesList';
import stripeCountries from './CountriesList/stripeCountries';

import UserDetailsReducer from './User/userDetails';
import TokenDetailsReducer from './User/tokenDetails'
import UserCountryDetailsReducer from './User/userCountryDetails';

import VerifyPasswordReducer from './Password/verifypassword';
import UpdatePasswordReducer from './Password/updatepassword';

import ProfileDetailsByIdReducer from './Profile/profiledetailsbyId';
import UpdateProfileDetailsByIdReducer from './Profile/updateprofiledetailsbyId';
import UserProfileDetailsByIdReducer from './Profile/userProfileDetails';
import UserAuthorizedReducer from './userAuthorized';
import dashboardDataReducer from './DashboardData/dashboardData';
import dashboardDataReducerInRange from './DashboardData/dashboardDataInRange';
import reportedPostReducer from './Report/reportedPost'
import CharityIDReducer from './AdminAPI/charityID';
import AdminCharityInfoIDReducer from './AdminAPI/AdminCharityInfo';

import { LOG_OUT } from '../constants/action-types'

const appReducer = combineReducers({
  isUserAuthorized: UserAuthorizedReducer,
  userContent: ActivityReducer,
  userProfile: ProfileSetupReducer,
  newUserToken: UserDetailsReducer,
  contentNewToken: ActivityGenerateTokenReducer,
  tempPassword: TempPasswordDataReducer,
  tokenDetails: TokenDetailsReducer,
  verifyEnteredPassword: VerifyPasswordReducer,
  resetPassword: UpdatePasswordReducer,
  profileDetailsById: ProfileDetailsByIdReducer,
  updateProfileById: UpdateProfileDetailsByIdReducer,
  charityNames: CharityNamesReducer,
  profilePicUpdate: ProfilePicUpdateReducer,
  countriesList: CountriesListReducer,
  stripeCountries:stripeCountries,
  userCountryDetails: UserCountryDetailsReducer,
  userProfileDetails: UserProfileDetailsByIdReducer,
  dashboardDataReducer: dashboardDataReducer,
  dashboardDataReducerInRange: dashboardDataReducerInRange,
  reportedPostReducer: reportedPostReducer,
  CharityIDReducer: CharityIDReducer,
  AdminCharityInfoIDReducer: AdminCharityInfoIDReducer
})

const rootReducer = (state, action) => {
  if (action.type === LOG_OUT) {
    state = undefined
  }
  return appReducer(state, action)
}

export default rootReducer