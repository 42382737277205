
import { GET_ADMIN_CHARITY_INFO } from '../../constants/action-types'

const initState = ""

const AdminCharityInfoIDReducer = (state = initState, action) => {
    if (action.type === GET_ADMIN_CHARITY_INFO) {
        return action.payload
    }
    return state
}
export default AdminCharityInfoIDReducer