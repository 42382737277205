import { STRIPE_COUNTRY_LIST } from '../../constants/action-types';

const initState = {}

const stripeCountries = (state = initState, action) => {
    if (action.type === STRIPE_COUNTRY_LIST) {
        return {
            ...state,
            ...action.payload
        }
    }
    return state
}

export default stripeCountries;