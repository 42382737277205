
import axios from 'axios'
import { BASEURL } from '../../config'

 export const AvgAmountPerPost = (from,to) => {
  
    return axios({
      url: `${BASEURL}/v1/adminDashboard/average-amount-per-post`,
      method: "POST",
      headers: {
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      data: {
        "from_date":from,
        "to_date":to
      }
    })
      .then((response) => response.data.response.data)
      .catch((err) => console.error(err));
  };

  export const AvgAmountLoaded = (from,to) => {
    return axios({
      url: `${BASEURL}/v1/adminDashboard/average-amount-loaded`,
      method: "POST",
      headers: {
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      data: {
        "from_date":from,
        "to_date":to
      }
    })
      .then((response) => response.data.response.data)
      .catch((err) => console.error(err));
  };