import axios from "axios";
import axiosInstance from "./axios";
import { BASEURL } from "../config";

const axiosCreateContent = axios.create({
  baseURL: `${BASEURL}`,
});

axiosCreateContent.interceptors.request.use(
  (request) => {
    request.headers.Authorization = `Bearer ${localStorage.getItem(
      "accessToken"
    )}`;
    request.headers.userexternalid = `${localStorage.getItem(
      "user_external_id"
    )}`;
    return request;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosCreateContent.interceptors.response.use(null, (error) => {
  if (error.config && error.response?.status === 401) {
    // console.log("Token has expired!");
    // new Promise((resolve, reject) => {
    return axiosInstance({
      url: `/v1/tokens`,
      method: "GET",
      headers: {
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${localStorage.getItem("refreshToken")}`,
        userexternalid: `${localStorage.getItem("user_external_id")}`,
      },
      withCredentials: true,
    })
      .then((res) => {
        // console.log(res)
        localStorage.setItem("accessToken", res.data.response.data.accessToken);
        localStorage.setItem(
          "refreshToken",
          res.data.response.data.refreshToken
        );
        // console.log("The token has updated")

        error.config.headers.Authorization = `Bearer ${res.data.response.data.accessToken}`;

        return new Promise((resolve, reject) => {
          axiosInstance
            .request(error.config) // Repeat the initial request
            .then((result) => {
              resolve(result);
            })
            .catch((err) => {
              reject(err);
            });
        });
      })
      .catch((err) => {
        if (axios.isCancel(err)) return;
        // When the refresh token expires, log the user out
        if (err.response.status === 401) {
          return window.location("/"), localStorage.clear();
        }
      });
    // })
  }
});

export default axiosCreateContent;
