import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { useSelector } from 'react-redux'

const AdminPayRoute = ({ component: Component, ...rest }) => {
    const userType = useSelector(state => state.newUserToken.user_type)

    return (
        <Route
            {...rest}
            render={props => {
                if (userType === "superadmin") {
                    return <Component {...props} />
                } else {
                    return <Redirect to="/home" />
                }
            }}
        />
    )
}

export default AdminPayRoute