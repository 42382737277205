import { Link,Redirect ,useHistory} from "react-router-dom";
import React, { useEffect, useState } from "react";
import { Card, Button, Container, Row, Image, Form, Alert, Col, InputGroup } from 'react-bootstrap';
import { useSelector ,useDispatch} from 'react-redux';
import axios from "axios";
import { storeUserDetails } from "../../actions/storingUserData";

import { Login } from "../../api/LoginApi"
import LoginNavbar from '../CharityNavbar/LoginNavbar';
import Footer from "../CharityFooter/Footer";
import { userAuthorized } from "../../actions/isAuthorized";
import { storeCountriesList } from "../../actions/countryList";

//css imports
// import "../../assets/scss/Login/loginPage.scss";

//CSS imports
import '../../assets/scss/CharityLogin/loginPage.scss';


import x from '../../assets/svg/x.svg';


const LoginPage = (props) => {
    const [loginBtn, setLoginBtn] = useState(false);
    const [Password, setPassword] = useState("");
    const [showPasswordIcon, setShowPasswordIcon] = useState(false);
    const [Email, setEmail] = useState("");
    const [validationMsg, setValidationMsg] = useState("")
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch();
    const isAuthorized = useSelector(state => state.isUserAuthorized.isAuthorized)
    const history = useHistory();

    if(isAuthorized){
      history.push("/home");
    }

      // useEffect(()=>{
      //   dispatch(storeCountriesList());
      // },[])

    const showPassword = (
        <svg
          width="19"
          height="14"
          viewBox="0 0 19 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1 7C1 7 4 1 9.25 1C14.5 1 17.5 7 17.5 7C17.5 7 14.5 13 9.25 13C4 13 1 7 1 7Z"
            stroke="#757575"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M9.25 9.25C10.4926 9.25 11.5 8.24264 11.5 7C11.5 5.75736 10.4926 4.75 9.25 4.75C8.00736 4.75 7 5.75736 7 7C7 8.24264 8.00736 9.25 9.25 9.25Z"
            stroke="#757575"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );

      const hidePassword = (
        <svg
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10.5418 10.5417C10.3421 10.756 10.1012 10.928 9.83357 11.0472C9.56593 11.1665 9.27702 11.2306 8.98407 11.2357C8.69111 11.2409 8.40012 11.187 8.12844 11.0773C7.85677 10.9676 7.60998 10.8042 7.4028 10.597C7.19562 10.3899 7.03229 10.1431 6.92255 9.8714C6.81282 9.59972 6.75893 9.30873 6.7641 9.01577C6.76927 8.72282 6.83339 8.43391 6.95264 8.16628C7.07189 7.89864 7.24382 7.65777 7.45818 7.45802M13.32 13.3198C12.0768 14.2675 10.563 14.7925 9 14.818C3.90909 14.818 1 8.99984 1 8.99984C1.90465 7.31395 3.15937 5.84101 4.68 4.67984L13.32 13.3198ZM7.47273 3.3562C7.97333 3.23903 8.48586 3.18045 9 3.18166C14.0909 3.18166 17 8.99984 17 8.99984C16.5585 9.82574 16.032 10.6033 15.4291 11.3198L7.47273 3.3562Z"
            stroke="#757575"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M1 1L17 17"
            stroke="#757575"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );


    const myFunction = () => {
        var x = document.getElementById("myInput");
        if (x.type === "password") {
          x.type = "text";
          setShowPasswordIcon(true);
        } else {
          x.type = "password";
          setShowPasswordIcon(false);
        }
      };

   
      const handleEmail = (e) => {
        e.preventDefault();
        setValidationMsg("")
        setEmail(e.target.value);
       
      };
      const handlePassword = (e) => {
        e.preventDefault();
        setValidationMsg("")
        setPassword(e.target.value)
      }

      const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true)
        let res = await Login(Email, Password);//username login
        if (res && res.response && res.response.statusCode === 401) {
          setLoading(false)
          setValidationMsg("Invalid Username or Password");
        }
  
        if (res.response.statusCode === 200) {
          setLoading(false)
          localStorage.setItem("accessToken", res.response.data.accessToken);
          localStorage.setItem("refreshToken", res.response.data.refreshToken);
          localStorage.setItem(
            "user_external_id",
            res.response.data.db_user_external_id
          );
        dispatch(storeUserDetails(res.response.data));
          dispatch(userAuthorized(true));
         
          props.history.push('/home')
        }
      }

      

  return (
    <section className="charity-login-container">
    <LoginNavbar />
    <Container className="charitylogin-page-container">
        <Row>
        <Col sm={12} className="login-page-contents">
                <Card className="card">
                    <Card.Header className="header">Charity Login
                     <Link to="/" className="close">
                            <img src={x} alt="close-button" /></Link></Card.Header>
                    <Card.Body>
                        <Form className="password-form" onSubmit= {(e)=>handleSubmit( e, Email,  Password) }>
                            <Form.Group className="email">
                                <Form.Label className="password-form-label">Email address</Form.Label>
                                <Form.Control size="lg" type="email" value={Email}
                                onChange={(e) => handleEmail(e)} placeholder="Email address" required />
                            </Form.Group>
                            <Form.Group className="password">
                           <Form.Label className="password-form-label">Password</Form.Label>
                            <InputGroup>
                                <Form.Control size="lg" type="password" id="myInput" onChange={(e) => handlePassword(e)}  
                                 value={Password} placeholder="Password" required />
                            <InputGroup.Append>
                            <InputGroup.Text id="basic-addon2">
                                {showPasswordIcon ? (
                                    <span onClick={myFunction}>{showPassword}</span>
                                    ) : (
                                        <span onClick={myFunction}>{hidePassword}</span>
                                        )}
                            </InputGroup.Text>
                            
                        </InputGroup.Append>
                            </InputGroup>
                            <span style={{ fontSize: "14px", display: "flex", marginLeft: "5px" }} className="error-message">
                                {validationMsg}
                            </span>
                           </Form.Group>
                            {/* <p className="forgotten-password"><a href="/charity-resetpasswordlink">Forgotten Password?</a></p> */}
                            {/* <Button className="submit-btn" type="submit">Login</Button> */}
                            {loading ?
                      <Button
                        className="mt-4 blue-buttonActive"
                        type="submit"
                        // disabled={valid}
                        style={{ width: "100%" }}
                        disabled
                      >
                        Please wait ...
                   </Button>
                      : <Button
                        className="mt-4 blue-buttonActive"
                        type="submit"
                        // disabled={valid}
                        style={{ width: "100%" }}
                      >
                        Login
                   </Button>}
                            <p className="footer-text">
                             Trouble signing in?
                                <a href="/charity-form"><span className="ml-1"> Contact Us</span></a>
                            </p>
                        </Form>
                    </Card.Body>
                </Card>
                </Col>
        </Row>
    </Container>
    <Footer />
    </section>
  );
};

export default LoginPage;


