import React, { useState, useEffect } from "react";
import { Table, Container, Row, Col, DropdownButton, Dropdown, Image, Button } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import dropdownButtonArrow from '../../assets/svg/dropdownButtonArrow.svg';
import avatar from '../../assets/images/avatar.png';
import { ListAllUsers, ListAllRoles, UpdateUserRole } from '../../api/Users/EditUsers'
import { UpdateUserStatus } from '../../api/Users/EditUsers'
import AssignRoleModal from "./AssignRoleModal";
import UpdateRoleModal from "./UpdateRoleModal";
import UpdateUserData from "./UpdateUserData";
import { loadAllCharityNames } from "../../actions/CharityList"
import NavBarDashboard from "../Dashboard/DashboardNavbar";
import { Link } from "react-router-dom";

const EditUsers = (props) => {

  const [Users, setUsers] = useState([]);
  const [Roles, setRoles] = useState([]);
  const [RoleId, setRoleId] = useState();
  const [Role, setRole] = useState();
  const [isActive, setIsActive] = useState("")
  const [isActiveVal, setIsActiveVal] = useState(true)
  const [updatedUserIdArr, setUpdatedUserIdArr] = useState([])
  // const [updateRole, setUpdateRole] = useState(false);
  const [roleUpdatedId, setRoleUpdatedId] = useState(false);
  const [updatedRole, setUpdatedRole] = useState(false);
  const [updatedRoleUserId, setUpdatedRoleUserId] = useState("");
  const [userIdAssignRole, setUserIdAssignRole] = useState("");
  const [usernameAssignRole, setUsernameAssignRole] = useState("");
  const [emailAssignRole, setEmailAssignRole] = useState("");
  const [roleModal, setRoleModal] = useState();
  const [countryOfUser, setCountryOfUser] = useState();
  const [causeOfUser, setCauseOfUser] = useState();
  const [showReportModal, setShowReportModal] = useState(false);
  const [reportModalUpdateRole, setReportModalUpdateRole] = useState(false);
  const [reportModalUpdateUserData, setReportModalUpdateUserData] = useState(false);
  const countriesList = useSelector(state => state.countriesList.data);
  const charityData = useSelector((state) => state.charityNames.data);
  const myUserExternalId = useSelector((state) => state.newUserToken.db_user_external_id);
  const [sortBy, setSortBy] = useState("");

  const editIcon = (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.0002 4.11956C17.9308 4.05021 17.8366 4.01168 17.7385 4.01272C17.6408 4.01341 17.5472 4.0516 17.4769 4.11956L12.1998 9.39651L12.1851 9.41139L12.1777 9.41878C12.1705 9.42985 12.1594 9.44093 12.152 9.45189C12.152 9.45558 12.1483 9.45558 12.1483 9.45927C12.1409 9.47024 12.1371 9.47774 12.1298 9.4887C12.126 9.49239 12.126 9.4962 12.1225 9.49977C12.1188 9.51085 12.1152 9.51823 12.1114 9.52931C12.1114 9.533 12.1077 9.533 12.1077 9.53658L11.1679 12.3705C11.1229 12.5024 11.1572 12.6486 11.2565 12.7464C11.3261 12.8151 11.4202 12.8535 11.518 12.8532C11.558 12.8524 11.5978 12.8463 11.636 12.8348L14.4626 11.8915C14.4661 11.8915 14.4661 11.8915 14.4698 11.8878C14.4815 11.8844 14.4928 11.8795 14.503 11.8731C14.5058 11.8726 14.5085 11.8714 14.5104 11.8693C14.5214 11.862 14.5362 11.8546 14.5473 11.8471C14.5582 11.8398 14.5693 11.8287 14.5804 11.8214C14.5842 11.8178 14.5878 11.8178 14.5878 11.814C14.5914 11.8103 14.5988 11.8067 14.6026 11.7994L19.8832 6.51862C19.9523 6.44963 19.991 6.35617 19.991 6.25879C19.991 6.16129 19.9523 6.06772 19.8832 5.99896L18.0002 4.11956ZM12.62 10.34L13.6629 11.3829L12.1003 11.9024L12.62 10.34ZM14.3447 11.0217L12.9811 9.6583L17.7385 4.90078L19.1019 6.26421L14.3447 11.0217Z"
        fill="#757575"
      />
      <path
        d="M17.1302 10.9003C16.9269 10.9009 16.7622 11.0655 16.7617 11.2688V18.1563C16.7599 18.7661 16.2661 19.2599 15.6561 19.2619H5.8465C5.23673 19.2599 4.74268 18.7661 4.74094 18.1563V8.3465C4.74268 7.73673 5.23673 7.24279 5.8465 7.24094H12.7338C12.9374 7.24094 13.1023 7.07595 13.1023 6.87243C13.1023 6.6689 12.9374 6.50391 12.7338 6.50391H5.8465C4.82898 6.50437 4.00437 7.32909 4.00391 8.3465V18.1563C4.00437 19.1737 4.82898 19.9985 5.8465 19.9988H15.6561C16.6736 19.9985 17.4983 19.1737 17.4987 18.1563V11.2688C17.4981 11.0655 17.3335 10.9009 17.1302 10.9003Z"
        fill="#757575"
      />
    </svg>
  );

  const dispatch = useDispatch(); //Used to dispatch the actions from the component
//Lists all the charities
useEffect(() => {
  dispatch(loadAllCharityNames())
}, [])
 
  
  useEffect(() => {
    async function fetchData() {
      let res = await ListAllUsers()
      setUsers(res)
      let allroles = await ListAllRoles()
      setRoles(allroles)
    }
    fetchData();
  }, []);

 
  const sortUsers = (usersArr) => {
    if(sortBy === "User Name"){
      // let usersSortedByName = usersArr.sort((a, b) => (a.username > b.username) ? 1 : ((b.username > a.username) ? -1 : 0))
      let usersSortedByName =  usersArr.sort( function(a, b)  {
        let va = (a.username === null) ? "" : "" + a.username.toLowerCase(),
            vb = (b.username === null) ? "" : "" + b.username.toLowerCase();
        return va > vb ? 1 : ( va === vb ? 0 : -1 );
      } );
      return usersSortedByName
    } else if(sortBy === "Role"){
        // let usersSortedByRole = usersArr.sort((a, b) => (a.user_type > b.user_type ) ? 1 : ((b.user_type > a.user_type) ? -1 : 0))
        // let usersSortedByRole = usersArr.sort((a, b) => (a.user_type > b.user_type ) ? 1 : ((b.user_type > a.user_type) ? -1 : 0))
       let usersSortedByRole =  usersArr.sort( function(a, b)  {
              let va = (a.user_type === null) ? "" : "" + a.user_type,
                  vb = (b.user_type === null) ? "" : "" + b.user_type;
              return va > vb ? 1 : ( va === vb ? 0 : -1 );
      } );
        return usersSortedByRole
    } else if(sortBy === "Country Name"){
      let usersSortedByCountry =  usersArr.sort( function(a, b)  {
        let va = (a.country_external_id === null) ? "" : "" + getCountryName(a.country_external_id);
        let vb = (b.country_external_id === null) ? "" : "" + getCountryName(b.country_external_id);
            va = va.replace(/(^[,\s]+)|([,\s]+$)/g, '').toLowerCase();
            vb = vb.replace(/(^[,\s]+)|([,\s]+$)/g, '').toLowerCase();
        return va > vb ? 1 : ( va === vb ? 0 : -1 );
      } );
      return usersSortedByCountry
    } else if(sortBy === "Cause Name"){
      let usersSortedByCause =  usersArr.sort( function(a, b)  {
        let va = (a.cause_external_id === null) ? "" : "" + getCharityName(a.cause_external_id);
        let vb = (b.cause_external_id === null) ? "" : "" + getCharityName(b.cause_external_id);
        va = va.replace(/(^[,\s]+)|([,\s]+$)/g, '').toLowerCase();
        vb = vb.replace(/(^[,\s]+)|([,\s]+$)/g, '').toLowerCase();
        return va > vb ? 1 : ( va === vb ? 0 : -1 );
      } );
      return usersSortedByCause
    } else {
      let usersSortedByName =  usersArr.sort( function(a, b)  {
        let va = (a.username === null) ? "" : "" + a.username.toLowerCase(),
            vb = (b.username === null) ? "" : "" + b.username.toLowerCase();
        return va > vb ? 1 : ( va === vb ? 0 : -1 );
      } );
      return usersSortedByName
    }
  }

  // const sortByRole = (usersArr) => {
  //   let usersSortedByRole = usersArr.sort((a, b) => (a.user_type > b.user_type) ? 1 : ((b.user_type > a.user_type) ? -1 : 0))
  //   return usersSortedByRole
  // }

  const getCountryName = (countryExternalId) => {
    return Object.values(countriesList).map((country) => {
      if (country.country_external_id === countryExternalId) {
        return country.country_name
      }
    });
  }

  const getCharityName = (charityId) => {
    return charityData && charityData.map((cause, index) => {
      if (cause.cause_external_id === charityId) {
        return cause.cause_name
      }
    })
  }


  const text_truncate = (str, length, ending) => {
    if (length == null) {
      length = 100;
    }
    if (ending == null) {
      ending = "...";
    }
    if (str.length > length) {
      return str.substring(0, length - ending.length) + ending;
    } else {
      return str;
    }
  };

  const handleRoles = async (e) => {
    if (e !== null) {
      const role = e;
      const name = role.split(", ");
      setRoleId(name[0]);
      setRole(name[1]);
      let res = await ListAllUsers(name[1], isActiveVal);
      setUsers(res)
    } else {
      let res = await ListAllUsers(null, isActiveVal);
      setUsers(res)
      setRole();
      setRoleId("");
    }
  };

  const handleActive = async (e) => {
    setUpdatedUserIdArr("")
    if (e !== null) {
      if (e === "active") {
        setIsActiveVal(true)
        setIsActive("Active")
        let res = await ListAllUsers(Role, true);
        setUsers(res)
      } else if (e === "inActive") {
        setIsActiveVal(false)
        setIsActive("InActive")
        let res = await ListAllUsers(Role, false);
        setUsers(res)
      }
    } else {
      setIsActive(true)
      setIsActive("Active")
      let res = await ListAllUsers(true)
      setUsers(res)
    }
  };

  const handleAssignRole = (val, userId, name, email) => {
    setShowReportModal(val)
    setUserIdAssignRole(userId)
    setUsernameAssignRole(name)
    setEmailAssignRole(email)
  }

  const handleUpdateRole = (val, userId, name, email, role) => {
    setReportModalUpdateRole(val)
    setUserIdAssignRole(userId)
    setUsernameAssignRole(name)
    setEmailAssignRole(email)
    setRoleModal(role)
  }

  const handleUpdateUserData = (val, userId, name, email, role, country_id, cause_id) => {
    setReportModalUpdateUserData(val)
    setUserIdAssignRole(userId)
    setUsernameAssignRole(name)
    setEmailAssignRole(email)
    setRoleModal(role)
    setCountryOfUser(country_id)
    setCauseOfUser(cause_id)
  }
  // const charityAndCountryAdminRoles = Roles.filter(role=>{
  // if(role.type === "charity" || role.type === "countryadmin") 
  // return role
  // })

  const AdminSuperAdminContentMod = Roles.filter(role => {
    if (role.type === "contentmoderator" || role.type === "admin" || role.type === "superadmin")
      return role
  })


  const deactivateUser = (userId) => {
    const newArr = Users.filter(user => user.db_user_external_id !== userId)
    setUsers(newArr)
    // setUpdatedUserIdArr(...updatedUserIdArr,userId)
    setUpdatedUserIdArr(updatedUserIdArr => [...updatedUserIdArr, userId]);
    UpdateUserStatus(userId, false)
  }


  const activateUser = (userId) => {
    const newArr = Users.filter(user => user.db_user_external_id !== userId)
    setUsers(newArr)
    setUpdatedUserIdArr(updatedUserIdArr => [...updatedUserIdArr, userId]);
    // setUpdatedUserIdArr(...updatedUserIdArr,userId)
    UpdateUserStatus(userId, true)

  }

  return (
    <>
      <NavBarDashboard />
      <section style={{ backgroundColor: "#F2F2F2" }} >
        <div className="admin-dashboard-navbar" style={{ display: "flex", justifyContent: "center", backgroundColor: "white", padding: "0.5rem" }}>
          <Link to="/users" className="dashboard-navbar-elements " style={{ margin: "0px 1rem",textDecoration: "none" }}>Create New User</Link>
          <Link to="/edit-user" style={{textDecoration: "none"}} className="dashboard-navbar-elements nav-admin-reports-active" >Edit Users</Link>
        </div>
        {/* {value == 2 && <EditRoles />} */}
        <Container fluid style={{ fontFamily: "DM Sans" }}>
          <Row style={{ justifyContent: "center" }}>
            <Col className="admin-dropdowns" style={{ display: "flex", justifyContent: "end" }} >

              <div style={{ top: "0px" }} className="dropdown-container">
                <img
                  className="dropdown-arrow"
                  src={dropdownButtonArrow}
                  alt="dropdownButtonArrow"
                />
                <DropdownButton
                  className="dropdown-btn "
                  onSelect={handleRoles}
                  title={
                    <span className="dropdown-span">
                      {Role ? text_truncate(Role, 25) : "Role"}
                    </span>
                  }
                >
                  <Dropdown.Item eventkey=" ">Role</Dropdown.Item>
                  {Roles && Roles.map((item, index) => {
                    return (
                      <Dropdown.Item
                        eventKey={`${item.role_external_id}, ${item.type}`}
                        key={item.role_external_id}
                      >
                        {item.type}
                      </Dropdown.Item>
                    )
                  })}
                </DropdownButton>
              </div>
              <div style={{ top: "0px" }} className="dropdown-container">
                <img
                  className="dropdown-arrow"
                  src={dropdownButtonArrow}
                  alt="dropdownButtonArrow"
                />
                <DropdownButton
                  className="dropdown-btn dropdown-container"

                  onSelect={handleActive}

                  title={
                    <span className="dropdown-span">
                      {isActive ? isActive : "Active"}
                    </span>
                  }
                >

                  <Dropdown.Item eventKey="active" > Active </Dropdown.Item>
                  <Dropdown.Item eventKey="inActive" > InActive </Dropdown.Item>

                </DropdownButton>
              </div>

                  {/* sort filter */}
              <div style={{ top: "0px" }} className="dropdown-container">
                <img
                  className="dropdown-arrow"
                  src={dropdownButtonArrow}
                  alt="dropdownButtonArrow"
                />
                <DropdownButton
                  className="dropdown-btn dropdown-container"

                  onSelect={(e)=>{setSortBy(e)}}

                  title={
                    <span className="dropdown-span">
                      {sortBy ? `Sort by ${sortBy}` : "Sort"}
                    </span>
                  }
                >

                  <Dropdown.Item eventKey="User Name" > User Name </Dropdown.Item>
                  <Dropdown.Item eventKey="Role" > Role </Dropdown.Item>
                  <Dropdown.Item eventKey="Country Name" > Country Name </Dropdown.Item>
                  <Dropdown.Item eventKey="Cause Name" > Cause Name </Dropdown.Item>

                </DropdownButton>
              </div>

            </Col>
          </Row>
          <Row style={{ justifyContent: "center" }}>
            <Col>


              <Table>
                <thead>
                  <tr>
                    <th style={{ textAlign: "start" }}>Sl No.</th>
                    <th style={{ textAlign: "start" }}>User Name</th>
                    <th style={{ textAlign: "start" }}>Email Address</th>
                    <th style={{ textAlign: "start" }}>Country</th>
                    <th style={{ textAlign: "start" }}>Cause</th>
                    <th style={{ textAlign: "start" }}>Role </th>
                    <th style={{ textAlign: "start" }}>Edit Role </th>
                    <th style={{ textAlign: "start" }}></th>
                  </tr>
                </thead>
                <tbody>

                  {/* {Users && Users.sort((a, b) => (a.username > b.username) ? 1 : ((b.username > a.username) ? -1 : 0)) && Users.map((item, index) => { */}
                  {Users && sortUsers(Users).map((item, index) => {
                    //users after deactivating a user
                    if (updatedUserIdArr.length > 0) {
                      for (let updatedUser of updatedUserIdArr) {
                        if (updatedUser !== item.db_user_external_id) {
                          return <tr key={item.db_user_external_id}>
                            <td style={{ textAlign: "start" }}>{index + 1}</td>
                            <td style={{ textAlign: "start" }}>
                              <span style={{cursor:"pointer"}} onClick={(() => handleUpdateUserData(true, item.db_user_external_id, item.username, item.user_email_address, item.user_type, item.country_external_id, item.cause_external_id))}>{editIcon}</span>
                              <Image style={{ width: "2rem", height: "2rem", margin: "0px 0.4rem" }} src={avatar} roundedCircle />{item.username}
                            </td>

                            <td style={{ textAlign: "start" }}>{text_truncate(item.user_email_address,20)}</td>
                            <td style={{ textAlign: "start" }}>{getCountryName(item.country_external_id)}</td>
                            <td style={{ textAlign: "start" }}>{getCharityName(item.cause_external_id)}</td>
                            <td style={{ textAlign: "start" }}>{item.user_type}</td>
                            {item.user_type ?
                              <td><Button variant="light" style={{ fontSize: "14px" }} onClick={(() => handleUpdateRole(true, item.db_user_external_id, item.username, item.user_email_address, item.user_type))} >Update Role</Button></td>
                              : <><td><Button variant="light" style={{ fontSize: "14px" }} onClick={(() => handleAssignRole(true, item.db_user_external_id, item.username, item.user_email_address))} >Assign Role</Button></td></>}
                            {isActiveVal ? <td>{(myUserExternalId !== item.db_user_external_id) ? <Button variant="danger" style={{ fontSize: "14px" }} onClick={(() => deactivateUser(item.db_user_external_id))} >Deactivate User</Button>
                      : <Button disabled variant="danger" style={{ fontSize: "14px",cursor:"not-allowed" }} >Deactivate User</Button>}</td>
                              : <td><Button variant="success" onClick={(() => activateUser(item.db_user_external_id))} style={{ fontSize: "14px" }}>Activate User</Button></td>
                            }
                          </tr>
                        }
                      }
                    }
                    //active users
                    else return <tr key={item.db_user_external_id}>
                      <td style={{ textAlign: "start" }}>{index + 1}</td>

                      <td style={{ textAlign: "start" }}>
                        <span style={{cursor:"pointer"}} onClick={(() => handleUpdateUserData(true, item.db_user_external_id, item.username, item.user_email_address, item.user_type, item.country_external_id, item.cause_external_id))}>{editIcon}</span>
                        <Image style={{ width: "2rem", height: "2rem", margin: "0px 0.4rem" }} src={avatar} roundedCircle />{item.username}
                      </td>
                      <td style={{ textAlign: "start" }}>{text_truncate(item.user_email_address,20)}</td>
                      <td style={{ textAlign: "start" }}>{getCountryName(item.country_external_id)}</td>
                      <td style={{ textAlign: "start" }}>{getCharityName(item.cause_external_id)}</td>
                      <td style={{ textAlign: "start" }}>{item.user_type}</td>
                      {item.user_type ?
                        <td><Button variant="light" style={{ fontSize: "14px" }} onClick={(() => handleUpdateRole(true, item.db_user_external_id, item.username, item.user_email_address, item.user_type))} >Update Role</Button></td>
                        : <><td><Button variant="light" style={{ fontSize: "14px" }} onClick={(() => handleAssignRole(true, item.db_user_external_id, item.username, item.user_email_address))} >Assign Role</Button></td></>}
                      {isActiveVal ? <td>{(myUserExternalId !== item.db_user_external_id) ? <Button variant="danger" style={{ fontSize: "14px" }} onClick={(() => deactivateUser(item.db_user_external_id))} >Deactivate User</Button>
                      : <Button disabled variant="danger" style={{ fontSize: "14px",cursor:"not-allowed" }} >Deactivate User</Button>}</td>
                        : <td><Button variant="success" onClick={(() => activateUser(item.db_user_external_id))} style={{ fontSize: "14px" }}>Activate User</Button></td>
                      }
                    </tr>
                  })}
                </tbody>
              </Table>
            </Col>
          </Row>
        </Container>

      </section>

      {showReportModal ? (
        <AssignRoleModal showReportModal={showReportModal}
          handleAssignRole={handleAssignRole}
          userId={userIdAssignRole}
          username={usernameAssignRole}
          email={emailAssignRole}
        />
      ) : (
          ""
        )
      }
      {reportModalUpdateRole ? (
        <UpdateRoleModal showReportModal={reportModalUpdateRole}
          handleUpdateRole={handleUpdateRole}
          userId={userIdAssignRole}
          username={usernameAssignRole}
          email={emailAssignRole}
          role={roleModal}
        />
      ) : (
          ""
        )
      }
      {reportModalUpdateUserData ? (
        <UpdateUserData showReportModal={reportModalUpdateUserData}
          handleUpdateUserData={handleUpdateUserData}
          userId={userIdAssignRole}
          username={usernameAssignRole}
          email={emailAssignRole}
          role={roleModal}
          country_id={countryOfUser}
          cause_id={causeOfUser}
        />
      ) : (
          ""
        )
      }

    </>
  );
};

export default EditUsers;
