import { USER_CONTENT_DATA } from '../../constants/action-types'

const initState = {}

const ActivityReducer = ( state = initState, action ) => {
    if (action.type === USER_CONTENT_DATA) {
        return {
            ...state,
            ...action.payload
        }
        
    }
    return state
}
export default ActivityReducer