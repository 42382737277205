import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { Card, Button, Container, Form, InputGroup, Spinner } from 'react-bootstrap'
import { Redirect, Link } from "react-router-dom";
import axios from 'axios';

//file imports 
import { updatePassword } from '../../../actions/login';

//CSS imports
import '../../../assets/scss/Login/enterPassword.scss';
import Footer from "../../CharityFooter/Footer";
import LoginNavbar from "../../CharityNavbar/LoginNavbar";
import x from '../../../assets/svg/x.svg';

import { BASEURL } from '../../../config';


const ResetPassword = (props) => {
    // console.log(props);
    const tokenDetails = useSelector((state) => state.tokenDetails);
    const updatePasswordResponse = useSelector((state) => state.resetPassword.data);
    // console.log("updatePasswordResponse", updatePasswordResponse);
    const [Password, setPassword] = useState("");
    const [ReTypedPassword, setReTypedPassword] = useState("");
    const [showPasswordIcon, setPasswordIcon] = useState(false);
    const [showReenterPasswordIcon, setReenterPasswordIcon] = useState(false);
    const [changePasswordBtn, setChangePasswordBtn] = useState(false);
    const [passwordMessage, setpasswordMessage] = useState("");
    const [passwordMatchedmessage, setPasswordMatchedMessage] = useState("");
    const [resetPwdResponseMsg, setresetPwdResponseMsg] = useState("");
    const [enterPwdScreen, setEnterPwdScreen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [passwordRuleMsg, setPasswordRuleMsg] = useState("Use 8 or more characters with a mix of letters, numbers, symbols and not same as UserName");
    const [passwordValidation, setPasswordValidation] = useState("");
    const [pwdSuccessMsg, setPwdSuccessMsg] = useState("");

    const [resetToken, setResetToken] = useState("");
    const [userExternalID, setUserExternalID] = useState("");

    const dispatch = useDispatch();
    const inputRef = useRef();

    const hidePassword = <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10.5418 10.5417C10.3421 10.756 10.1012 10.928 9.83357 11.0472C9.56593 11.1665 9.27702 11.2306 8.98407 11.2357C8.69111 11.2409 8.40012 11.187 8.12844 11.0773C7.85677 10.9676 7.60998 10.8042 7.4028 10.597C7.19562 10.3899 7.03229 10.1431 6.92255 9.8714C6.81282 9.59972 6.75893 9.30873 6.7641 9.01577C6.76927 8.72282 6.83339 8.43391 6.95264 8.16628C7.07189 7.89864 7.24382 7.65777 7.45818 7.45802M13.32 13.3198C12.0768 14.2675 10.563 14.7925 9 14.818C3.90909 14.818 1 8.99984 1 8.99984C1.90465 7.31395 3.15937 5.84101 4.68 4.67984L13.32 13.3198ZM7.47273 3.3562C7.97333 3.23903 8.48586 3.18045 9 3.18166C14.0909 3.18166 17 8.99984 17 8.99984C16.5585 9.82574 16.032 10.6033 15.4291 11.3198L7.47273 3.3562Z" stroke="#757575" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M1 1L17 17" stroke="#757575" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    </svg>

    const showPassword = <svg width="19" height="14" viewBox="0 0 19 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 7C1 7 4 1 9.25 1C14.5 1 17.5 7 17.5 7C17.5 7 14.5 13 9.25 13C4 13 1 7 1 7Z" stroke="#757575" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M9.25 9.25C10.4926 9.25 11.5 8.24264 11.5 7C11.5 5.75736 10.4926 4.75 9.25 4.75C8.00736 4.75 7 5.75736 7 7C7 8.24264 8.00736 9.25 9.25 9.25Z" stroke="#757575" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    </svg>

    useEffect(() => {
        if ((Password === ReTypedPassword) && (Password.length > 0)) {
            setChangePasswordBtn(true);
            setIsLoading(false);
            setpasswordMessage("")
            setPasswordMatchedMessage("Password Match");
        }
        else if (ReTypedPassword.length > 0) {
            setChangePasswordBtn(false);
            setIsLoading(false);
            setpasswordMessage("Passwords don't match. Enter valid password")
            setPasswordMatchedMessage("");
        }
    }, [Password, ReTypedPassword])

    useEffect(() => {
        if ((Password === ReTypedPassword) && (Password.length > 0)) {
            setChangePasswordBtn(true);
        }
    }, [Password, ReTypedPassword])


    useEffect(() => {
        if (updatePasswordResponse) {
            if (updatePasswordResponse.response.statusCode === 200) {
                setresetPwdResponseMsg("Password Updated Successfully");
                //setEnterPwdScreen(true);
            }
            else {
                setresetPwdResponseMsg("Password Update Unsuccessful,Please try again.");
                //setEnterPwdScreen(false);
            }
        }
    }, [updatePasswordResponse])

    useEffect(() => {
        const query = new URLSearchParams(props.location.search);
        const resetToken = query.get('resetToken')
        const user_external_id = query.get('user_external_id');
        // console.log(`${resetToken} ${user_external_id}`)
        setResetToken(resetToken)
        setUserExternalID(user_external_id)
    }, [])

    const myFunction = () => {
        var x = document.getElementById("myInput");
        if (x.type === "password") {
            x.type = "text";
            setPasswordIcon(true);

        } else {
            x.type = "password";
            setPasswordIcon(false);
            setReenterPasswordIcon(false);


        }
    }
    const reEnterPassword = () => {
        var x = document.getElementById("ReEnterPasswordInput");
        if (x.type === "password") {
            x.type = "text";
            setReenterPasswordIcon(true);


        } else {
            x.type = "password";
            setReenterPasswordIcon(false);


        }
    }
    const passwordValidate = () => {
        if (Password.length > 0) {
            var lower = /(?=.*[a-z])/;
            var upper = /(?=.*[A-Z])/;
            var value = /(?=.*\d)/;
            var spclchar = /(?=.*[!@#$%^&*])/;
            if (Password.length < 8) {
                inputRef.current.focus();
                setPasswordValidation("Password should be atleast minimum 8 characters");
                setPasswordRuleMsg("");
                // setPwdSuccessMsg("");
                return false;
            }
            else if (!lower.test(Password)) {
                setPasswordValidation("Password should contain one LowerCase");
                setPasswordRuleMsg("");
                inputRef.current.focus();
                // setPwdSuccessMsg("");
                return false;
            }
            else if (!upper.test(Password)) {
                setPasswordValidation("Password should contain one UpperCase");
                setPasswordRuleMsg("");
                inputRef.current.focus();
                // setPwdSuccessMsg("");
                return false;
            }
            else if (!value.test(Password)) {
                setPasswordValidation("Password should contain atleast one Number");
                setPasswordRuleMsg("");
                inputRef.current.focus();
                // setPwdSuccessMsg("");
                return false;
            }
            else if (!spclchar.test(Password)) {
                setPasswordValidation("Password should contain atleast one special character");
                setPasswordRuleMsg("");
                inputRef.current.focus();
                // setPwdSuccessMsg("");
                return false;
            }
            else {
                setPasswordValidation("");
                setPwdSuccessMsg("Password is strong");
                setPasswordRuleMsg("");
                return true;
            }
        }

    }
    const handleSubmit = (e, Password, ReTypedPassword) => {
        e.preventDefault();
        if ((passwordValidate() === true)) {
            setIsLoading(true);
            axios({
                method: "POST",
                url: `${BASEURL}/v1/resetpassword`,
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${resetToken}`,
                    "userexternalid": `${userExternalID}`
                },
                data: {
                    user_external_id: userExternalID,
                    password: Password
                },
                withCredentials: true,
            });
            //dispatch(updatePassword(Password, tokenDetails.external_id));
        }
    }

    return (
        <div className="password-container">
            <LoginNavbar />
            <Container>
                <div className="row">
                    <div className="col-sm-12">
                        <Card className="password-card">
                            <Card.Header className="password-header">Reset Password
                            <Link to="/password" className="password-close">
                                    <img src={x} alt="close-button" /></Link></Card.Header>
                            <Card.Body>
                                <Form className="password-form" onSubmit={(e) => handleSubmit(e, Password)}>
                                    <Form.Label className="password-form-label">Set Password *</Form.Label>
                                    <InputGroup className="mb-3">
                                        <Form.Control size="lg" type="password" id="myInput" placeholder="enter Password" onChange={e => setPassword(e.target.value)} ref={inputRef} value={Password} />

                                        <InputGroup.Append>
                                            <InputGroup.Text id="basic-addon2">{showPasswordIcon ? <span onClick={myFunction}>{showPassword}</span> : <span onClick={myFunction}>{hidePassword}</span>}</InputGroup.Text>
                                        </InputGroup.Append>


                                        {/* </Form.Group> */}
                                    </InputGroup>
                                    <span style={{ fontSize: "12px", lineHeight: "18px", display: "block" }}>{passwordRuleMsg}</span>
                                    <span className="error-message">{passwordValidation}</span>
                                    <span style={{ color: "green" }}>{pwdSuccessMsg}</span>
                                    <Form.Group>
                                        <Form.Label className="password-form-label">Re-type Password *</Form.Label>
                                        <InputGroup className="mb-3">

                                            <Form.Control size="lg" type="password" placeholder="re-enter Password" id="ReEnterPasswordInput" onChange={e => setReTypedPassword(e.target.value)} value={ReTypedPassword} />
                                            <InputGroup.Append>
                                                <InputGroup.Text id="basic-addon3">{showReenterPasswordIcon ? <span onClick={reEnterPassword}>{showPassword}</span> : <span onClick={reEnterPassword}>{hidePassword}</span>}</InputGroup.Text>
                                            </InputGroup.Append>
                                        </InputGroup>
                                        <span style={{ color: "red" }}>{passwordMessage}</span>
                                        <span style={{ color: "green" }}>{passwordMatchedmessage}</span>
                                    </Form.Group>
                                    {/* {disablebtn ? <Button className="profilesetup-button" type="submit" disabled>Save</Button> : <Button className="profilesetup-button" type="submit">Save</Button>} */}
                                    {changePasswordBtn ? <Button className="password-enable-button" style={{ marginTop: "40px" }} type="submit">Change Password</Button> :
                                        <Button style={{ marginTop: "40px" }} className="password-button" type="submit" disabled>Change Password</Button>

                                    }
                                    <span style={{ color: "green" }}>{resetPwdResponseMsg}</span>
                                </Form>



                                {enterPwdScreen && (
                                    <Redirect
                                        to={{
                                            pathname: "/password"
                                        }}
                                    />
                                )}
                            </Card.Body>
                        </Card>
                    </div>
                </div>
            </Container>
            <Footer />

        </div>
    )
}
export default ResetPassword;
