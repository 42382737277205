import axios from 'axios'
import { BASEURL } from '../../config'

export const AddUserRole = (RoleId,userExternalId) => {
    return axios({
      url: `${BASEURL}/v1/user_roles/addUser_Role`,
      method: "POST",
      headers: {
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      data: {
      role_external_id: RoleId,
      db_user_list:[userExternalId]
      }
    })
    .then((response) => response.data.response)
    .catch((err) => err);
  };