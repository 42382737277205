import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";
//comment
import ScrollMemory from 'react-router-scroll-memory';

//Importing the components from the index file in components
import {
  LandingPage,
  // LoginPage,
  // SignUpPage,
  // NexmoOTP,
  // HomePage,
  // OtherUserProfile,
  PageNotFound,
  // AddBalance,
  // TransactionDetails,
  // LeaderBoard,
  // ProfileSetup,
  // Profile,
  // ProfileEdit,
  // EnterPassword,
  // ProfilePassword,
  // ResetPassword,
  // SendLinkPassword,
  // Notification,
  // Charity,
  // Achievement,
  ErrorBoundary,
  // RapydCheckout,
  // PaymentGateway,
  // StripePayment,
  // SharePost,
  Dashboard,
  DashboardHome,
  DashboardTransactions,

  CreateNewPassword,
  CharityLogin,
  CharityResetPasswordLink,
  CharityResetPassword,
  CharityProfile,

} from "./components";

//Importing Other files
import { JWTPrivateRoute, AdminPayRoute, ReportsRoute } from "./PrivateRoutes";
import store from "./store";
import CheckoutForm from "./components/CheckoutForm/CheckoutForm";
import AdminForm from "./components/AdminForm/AdminForm";
import Reports from "./components/ReportsAdmin/ReportsDashboard";
import Users from "./components/Users/Users";

import UserContactForm from "./components/ContactForm/UserContactForm";
import EditUsers from "./components/Users/EditUsers";
// import ContentNavbar from "./components/ContentNavbar/ContentNavbar";

function App() {
  return (
    <Provider store={store}>
      <ErrorBoundary>
        <Router>
          <ScrollMemory />
          <Switch>
            <Route path='/' exact component={LandingPage} />
            <Route path='/login' exact component={CharityLogin} />
            <JWTPrivateRoute path='/dashboard' exact component={Dashboard} />
            {/* <JWTPrivateRoute path='/user' exact component={CreateUser} /> */}
            <AdminPayRoute path='/edit-user' exact component={EditUsers} />
            <JWTPrivateRoute path='/home' exact component={DashboardHome} />
            <JWTPrivateRoute path='/transactions' component={DashboardTransactions} />
            <JWTPrivateRoute path='/profile' component={CharityProfile} />
            <JWTPrivateRoute path='/create-new-password' component={CreateNewPassword} />
            <JWTPrivateRoute path='/charity-resetpassword' component={CharityResetPassword} />
            <JWTPrivateRoute path='/charity-resetpasswordlink' component={CharityResetPasswordLink} />
            <JWTPrivateRoute path='/withdraw' component={CheckoutForm} />
            <AdminPayRoute path='/admin-pay' component={AdminForm} />
            <AdminPayRoute path='/users' component={Users} />
            <ReportsRoute path='/reports' component={Reports} />
            <Route path='/charity-form' component={UserContactForm} />

            <Route component={PageNotFound} />
          </Switch>
        </Router>
      </ErrorBoundary>
    </Provider>
  );
}

export default App;
