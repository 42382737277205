import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { useSelector } from 'react-redux'

const JWTPrivateRoute = ({ component: Component, ...rest }) => { 

    // const JWTtoken = useSelector(state => state.tempPassword.tempToken)
    const LoggedInUserId = useSelector(state => state.newUserToken.db_user_external_id)

    return (
        <Route 
            {...rest}
            render = {props =>  LoggedInUserId ? (<Component {...props} />) : (<Redirect to="/" />) }
        />
    )
}

export default JWTPrivateRoute