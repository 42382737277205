import React, { useState, useEffect } from "react";
import { Button, Form } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { Redirect, useHistory } from "react-router-dom";

//css imports
import "../../assets/scss/Contents/sharingOnMedia.scss";

//image imports
import x from "../../assets/svg/x.svg";
// import horizontalDots from "../../../assets/svg/moreDot-horizontal.svg";
import Loader from "../Loader";
import ToastMessage from "../ToastMessage";
import greenTickMark from "../../assets/svg/greenTickMark.svg";
import { ListAllRoles, updateUserDataAPI } from "../../api/Users/EditUsers";
import { loadCharityNames } from "../../actions/CharityList";

/*Style applied to body element */
const BodyStyle = () => {
  useEffect(() => {
    document.getElementById("body").className = "bodyStyleModal";
    return () => {
      document.getElementById("body").className = " ";
    };
  }, []);
  return <></>;
};
/*end**********************/

const UpdateUserData = ({
  showReportModal,
  handleUpdateUserData,
  userId,
  username,
  email,
  role,
  country_id,
  cause_id
}) => {
  const countriesList = useSelector((state) => state.countriesList.data);
  const charityData = useSelector((state) => state.charityNames.data);
  const [validationMsg, setValidationMsg] = useState("");
  const [validationMsgAssignRole, setValidationMsgAssignRole] = useState("");
  const [Role, setRole] = useState("");
  const [Roles, setRoles] = useState("");
  const [UserName, setUserName] = useState("");
  const [Email, setEmail] = useState("");
  const [showToastMsgUserCreated, setShowToastMsgUserCreated] = useState(false);
  const [charity, setCharity] = useState();
  const [countryName, setCountryName] = useState("");
  const [charityName, setCharityName] = useState("");
  const [showToastMsgRoleAssigned, setShowToastMsgRoleAssigned] = useState(
    false
  );
  const [RoleId, setRoleId] = useState("");
  const [countryID, setCountryID] = useState("");
  const [charityExternalID, setCharityExternalID] = useState();
  // const [countryName, setCountryName] = useState();
  // const [charityName, setCharityName] = useState();
  const history = useHistory();



  const [showModal, setShowModal] = useState(() =>
    showReportModal ? showReportModal : false
  );
  const dispatch = useDispatch();

  useEffect(() => {
    async function fetchData() {
      let allroles = await ListAllRoles();
      setRoles(allroles);
    }
    fetchData();
  }, []);

  useEffect(() => {
    if (countryID) {
      dispatch(loadCharityNames(countryID));
    } 
  }, [countryID]);

  useEffect(() => {
    Object.values(countriesList).map((country) => {
      if (country.country_external_id === country_id) {
        setCountryName(country.country_name);
      }
    });
  }, [country_id]);
  useEffect(()=>{
    charityData.map((cause, index) => {
        if (cause.cause_external_id === cause_id) {
          setCharityName(cause.cause_name);
        }
      });
  },[cause_id])

  const handleCountry = (e) => {
    const name = e ? e.split(",") : "";
    let countryExternalId = name[0];

    if (countryExternalId !== "") {
      setCountryID(countryExternalId);
      Object.values(countriesList).map((country) => {
        if (country.country_external_id === countryExternalId) {
          setCountryName(country.countryName);
          setCharity("");
          setCharityExternalID("");
        }
      });
    } else if (countryExternalId === "") {
      setCountryID("");
      setCountryName("");
      setCharity("");
      setCharityExternalID("");
    }
  };

  const updateUserDataHandler = async (e) => {
    e.preventDefault();
    let res = await updateUserDataAPI(userId, countryID, charityExternalID);
    if (res.statusCode === 200) {
      setShowToastMsgRoleAssigned(true);
      setTimeout(() => {
        setShowToastMsgRoleAssigned(false);
        history.push('/edit-user')
        window.location.reload(true);
        handleUpdateUserData(false);
      }, 2000);
    } else {
      setValidationMsgAssignRole("Something went wrong!Please try again");
    }
  };

  let stylesReport = showModal
    ? { display: "block", overflowY: "scroll" }
    : { display: "none", overflow: "hidden" };
  return (
    <div style={{fontFamily:"DM Sans"}}>
      {/*Report Modal*********************************************** */}
      {showModal && <BodyStyle />}
      {showModal && <div className="modal-backdrop"></div>}

      <div
        id=""
        className="modal in report-modal-container"
        role="dialog"
        style={stylesReport}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Update User Data</h4>

              <button
                className="close"
                onClick={() => {
                  dispatch(loadCharityNames())
                  handleUpdateUserData(false)}
                }
                  
              >
                <img src={x} alt="close-button" />
              </button>
            </div>
            <div className="">
              <div className="modal-body">
                <Form
                  className="profile-edit-form"
                  onSubmit={(e) =>
                    updateUserDataHandler(e, countryID, charityExternalID)
                  }
                >
                  <Form.Group>
                    <Form.Label className="profile-edit-label">
                      User Name
                    </Form.Label>
                    <Form.Control
                      value={username}
                      readOnly
                      type="name"
                      required
                      placeholder="Enter Username"
                    />
                  </Form.Group>

                  <Form.Group>
                    <Form.Label className="profile-edit-label">
                      Email address
                    </Form.Label>
                    <Form.Control
                      value={email}
                      readOnly
                      required
                      type="email"
                      placeholder="Enter email"
                    />
                  </Form.Group>

                  {role && <Form.Group>
                    <Form.Label className="profile-edit-label">Role</Form.Label>
                    <Form.Control
                      value={role}
                      readOnly
                      required
                      type="name"
                      placeholder="Enter email"
                    />
                  </Form.Group>}
                  {country_id && (
                    <Form.Group>
                      <Form.Label className="profile-edit-label">
                        Current Country
                      </Form.Label>
                      <Form.Control
                        value={countryName}
                        readOnly
                        required
                        type="name"
                        placeholder="Country Name"
                      />
                    </Form.Group>
                  )}
                  <>
                    <Form.Label>Country</Form.Label>

                    <Form.Control
                      className="dropdown-btn"
                      as="select"
                      defaultValue={countryName}
                      onChange={(e) => handleCountry(e.target.value)}
                      // value={countryName || ""}
                      required
                    >
                      <option value={""}>Select country</option>
                      {countriesList &&
                        Object.values(countriesList).map(
                          (countryData, index) => (
                            <option
                              value={`${countryData.country_external_id},${countryData.country_name}`}
                              key={countryData.country_external_id}
                            >
                              {countryData.country_name}
                            </option>
                          )
                        )}
                    </Form.Control>
                  </>
                  {cause_id && (
                    <Form.Group style={{marginTop:"1rem"}}>
                      <Form.Label className="profile-edit-label ">
                        Current Charity
                      </Form.Label>
                      <Form.Control
                        value={charityName}
                        readOnly
                        required
                        type="name"
                        placeholder="Charity Name"
                      />
                    </Form.Group>
                  )}
                  <Form.Group style={{ marginTop: "1rem" }}>
                    <Form.Label>Select Charity</Form.Label>
                    <Form.Control
                      required
                      as="select"
                      size="lg"
                      // value={charityExternalID || ""}
                      onChange={(e) => setCharityExternalID(e.target.value)}
                    >
                      <option defaultValue style={{ display: "none" }}>
                        Select Charity
                      </option>
                      {countryID &&
                        charityData &&
                        charityData.map((cause, index) => {
                          return (
                            <option
                              value={cause.cause_external_id}
                              key={cause.cause_external_id}
                            >
                              {cause.cause_name}
                            </option>
                          );
                        })}
                    </Form.Control>
                  </Form.Group>
                  <span style={{ color: "red" }}>
                    {validationMsgAssignRole}
                  </span>
                  <Button
                    style={{ margin: "1rem 0px" }}
                    variant="primary"
                    type="submit"
                    block
                  >
                    Submit
                  </Button>
                </Form>
              </div>
              {showToastMsgRoleAssigned ? (
                <ToastMessage
                  image={greenTickMark}
                  message="Data updated successfully"
                />
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateUserData;
