import axios from 'axios';
import { GET_CHARITY_NAMES } from '../constants/action-types';
import { BASEURL } from '../config';

export const loadCharityNames = (countryID) => (dispatch) => {
  return axios({
    url: `${BASEURL}/v1/adminDashboard/list-causes`,
    method: "POST",
    headers: {
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
      // Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      // userexternalid: `${localStorage.getItem("user_external_id")}`,
    },
    data: {
      country_external_id: countryID
    }
  })
    .then((response) =>
      dispatch({
        type: GET_CHARITY_NAMES,
        payload: response.data.response,
      })
    )
    .catch((err) => err);
};


export const loadAllCharityNames = () => dispatch => {
  return axios({
    url: `${BASEURL}/v1/adminDashboard/list-causes`,
    method: "POST",
    headers: {
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
      // Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      // userexternalid: `${localStorage.getItem("user_external_id")}`,
    },
    
  })
    .then((response) =>
      dispatch({
        type: GET_CHARITY_NAMES,
        payload: response.data.response,
      })
    )
    .catch((err) => err);
};