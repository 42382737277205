import React, { useState, useEffect,useRef, createRef } from "react";

import { useSelector, useDispatch } from "react-redux";
import NavBarDashboard from "../Dashboard/DashboardNavbar";
import {
  Row,
  Col,
  Card,
  Button,
  Form,
  DropdownButton,
  Dropdown,
  Nav,Navbar,NavLink,
  ButtonGroup,
} from "react-bootstrap";

import ReportsDashboardData from './ReportsDashboardData'
import ListOfCharities from './ListOfCharities'
import AmountLoaded from './AmountLoaded'
import GenerateReport from './GenerateReport'


const ReportsDashboard = () => {
  const dashboardRef = createRef();
  const listOfCharitiesRef = createRef();
  const amountLoadedRef = createRef();
  const generatereportRef = createRef();
  const [showReportsDashboardData, setshowReportsDashboardData] = useState(true);
  const [showListOfCharities, setshowListOfCharities] = useState(false);
  const [showAmountLoaded, setshowAmountLoaded] = useState(false);
  const [showGenerateReport, setShowGenerateReport] = useState(false);
  const userType = useSelector(state => state.newUserToken.user_type);

  const handleNavigation = (str) => {
    str === "dashboard"
      ? dashboardRef.current.classList.add("nav-admin-reports-active")
      : dashboardRef.current.classList.remove("nav-admin-reports-active");
    str === "listofcharities"
      ? listOfCharitiesRef.current.classList.add("nav-admin-reports-active")
      : listOfCharitiesRef.current.classList.remove("nav-admin-reports-active");
    str === "amountloaded"
      ? amountLoadedRef.current.classList.add("nav-admin-reports-active")
      : amountLoadedRef.current.classList.remove("nav-admin-reports-active");
    str === "generatereport"
      ? generatereportRef.current.classList.add("nav-admin-reports-active")
      : generatereportRef.current.classList.remove("nav-admin-reports-active");
  };

  const handleNavigationAdmin = (str) => {
    str === "dashboard"
      ? dashboardRef.current.classList.add("nav-admin-reports-active")
      : dashboardRef.current.classList.remove("nav-admin-reports-active");
    str === "listofcharities"
      ? listOfCharitiesRef.current.classList.add("nav-admin-reports-active")
      : listOfCharitiesRef.current.classList.remove("nav-admin-reports-active");
  };

  const handleNav = (str) => {
  if(userType === "admin" || userType === "countryadmin"){
    handleNavigationAdmin(str)
  }
  else if(userType === "superadmin"){
    handleNavigation(str)
  }
    switch (str) {
      case "dashboard":
        setshowReportsDashboardData(true);
        setshowListOfCharities(false);
        setshowAmountLoaded(false);
        setShowGenerateReport(false);
        break;
      case "listofcharities":
        setshowReportsDashboardData(false);
        setshowListOfCharities(true);
        setshowAmountLoaded(false);
        setShowGenerateReport(false)
        break;
      case "amountloaded":
        setshowReportsDashboardData(false);
        setshowListOfCharities(false);
        setshowAmountLoaded(true);
        setShowGenerateReport(false)
        break;
      case "generatereport":
        setshowReportsDashboardData(false);
        setshowListOfCharities(false);
        setshowAmountLoaded(false);
        setShowGenerateReport(true)
        break;  
      default:
        setshowReportsDashboardData(true);
        setshowListOfCharities(false);
        setshowAmountLoaded(false);
        setShowGenerateReport(false)
    }
  };
  return (
    <>
    <NavBarDashboard />
        <div className="admin-dashboard-navbar">
          {/* <div className="admin-dashboard-navbar-contents"> */}
         <span
                  className="dashboard-navbar-elements nav-admin-reports-active"
                    ref={dashboardRef}
                    onClick={() => handleNav("dashboard")}
                  >
                    Dashboard
                  </span>
             
                  <span
                  className="dashboard-navbar-elements"
                    ref={listOfCharitiesRef}
                    onClick={() => handleNav("listofcharities")}
                  >
                    List Of Charities
                  </span>
                
               {  userType === "superadmin" &&
                  <span
                  className="dashboard-navbar-elements"
                    ref={amountLoadedRef}
                    onClick={() => handleNav("amountloaded")}
                  >
                    Amount Loaded 
                  </span>
              }
              {  userType === "superadmin" &&
                  <span
                  className="dashboard-navbar-elements"
                    ref={generatereportRef}
                    onClick={() => handleNav("generatereport")}
                  >
                    Generate Report
                  </span>
              }
         </div>
         {/* </div> */}
         <div>
              {showReportsDashboardData && <ReportsDashboardData />}
              {showListOfCharities && <ListOfCharities/>}
              { userType === "superadmin" && showAmountLoaded && <AmountLoaded />} 
              { userType === "superadmin" && showGenerateReport && <GenerateReport />} 
          </div>
    </>
  );


};

export default ReportsDashboard;
