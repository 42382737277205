
import axios from 'axios'
import { BASEURL } from '../../config'

 export const DefaultUsers = () => {
    return axios({
      url: `${BASEURL}/v1/adminDashboard/default-users-cause-count`,
      method: "GET",
      headers: {
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
      }
    })
      .then((response) => response.data.response.data)
      .catch((err) => console.error(err));
  };