import React, { useState } from 'react';
import { Container, Row, Col, Form, Spinner } from 'react-bootstrap';
// import LoginNavbar from '../LandingLearnMore/LoginNavbar';
import axios from "axios";
import { BASEURL } from "../../config";
import LoginNavbar from '../CharityNavbar/LoginNavbar';
import countriesList from '../../assets/CountryCode/Countries.json';
import './userContactForm.scss';
import Footer from '../CharityFooter/Footer';

const UserContactForm = () => {

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [countryID, setCountryID] = useState("");
    const [query, setQuery] = useState("");

    const [isLoading, setIsLoading] = useState(false);
    const [showToastMsg, setShowToastMsg] = useState(false);

    // Handlers
    const handleName = (e) => {
        setName(e.target.value);
    };

    const handleEmail = (e) => {
        setEmail(e.target.value);
    };

    const handleCountry = (e) => {
        setCountryID(e.target.value);
    };

    const handleQuery = (e) => {
        setQuery(e.target.value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        setIsLoading(true);
        // console.log(`${name}, ${email}, ${countryID}, ${query}`)
        axios({
            url: `https://api.gopoolit.com/api/v1/support`,
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
            data: {
                Name: name,
                Email_Address: email,
                Country: countryID,
                Message: query,
            },
        })
            .then((res) => {
                setIsLoading(false);
                if (res.status === 200) {
                    setShowToastMsg(true);
                    setIsLoading(false);
                    setTimeout(() => {
                        setShowToastMsg(false);
                    }, 5000);
                }
            })
            .catch((err) => console.log(err));
    };
    return (
        <section className="user-contact-wrapper">
            <LoginNavbar />
            <Container>
                <Row>
                    <Col md={7} xs={11} className="contact-form mx-auto">
                        <div
                            style={{
                                backgroundColor: "#fff",
                                padding: "1rem 2rem",
                                borderRadius: "15px",
                            }}
                        >
                            <Form onSubmit={handleSubmit}>
                                <div>
                                    <h4 style={{ textAlign: "center" }}>Contact us now</h4>

                                    <div style={{ textAlign: "left" }}>
                                        <Form.Group className="section">
                                            <Row>
                                                <Col md={6}>
                                                    <Form.Label>
                                                        Full name<sup>*</sup>
                                                    </Form.Label>
                                                    <Form.Control
                                                        placeholder="Full name"
                                                        value={name}
                                                        onChange={(e) => handleName(e)}
                                                        required
                                                    />
                                                </Col>

                                                <Col md={6} className="email-input">
                                                    <Form.Label>
                                                        Email address<sup>*</sup>
                                                    </Form.Label>
                                                    <Form.Control
                                                        required
                                                        type="email"
                                                        placeholder="Email"
                                                        value={email}
                                                        onChange={(e) => handleEmail(e)}
                                                    />
                                                </Col>
                                            </Row>
                                        </Form.Group>

                                        <Form.Group className="section">
                                            <Row>
                                                <Col>
                                                    <Form.Label>
                                                        Country<sup>*</sup>
                                                    </Form.Label>
                                                    <Form.Control
                                                        // disabled={causeName === null ? true : false}
                                                        className="dropdown-btn"
                                                        as="select"
                                                        onChange={(e) => handleCountry(e)}
                                                        value={countryID}
                                                        required
                                                    >
                                                        <option value="" disabled>
                                                            Select country
                              </option>
                                                        {countriesList.map((countryData, index) => {
                                                            return (
                                                                <option
                                                                >
                                                                    {countryData.name}
                                                                </option>
                                                            );
                                                        })}
                                                    </Form.Control>
                                                </Col>
                                            </Row>
                                        </Form.Group>

                                        <Form.Group className="section">
                                            <Row>
                                                <Col>
                                                    <Form.Label>
                                                        How can we help you?<sup>*</sup>
                                                    </Form.Label>
                                                    <Form.Control
                                                        as="textarea"
                                                        onChange={(e) => handleQuery(e)}
                                                        placeholder="Type your message here..."
                                                        rows={6}
                                                        value={query}
                                                        required
                                                    />
                                                </Col>
                                            </Row>
                                        </Form.Group>

                                        {isLoading ? (
                                            <button
                                                className="btn btn-secondary contact-btn"
                                                type="submit"
                                                disabled
                                            >
                                                Send{" "}
                                                <Spinner animation="border" className="spinner" />
                                            </button>
                                        ) : (
                                                <button
                                                    className="btn btn-secondary contact-btn"
                                                    type="submit"
                                                >
                                                    Send
                                                </button>
                                            )}
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </Col>
                </Row>

            </Container>
            <Footer />
        </section>
    )
}

export default UserContactForm
