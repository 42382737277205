import axios from 'axios';
import { BASEURL } from '../config';
import axiosCreateContent from "../axios/createContentAxios";

export const walletApi = (userexternalid,causeID) => {
    // let causeID = "b3381375-273b-40c8-b07c-7c4ee1abcd56"
    // if (causeID) {
        return axios({
            url: `${BASEURL}/v1/charities/wallet`,
            //   url: `https://dashboard-api.gopoolit.me/api/v1/charities/wallet/b4c612ee-87e2-4603-876b-6b941c670483`,
            method: "POST",
            //   headers: {
            //     Accept: "application/json",
            //     "Access-Control-Allow-Origin": "*",
            //   },
            // data: {
            //     cause_info_external_id: causeID
            // }
        })
            .then((response) => response.data.response.data)
            .catch((err) => console.error(err));
    // } 
    // else {
    //     return axios({
    //         url: `${BASEURL}/v1/charities/wallet`,
    //         //   url: `https://dashboard-api.gopoolit.me/api/v1/charities/wallet/b4c612ee-87e2-4603-876b-6b941c670483`,
    //         method: "POST",
    //         //   headers: {
    //         //     Accept: "application/json",
    //         //     "Access-Control-Allow-Origin": "*",
    //         //   },
    //     })
    //         .then((response) => response.data.response.data)
    //         .catch((err) => console.error(err));
    // }


};

export const payoutApi = (amount,currencyCode) => {
    return axiosCreateContent({
        url: `/v1/charities/payout`,
        //    return axios({
        //         url: `https://dashboard-api.gopoolit.me/api/v1/charities/payout`,
        method: "POST",
        headers: {
            Accept: "application/json",
            "Access-Control-Allow-Origin": "*",
            // 'Authorization': `Bearer ${tokenDetails.token}`,
            // 'userexternalid': tokenDetails.user_external_id,
        },
        data: {
            amount: amount,
            // currency: currencyCode,
            // stripeAccount:"acct_1HtXQVR5sGPCd1DN"
        },
        // withCredentials: true,
    })
        .then((res) => {
            return res
        })
        .catch((err) => {
            // setOpen(true);
            console.log(err);
            return err
        });
};


export const transferToCharity = (amount, charityId, currencyCode) => {
    return axiosCreateContent({
        url: `/v1/charities/transfer`,
        // return axios({
        //     url: `https://dashboard-api.gopoolit.me/api/v1/charities/transfer`,
        method: "POST",
        headers: {
            Accept: "application/json",
            "Access-Control-Allow-Origin": "*",
            // 'Authorization': `Bearer ${tokenDetails.token}`,
            // 'userexternalid': tokenDetails.user_external_id,
        },
        data: {
            amount: amount,
            cause_external_id:charityId
            // currency: currencyCode,
            // destination:"acct_1HtXQVR5sGPCd1DN", 
            // transfer_group:"ORDER_95" 
        },
        // withCredentials: true,
    })
        .then((res) => {
            return res
        })
        .catch((err) => {
            // setOpen(true);
            console.log(err);
            return err
        });
};