import React,{useState,useEffect} from 'react';
import {Form, Button,Container, Row,Col,Card, Dropdown, InputGroup} from 'react-bootstrap'
import { useSelector, useDispatch } from "react-redux";
import { loadCharityNames } from "../../actions/CharityList";
import {CreateUsers } from '../../api/Users/CreateUser'
import {AddUserRole } from '../../api/Users/AddUserRole'
import "../../assets/scss/DashboardUsers/Users.scss"
import ToastMessage from "../ToastMessage";
import greenTickMark from "../../assets/svg/greenTickMark.svg";
import {ListAllRoles} from '../../api/Users/EditUsers'
import arrowLeft from "../../assets/svg/arrow-left.svg";

const CreateUser = () => {
    const [Role,setRole] = useState("")
    const [Roles,setRoles] = useState("")
    const countriesList = useSelector(state => state.countriesList.data);
    const [countryID, setCountryID] = useState("");
    const [charityExternalID, setCharityExternalID] = useState();
    const [charity, setCharity] = useState();
    const [countryName, setCountryName] = useState("");
    const charityData = useSelector((state) => state.charityNames.data);
    const [UserName, setUserName] = useState("");
    const [Password, setPassword] = useState("");
    const [Email, setEmail] = useState("");
    const [validationMsg, setValidationMsg] = useState("");
    const [validationMsgAssignRole, setValidationMsgAssignRole] = useState("");
    const [showToastMsgUserCreated, setShowToastMsgUserCreated] = useState(false);
    const [showToastMsgRoleAssigned, setShowToastMsgRoleAssigned] = useState(false);
    const [error, setError] = useState(false);
    const [showCurrentPasswordIcon, setShowCurrentPasswordIcon] = useState(false);
    const [showPasswordIcon, setPasswordIcon] = useState(false);
    const [userCreated,setUserCreated] = useState(false);
    const [RoleId,setRoleId] = useState("");
    const [userExternalId,setUserExternalId] = useState("");
  


    const dispatch = useDispatch();
    const showPassword = (
      <svg
        width="19"
        height="14"
        viewBox="0 0 19 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1 7C1 7 4 1 9.25 1C14.5 1 17.5 7 17.5 7C17.5 7 14.5 13 9.25 13C4 13 1 7 1 7Z"
          stroke="#757575"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M9.25 9.25C10.4926 9.25 11.5 8.24264 11.5 7C11.5 5.75736 10.4926 4.75 9.25 4.75C8.00736 4.75 7 5.75736 7 7C7 8.24264 8.00736 9.25 9.25 9.25Z"
          stroke="#757575"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    );
  
    const hidePassword = (
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10.5418 10.5417C10.3421 10.756 10.1012 10.928 9.83357 11.0472C9.56593 11.1665 9.27702 11.2306 8.98407 11.2357C8.69111 11.2409 8.40012 11.187 8.12844 11.0773C7.85677 10.9676 7.60998 10.8042 7.4028 10.597C7.19562 10.3899 7.03229 10.1431 6.92255 9.8714C6.81282 9.59972 6.75893 9.30873 6.7641 9.01577C6.76927 8.72282 6.83339 8.43391 6.95264 8.16628C7.07189 7.89864 7.24382 7.65777 7.45818 7.45802M13.32 13.3198C12.0768 14.2675 10.563 14.7925 9 14.818C3.90909 14.818 1 8.99984 1 8.99984C1.90465 7.31395 3.15937 5.84101 4.68 4.67984L13.32 13.3198ZM7.47273 3.3562C7.97333 3.23903 8.48586 3.18045 9 3.18166C14.0909 3.18166 17 8.99984 17 8.99984C16.5585 9.82574 16.032 10.6033 15.4291 11.3198L7.47273 3.3562Z"
          stroke="#757575"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M1 1L17 17"
          stroke="#757575"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    );

 useEffect(() => {
    async function fetchData() {
      let allroles = await ListAllRoles()
      setRoles(allroles)
    }
    fetchData();
  }, []);

    useEffect(() => {
        if (countryID) {
          dispatch(loadCharityNames(countryID));
        }
      }, [countryID]);

     //function to dispatch profile obj based on externalId
  const handleSubmit = async (
    e,
    UserName,
    Email,
    Password,
    Role,
    countryID,
    charityExternalID
  ) => {
    e.preventDefault();
    let isAnAdmin = true;
    if(charityExternalID){
      isAnAdmin = false
    }
    const obj = {
      username: UserName,
      user_email_address: Email,
      user_password:Password,
      isAdmin:isAnAdmin,
      userType:Role,
      cause_external_id: charityExternalID,
      country_external_id: countryID,
    };
    // console.log(obj)
    // setProfilePicture(profilePicture);
    
    let res = await CreateUsers(obj);
    // console.log(res.response.data.response.statusCode)
    if (res.statusCode === 200) {
      setUserExternalId(res.data[0].db_user_external_id)
      setShowToastMsgUserCreated(true);
      setTimeout(() => {
        setShowToastMsgUserCreated(false);
      }, 3000);
      setUserName("")
      setEmail("");
      setPassword("");
      setRole("");
      setCountryID("");
      setCharityExternalID("");
      setUserCreated(true)//test
    } else {
      setValidationMsg(
        "Please Enter Valid Credentials"
      );
    } 
  }

  const handleRoleAssign = async (
    e,
    Role,
    countryID,
    charityExternalID
  ) => {
    e.preventDefault();
    let res = await AddUserRole(RoleId,userExternalId);
    // console.log(res.response.data.response.statusCode)
    //
    if (res.statusCode === 200) {
      setShowToastMsgRoleAssigned(true);
      setTimeout(() => {
        setShowToastMsgRoleAssigned(false);
      }, 3000);
      setRole("");
      // setUserCreated(false)//stay in same page
    } else {
      setValidationMsgAssignRole("Something went wrong!Please try again")
      // alert("Something went wrong!Please try again")
    } 
  }
    const handleCountry = (e) => {
        const name = e ? e.split(",") : "";
        let countryExternalId = name[0];

        if (countryExternalId !== "") {
            setCountryID(countryExternalId);
            Object.values(countriesList).map((country) => {
                if (country.country_external_id === countryExternalId) {
                    setCountryName(country.country_name);
                    setCharity("");
                    setCharityExternalID("");
                }
            });
        } else if (countryExternalId === "") {
            setCountryID("");
            setCountryName("");
            setCharity("");
            setCharityExternalID("");
        }
    };

    
    const handleCurrentPassword = () => {
      var x = document.getElementById("userpassword");
      if (x.type === "password") {
        x.type = "text";
        setShowCurrentPasswordIcon(true);
      } else {
        x.type = "password";
        setPasswordIcon(false);
        setShowCurrentPasswordIcon(false);
      }
    };

    return (
   
    <>
    {!userCreated ?
    <Container className="profile-edit-card">
    <Row style={{justifyContent:"center",padding:"1rem",margin:"1rem"}}> 
    <Col lg={8}>
    <Card className="card-container" lg={6}>
    <Card.Header className="heading">Enter User Credentials</Card.Header>
    <Card.Body style={{padding:"1rem 5rem"}}>
      <Form className="profile-edit-form"  onSubmit={(e) =>
                          handleSubmit(
                            e,
                            UserName,
                            Email,
                            Password,
                            Role,
                            countryID,
                            charityExternalID
                          )
                        }>
        <Form.Group>
          <Form.Label className="profile-edit-label">User Name</Form.Label>
          <Form.Control onChange={(e) => setUserName(e.target.value)} value={UserName} type="name" required placeholder="Enter Username" />
        </Form.Group>

        <Form.Group >
          <Form.Label className="profile-edit-label">Email address</Form.Label>
          <Form.Control onChange={(e) => setEmail(e.target.value)} value={Email} required type="email" placeholder="Enter Email" />
        </Form.Group>

        <Form.Group >
          <Form.Label className="profile-edit-label">Password</Form.Label>
          <InputGroup className="mb-3">
                      <Form.Control
                        size="lg"
                        type="password"
                        id="userpassword"
                        placeholder="**********"
                        required
                        onChange={(e) => setPassword(e.target.value)}
                        value={Password}
                      />
                      <InputGroup.Append>
                        <InputGroup.Text id="basic-addon1">
                          {showCurrentPasswordIcon ? (
                            <span onClick={handleCurrentPassword}>
                              {showPassword}
                            </span>
                          ) : (
                            <span onClick={handleCurrentPassword}>
                              {hidePassword}
                            </span>
                          )}
                        </InputGroup.Text>
                      </InputGroup.Append>
                    </InputGroup>
         </Form.Group>
        <Form.Group >
            <Form.Label className="profile-edit-label">Select Role</Form.Label>
            <Form.Control required as="select"
            onChange={(e) => setRole(e.target.value)}
            >
            <option value="">Select Role</option>
            {/* <option value={"admin"}>Admin</option>
            <option value={"superadmin"}>Super Admin</option>
            <option value={"contentmoderator"}>Content Moderator</option>
            <option value={"countryadmin"}>Country Admin</option>
            <option value={"charity"}>Charity</option> */}
            { Roles && Roles.map(
                      (role, index) => (
                        <option
                          value={`${role.type}`}
                          key={role.role_external_id}
                        >
                          {role.type}
                        </option>
                      )
                    )}
            </Form.Control>
        </Form.Group>
        {(Role === "countryadmin" || Role === "charity") &&
        <>
        <Form.Label>
        Country
        </Form.Label>
        
        <Form.Control
        className="dropdown-btn"
        as="select"
        onChange={(e) => handleCountry(e.target.value)}
        // value={countryName || ""}

        required
        >
        <option value="">Select country</option>
        { countriesList && Object.values(countriesList).map(
                      (countryData, index) => (
                        <option
                          value={`${countryData.country_external_id},${countryData.country_name}`}
                          key={countryData.country_external_id}
                        >
                          {countryData.country_name}
                        </option>
                      )
                    )}
        </Form.Control>
        </>
    }
    {Role === "charity" &&
    <Form.Group style={{marginTop:"1rem"}} controlId="exampleForm.ControlSelect1">
                          <Form.Label>
                            Select Charity
                          </Form.Label>
                          <Form.Control
                            required
                            as="select"
                            size="lg"
                            // value={charityExternalID || ""}
                            onChange={(e) =>
                              setCharityExternalID(e.target.value)
                            }
                          >
                            <option defaultValue style={{ display: "none" }}>
                              Select Charity
                            </option>
                            {countryID && charityData && charityData.map((cause, index) => {
                                return (
                                  <option
                                    value={cause.cause_external_id}
                                    key={cause.cause_external_id}
                                  >
                                    {cause.cause_name}
                                  </option>
                                );
                            })}
                          </Form.Control>
        </Form.Group>
    }
      <span style={{ color: "red" }}>
        {validationMsg}
        </span>
        <Button style={{margin:"1rem 0px"}} variant="primary" type="submit" block>
          Submit
        </Button>
      </Form>
      </Card.Body>
      </Card>
      </Col>
      </Row>
    </Container>
    
    : 
    
    
    <Container style={{minHeight:"600px"}} className="profile-edit-card">
    <Row style={{justifyContent:"center",padding:"1rem",margin:"1rem"}}> 
    <Col lg={8}>
    <Button variant="link" onClick={()=>setUserCreated(false)} style={{ textDecoration: "none" }}>
                <img src={arrowLeft} alt="arrow-left" /> Back
    </Button>
    <Card className="card-container" lg={6}>
    <Card.Header className="heading">Please Assign a Role to {UserName}</Card.Header>
    <Card.Body style={{padding:"1rem 5rem"}}>
      <Form className="profile-edit-form"  onSubmit={(e) =>
                          handleRoleAssign(
                            e,
                            Role,
                            countryID,
                            charityExternalID
                          )
                        }>

        <Form.Group controlId="exampleForm.ControlSelect1">
            <Form.Label className="profile-edit-label">Assign Role</Form.Label>
            <Form.Control required as="select"
            onChange={(e) => setRoleId(e.target.value)}
            >
            <option value="">Select Role</option>
            {/* <option value={"admin"}>Admin</option>
            <option value={"superadmin"}>Super Admin</option>
            <option value={"contentmoderator"}>Content Moderator</option>
            <option value={"countryadmin"}>Country Admin</option>
            <option value={"charity"}>Charity</option> */}

            { Roles && Roles.map(
                      (role, index) => (
                        <option
                          value={`${role.role_external_id}`}
                          key={role.role_external_id}
                        >
                          {role.type}
                        </option>
                      )
                    )}
            </Form.Control>
        </Form.Group>
        {/* {(Role === "countryadmin" || Role === "charity") &&
        <>
        <Form.Label>
        Country
        </Form.Label>
        
        <Form.Control
        className="dropdown-btn"
        as="select"
        onChange={(e) => handleCountry(e.target.value)}
        // value={countryName || ""}

        required
        >
        <option value="">Select country</option>
        { countriesList && Object.values(countriesList).map(
                      (countryData, index) => (
                        <option
                          value={`${countryData.country_external_id},${countryData.country_name}`}
                          key={countryData.country_external_id}
                        >
                          {countryData.country_name}
                        </option>
                      )
                    )}
        </Form.Control>
        </>
    }
    {Role === "charity" &&
    <Form.Group style={{marginTop:"1rem"}} controlId="exampleForm.ControlSelect1">
                          <Form.Label>
                            Select Charity
                          </Form.Label>
                          <Form.Control
                            required
                            as="select"
                            size="lg"
                            // value={charityExternalID || ""}
                            onChange={(e) =>
                              setCharityExternalID(e.target.value)
                            }
                          >
                            <option defaultValue style={{ display: "none" }}>
                              Select Charity
                            </option>
                            {countryID && charityData.map((cause, index) => {
                                console.log(cause.country_name,countryID)
                                return (
                                  <option
                                    value={cause.cause_external_id}
                                    key={cause.cause_external_id}
                                  >
                                    {cause.cause_name}
                                  </option>
                                );
                            })}
                          </Form.Control>
        </Form.Group>
    } */}
      <span style={{ color: "red" }}>
        {validationMsgAssignRole}
        </span>
        <Button style={{margin:"1rem 0px"}} variant="primary" type="submit" block>
          Submit
        </Button>
      </Form>
      </Card.Body>
      </Card>
      </Col>
      </Row>
    </Container>
    
    
    }
    {showToastMsgUserCreated ? (
      <ToastMessage
        image={greenTickMark}
        message="Created User Successfully"
      />
    ) : null}
    {showToastMsgRoleAssigned ? (
      <ToastMessage
        image={greenTickMark}
        message="Assigned Role Successfully"
      />
    ) : null}
    </> 
    );

};

export default CreateUser;