import axios from 'axios';
import { BASEURL } from '../../config';
import { REPORTED_POST } from '../../constants/action-types';

export const reportTypePostApi = (token, external_Id) => {
    return axios({
        url: `${BASEURL}/v1/reportType`,
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Authorization': `Bearer ${token}`,
            'userexternalid': external_Id,
        },
        withCredentials: true,
    }).then(res => res.data)
        .catch(err => console.log(err))
}

export const reportPostApi = (content_id) => dispatch => {
    return axios({
        url: `${BASEURL}/v1/charityDashboard/content/report-content`,
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Access-Control-Allow-Origin': '*',
            // 'Authorization': `Bearer ${token}`,
            // 'userexternalid': external_Id,
        },
        data: {
            "content_external_id": `${content_id}`,
        },
        withCredentials: true,
    })
        .then(response => {
            dispatch({
                type: REPORTED_POST,
                payload: content_id
            })
            return response
        }).catch(err => console.log(err))
}
export const reportPostApiAdmin = (content_id) => dispatch => {
    return axios({
        url: `${BASEURL}/v1/adminDashboard/charity/report-content`,
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Access-Control-Allow-Origin': '*',
            // 'Authorization': `Bearer ${token}`,
            // 'userexternalid': external_Id,
        },
        data: {
            "content_external_id": `${content_id}`,
        },
        withCredentials: true,
    })
        .then(response => {
            dispatch({
                type: REPORTED_POST,
                payload: content_id
            })
            return response
        }).catch(err => console.log(err))
}