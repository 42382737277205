//USER AUTHORIZED
export const USER_AUTHORIZED = 'USER_AUTHORIZED'

//Country List
export const All_COUNTRY_LIST = "All_COUNTRY_LIST"
export const STRIPE_COUNTRY_LIST = "STRIPE_COUNTRY_LIST"

// CONTENT

export const USER_CONTENT_DATA = 'USER_CONTENT_DATA'
export const GENERATE_NEW_TOKEN = 'GENERATE_NEW_TOKEN'

//TEMPORARY_PASSWORD
export const TEMP_PASSWORD_DATA = 'TEMP_PASSWORD_DATA'

//CREATE POST
export const CREATE_POST = 'CREATE_POST'

//STORING USERS DETAILS WHO HAVE LOGGED IN
export const GET_TOKEN_DETAILS = 'GET_TOKEN_DETAILS'
export const GET_USER_COUNTRY_DETAILS = 'GET_USER_COUNTRY_DETAILS'

//PROFILE DETAILS BASED ON EXTERNAL_ID
export const GET_PROFILE_DETAILS_BY_ID = 'GET_PROFILE_DETAILS_BY_ID'
export const UPDATE_USER_DETAILS_BY_ID = 'UPDATE_USER_DETAILS_BY_ID'
export const USER_PROFILE_DETAILS = 'USER_PROFILE_DETAILS'

// GETTING THE CHARITY NAMES
export const GET_CHARITY_NAMES = 'GET_CHARITY_NAMES'
export const AMOUNT_DETAILS = 'AMOUNT_DETAILS'
export const AMOUNT_DETAILS_IN_RANGE = 'AMOUNT_DETAILS_IN_RANGE'
export const LOG_OUT = 'LOG_OUT'
export const STORE_USER_DATA = 'STORE_USER_DATA'
export const UPDATE_PASSWORD = 'UPDATE_PASSWORD'
export const UPDATE_PROFILE_PICTUTRE = 'UPDATE_PROFILE_PICTUTRE'
export const USER_PROFILE_DATA = 'USER_PROFILE_DATA'
export const VERIFY_PASSWORD = 'VERIFY_PASSWORD'
export const REPORTED_POST = 'REPORTED_POST'

//GETTING ADMIN 
export const GET_CHARITY_ID = 'GET_CHARITY_ID';
export const GET_ADMIN_CHARITY_INFO = 'GET_ADMIN_CHARITY_INFO';
