import React from "react";

import { withRouter } from "react-router-dom";

//image imports
import whiteBrandLogo from "../../assets/svg/white-brandLogo.svg";

import blueBrandLogo from "../../assets/svg/blue-brandLogo.svg";

//css imports
import { Navbar, Nav } from "react-bootstrap";
import "../../assets/scss/LandingLearnMore/loginNavbar.scss";

const LoginUserButton = () => {
  return (
    <Navbar.Collapse className="justify-content-end">
      <Nav>
        <Nav.Link
          href="/charity-login"
          id="learn-more-page-login-button"

        >
          Login
        </Nav.Link>
        <Nav.Link
          href="/signup"
          id="learn-more-page-signup-button"

        >
          Sign Up
        </Nav.Link>
      </Nav>
    </Navbar.Collapse>
  );
};

const LoginNavbar = (props) => {
  const path = props.location.pathname; //gives current path

  return (
    <section className="learn-more-nav-wrapper">
      <Navbar className="learn-more-nav">
        <Navbar.Brand href="#home" className="brand-logo">
          {path === "/" ? < img src={whiteBrandLogo} alr="brand-logo" /> : <img src={blueBrandLogo} alr="brand-logo" />}
        </Navbar.Brand>

        {/*the search bar is visible for learn more page  */}
        {/* {path !== "/profile-setup" && <LoginUserButton />} */}
        {path !== "/profile-setup" && path !== "/profile-password" && <LoginUserButton />}
      </Navbar>
    </section>
  );
};

export default withRouter(LoginNavbar);
