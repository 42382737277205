import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import NavBar from "./DashboardNavbar";
import Footer from "../CharityFooter/Footer";
import {
  Table,
  Container,
  Row,
  Col,
  Card,
  Button,
  Form,
  DropdownButton,
  Dropdown,
  ButtonGroup,
} from "react-bootstrap";
// import "react-date-picker/dist/react-date-picker.css";
import { getLiveDataInRange, getLiveData, adminGetLiveData, adminGetLiveDataInRange,AllAdminStats,AllAdminStatsInRange } from "../../api/LiveData"
import SelectDatepicker from 'react-select-datepicker';
import CountUp from 'react-countup';
import '../../assets/scss/Dashboard/ActivityPage.scss';
import '../../assets/scss/Dashboard/DropdownStyles.scss';

// import css
import "../../assets/scss/Dashboard/Dashboard.scss";


//import svgs
import postsImage from "../../assets/svg/postsTagged.svg";
import goldenHeart from "../../assets/svg/GoldenHeart.svg";
import dropdownButtonArrow from '../../assets/svg/dropdownButtonArrow.svg';

import avatar from "../../assets/svg/userIcon.svg";

import calenderImg from "../../assets/svg/calenderIcon.svg";

import { storeCountriesList } from "../../actions/countryList"
import { loadCharityNames } from "../../actions/CharityList"
import { getCharityID } from '../../actions/storeCharityID';
import { adminCharityInfo } from "../../actions/AdminCharityInfo";

const Dashboard = (props) => {
  const [countryID, setCountryID] = useState("");
  const charityID_Value = useSelector(state => state.CharityIDReducer.charityID);

  const [charityID, setCharityID] = useState(() => charityID_Value);
  const charityNames = useSelector((state) => state.charityNames.data);
  const userType = useSelector(state => state.newUserToken.user_type);
  const countriesList = useSelector(state => state.countriesList.data);
  const countryAdminCountryId = useSelector(state => state.newUserToken.country_external_id);
  const countryAdminCountryName = useSelector(state => state.newUserToken.country_name);
  
  const [showDatePicker, setShowDatePicker] = useState(true);
  const [fromDate, setFromDate] = useState();
  const [showFromDate, setShowFromDate] = useState(false);
  const [showToDate, setShowToDate] = useState();
  const [toDate, setToDate] = useState(new Date());
  const [liveDataInRange, setLiveDataInRange] = useState({});
  const [loading, setLoading] = useState(false)
  const [value, setValue] = useState(null);
  const charityDetails = useSelector(state => state.newUserToken.cause_info);


  const liveData = useSelector((state) => state.dashboardDataReducer);

  const dispatch = useDispatch(); //Used to dispatch the actions from the component
  // const liveDataInRange = useSelector((state) => state.dashboardDataReducerInRange);

  const adminCharityImage = useSelector(state => state.AdminCharityInfoIDReducer.cause_image)
  const adminCharityName = useSelector(state => state.AdminCharityInfoIDReducer.cause_name)
  const adminCharityCountry = useSelector(state => state.AdminCharityInfoIDReducer.country_name)
  const [countryName, setCountryName] = useState(()=> adminCharityCountry ? adminCharityCountry : "");
  const [charity, setCharity] = useState(()=> adminCharityName ? adminCharityName : "");

  useEffect(() => {
    if (userType === "superadmin" || userType === "admin" || userType === "countryadmin") {
      if(charityID){
        dispatch(adminCharityInfo(charityID))
        dispatch(adminGetLiveData(charityID))
      }else if(!charityID){
          dispatch(AllAdminStats())
      }
    } 
  }, [charityID])
 useEffect(()=>{
    if(countryAdminCountryName){
      setCountryName(countryAdminCountryName)
      dispatch(loadCharityNames(countryAdminCountryId))
    }
  },[])
 
  
  

  useEffect(()=>{
    let filterFromDate = new Date();
    filterFromDate.setHours(0);
    filterFromDate.setMinutes(0);
    filterFromDate.setSeconds(0);
    setFromDate(new Date(filterFromDate))
  },[])

  useEffect(()=>{
    async function fetchData() {
      if (userType === "charity") {
        if (fromDate && toDate) {
          let offsetTimeFromDate = new Date(fromDate.getTime() - fromDate.getTimezoneOffset() * 60 * 1000);
          let offsetTimeToDate = new Date(toDate.getTime() - toDate.getTimezoneOffset() * 60 * 1000);
          liveValues = await getLiveDataInRange(offsetTimeFromDate.toISOString(), offsetTimeToDate.toISOString())
          setLiveDataInRange(liveValues)
        }
      } else if (userType === "superadmin" || userType === "admin" || userType === "countryadmin") {
        if (fromDate && toDate && charityID) {
          let offsetTimeFromDate = new Date(fromDate.getTime() - fromDate.getTimezoneOffset() * 60 * 1000);
          let offsetTimeToDate = new Date(toDate.getTime() - toDate.getTimezoneOffset() * 60 * 1000);
          liveValues = await adminGetLiveDataInRange(offsetTimeFromDate.toISOString(), offsetTimeToDate.toISOString(), charityID)
          setLiveDataInRange(liveValues)
        }else if(fromDate && toDate && !charityID){
          let offsetTimeFromDate = new Date(fromDate.getTime() - fromDate.getTimezoneOffset() * 60 * 1000);
          let offsetTimeToDate = new Date(toDate.getTime() - toDate.getTimezoneOffset() * 60 * 1000);
          liveValues = await AllAdminStatsInRange(offsetTimeFromDate.toISOString(), offsetTimeToDate.toISOString())
          setLiveDataInRange(liveValues)
        }
      } 
    }
    fetchData();
  },[fromDate,toDate])

  useEffect(()=>{
   handleRange()
  },[charityID])

  useEffect(() => {
    if (userType === "charity") {
      dispatch(getLiveData())
    }
  }, [])

  useEffect(() => {
    if (countryID) {
      dispatch(loadCharityNames(countryID))
    }
  }, [countryID])


  const handleCountry = (e) => {
    const name = e ? e.split(",") : "";
    let countryExternalId = name[0];

    if (countryExternalId !== "") {
      setCountryID(countryExternalId);
      Object.values(countriesList).map((country) => {
        if (country.country_external_id === countryExternalId) {
          setCountryName(country.country_name);
          setCharity("");
          setCharityID("");
        }
      });
    } else if (countryExternalId === "") {
      setCountryID("");
      setCountryName("");
      setCharity("");
      setCharityID("");
    }
  };

  const text_truncate = (str, length, ending) => {
    if (length == null) {
      length = 100;
    }
    if (ending == null) {
      ending = "...";
    }
    if (str.length > length) {
      return str.substring(0, length - ending.length) + ending;
    } else {
      return str;
    }
  };

  const handleCharity = (e) => {
    if (e !== null) {
      const charity = e;
      const name = charity.split(",");
      setCharityID(name[0]);
      setCharity(name[1]);
      dispatch(getCharityID(name[0]));
    } else {
      setCharity("");
      setCharityID("");
    }
  };

  const onFromDateChange = useCallback((date) => {
    setFromDate(date);
  }, []);

  const onToDateChange = useCallback((date) => {
    if(date && (date.toString().substring(0,15) === new Date().toString().substring(0,15))){
      setToDate(new Date());
    }
    else {
    setToDate(date);
    }
  }, []);


  //   total_fund_collected: "14.08"
  // total_fund_collected_currency: "GBP"
  // total_fund_collected_currency_sign: "p"
  // total_gloden_buzzers: "4"
  // total_posts_tagged: "55"

  const {
    total_fund_collected: fundsCollected,
    total_fund_collected_currency_sign: currencySign,
    total_gloden_buzzers: totalGBs,
    total_posts_tagged: totalPosts
  } = liveData

  const handleShowFromDate = () => {
    setShowFromDate(!showFromDate)
    setShowToDate(false)
  }
  const handleShowToDate = () => {
    setShowToDate(!showToDate)
    setShowFromDate(false)
  }


  const handleRefresh = () => {
    setLoading(true)
    if (userType === "charity") {
      dispatch(getLiveData())
    }
    else if (userType === "superadmin" || userType === "admin") {
      if(!charityID){
        dispatch(AllAdminStats())
      }else{
        dispatch(adminGetLiveData(charityID))
      }
    }
    setTimeout(() => {
      setLoading(false)
    }, 500)
  }

  var liveValues = {}
  const handleRange = async () => {
    setShowFromDate(false)
    setShowToDate(false)
    if (userType === "charity") {
      if (fromDate && toDate) {
        let offsetTimeFromDate = new Date(fromDate.getTime() - fromDate.getTimezoneOffset() * 60 * 1000);
        let offsetTimeToDate = new Date(toDate.getTime() - toDate.getTimezoneOffset() * 60 * 1000);
        liveValues = await getLiveDataInRange(offsetTimeFromDate.toISOString(), offsetTimeToDate.toISOString())
        setLiveDataInRange(liveValues)
      }
    } else if (userType === "superadmin" || userType === "admin" || userType === "countryadmin") {
      if (fromDate && toDate && charityID) {
        let offsetTimeFromDate = new Date(fromDate.getTime() - fromDate.getTimezoneOffset() * 60 * 1000);
        let offsetTimeToDate = new Date(toDate.getTime() - toDate.getTimezoneOffset() * 60 * 1000);
        liveValues = await adminGetLiveDataInRange(offsetTimeFromDate.toISOString(), offsetTimeToDate.toISOString(), charityID)
        setLiveDataInRange(liveValues)
      }else if(fromDate && toDate && !charityID){
        let offsetTimeFromDate = new Date(fromDate.getTime() - fromDate.getTimezoneOffset() * 60 * 1000);
        let offsetTimeToDate = new Date(toDate.getTime() - toDate.getTimezoneOffset() * 60 * 1000);
        liveValues = await AllAdminStatsInRange(offsetTimeFromDate.toISOString(), offsetTimeToDate.toISOString())
        setLiveDataInRange(liveValues)
      }
    } 
  }
  
  const {
    total_fund_collected: fundsCollectedInRange,
    total_fund_collected_currency_sign: currencySignInRange,
    total_gloden_buzzers: totalGBsInRange,
    total_posts_tagged: totalPostsInRange
  } = liveDataInRange ? liveDataInRange : ""

  return (
    <div className="dashboard-wrapper">
      <NavBar />
      <div className="dashboard-container">
        <div className="mx-auto container col-lg-8 col-md-10">
          {(userType == "admin" || userType == "superadmin" || userType == "countryadmin") &&
            <>
              <Container>
                <Row className="admin-dropdowns" style={{display:"flex",justifyContent:"flex-end"}}>
              {/* ------ Country dropdown ------ */}
              <div className="dropdown-container dropdown-country">
                <img
                  className="dropdown-arrow"
                  src={dropdownButtonArrow}
                  alt="dropdownButtonArrow"
                />

                <DropdownButton
                  disabled = {countryAdminCountryId ? true : false}
                  className="dropdown-btn"
                  onSelect={handleCountry}
                  title={
                    <span className="dropdown-span">
                      {countryName
                        ? text_truncate(countryName, 18)
                        : "Country"}
                    </span>
                  }
                >
                  <div className="dropdown-options">
                    <Dropdown.Item eventkey=" ">Country</Dropdown.Item>
                    { countriesList && Object.values(countriesList).map(
                      (countryData, index) => (
                        <Dropdown.Item
                          eventKey={`${countryData.country_external_id},${countryData.country_name}`}
                          key={countryData.country_external_id}
                        >
                          {countryData.country_name}
                        </Dropdown.Item>
                      )
                    )}
                  </div>
                </DropdownButton>
              </div>

              {/* ------ Charity dropdown ------ */}
              <div className="dropdown-container dropdown-charity">
                <img
                  className="dropdown-arrow"
                  src={dropdownButtonArrow}
                  alt="dropdownButtonArrow"
                />

                <DropdownButton
                  disabled={countryName ? false : true}
                  className="dropdown-btn"
                  onSelect={handleCharity}
                  title={
                    <span className="dropdown-span">
                      {charity ? text_truncate(charity, 25) : "Charity"}
                    </span>
                  }
                >
                  
                  <Dropdown.Item eventkey=" ">Charity</Dropdown.Item>
                  {charityNames && charityNames.map((cause, index) => {
                    return (
                      <Dropdown.Item
                        eventKey={`${cause.cause_external_id}, ${cause.cause_name}`}
                        key={cause.cause_external_id}
                      >
                        {cause.cause_name}
                      </Dropdown.Item>
                    )
                  })}
                </DropdownButton>
              </div>
              </Row>
              </Container>
            </>
          }
          <p className="welcome">Welcome, </p>
          <div style={{display:"flex"}}>
          {adminCharityImage && <img style={{ width: "5rem", height: "5rem", objectFit: "contain"}} src={adminCharityImage} alt="" />}
          {adminCharityName && <p className="title ml-4">{adminCharityName}</p>}
          </div>
          <div style={{display:"flex"}}>
         { charityDetails && <img style={{ width: "5rem", height: "5rem", objectFit: "contain"}} src={charityDetails.cause_image} alt="" />}
          {charityDetails && <p className="title ml-4">{charityDetails.cause_name}</p>}
          </div>
          <div className="live-data">
            <p className="live-data-head ">Live Data</p>
            {loading ? <Button className="refresh-button">Please wait..</Button> :
              <Button onClick={handleRefresh} className="refresh-button">Refresh</Button>}
          </div>




          <Row className="fund-widgets">
            <Col className="funds-collected-card">
              <p className="funds-widget-title">Funds Collected</p>
              <p className="funds-widget-amount">
                {currencySign}
                {fundsCollected ?
                  <CountUp
                    start={0}
                    decimals={2}
                    end={+fundsCollected}
                    duration={3}

                  /> : "-"}</p>
            </Col>
            {/* <Col className="funds-withdrawn-card">
              <p className="funds-widget-title">Funds Withdrawn</p>
              <p className="funds-widget-amount">-</p>
            </Col>
            <Col className="funds-balance-card">
              <p className="funds-widget-title">Balance Fund</p>
              <p className="funds-widget-amount">-</p>
            </Col> */}
          </Row>
          <Row className="posts-gb">
            <Col className="posts-tagged">
              <p className="posts-gb-widget-title">Total Posts Tagged</p>
              <img className="postsTaggedImage" src={postsImage} />
              <p className="posts-gb-count">
                {totalPosts ? <CountUp
                  start={0}
                  end={+totalPosts}
                  duration={3}
                /> : "-"} </p>
            </Col>
            <Col className="gb-received">
              <p className="posts-gb-widget-title">Total GB Recieved</p>
              <img className="goldenHeart" src={goldenHeart} />
              <p className="posts-gb-count">
                {totalGBs ? <CountUp start={0} end={+totalGBs} duration={3} /> 
                : (liveData && liveData.total_golden_buzzers) 
                ? <CountUp start={0} end={liveData.total_golden_buzzers} duration={3} />
                : "-" }
              </p>
            </Col>
          </Row>

          <div className="fund-raised">
            <p className="fund-raised-head ">Fund Collected</p>

            <div className="date-container">
              <div onClick={handleShowFromDate} className="datePicker">{fromDate ? fromDate.toString().substring(4, 15) : "From"}
                <img className='dropdown-calIcon' src={calenderImg} alt='dropdownButtonArrow' />
              </div>
              {showFromDate && <div className="selectDatePicker">
                <SelectDatepicker
                  value={fromDate}
                  onDateChange={onFromDateChange}
                  minDate={new Date(2018, 0, 1)}
                  maxDate={new Date()}
                  showLabels={false}
                  monthNames={["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]}
                  format={"day/month/year"}
                />
              </div>
              }
              {(fromDate && toDate && !showToDate) && ((toDate.getTime() - fromDate.getTime() <= 86400000) && (toDate.toString().substring(0,15) === new Date().toString().substring(0,15)) ) ? 
              <div style={{pointerEvents:"none",opacity:"0.7"}} className="datePicker">{toDate ? toDate.toString().substring(4, 15) : "To"}
                <img className='dropdown-calIcon' src={calenderImg} alt='dropdownButtonArrow' />
              </div> : 
              <div onClick={handleShowToDate} className="datePicker">{toDate ? toDate.toString().substring(4, 15) : "To"}
              <img className='dropdown-calIcon' src={calenderImg} alt='dropdownButtonArrow' />
            </div>
             }
              {showToDate &&
                <div className="selectDatePicker">
                  <SelectDatepicker
                    value={toDate}
                    onDateChange={onToDateChange}
                    minDate={fromDate ? fromDate : new Date(2018, 0, 1)}
                    maxDate={new Date()}
                    showLabels={false}
                    monthNames={["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]}
                    format={"day/month/year"}
                  />
                </div>
              }
            </div>
            {/* <div className="datePicker">
                  <DatePicker value={fromDate}
                  // yearPlaceholder=""
                  // monthPlaceholder=""
                  calendarIcon={<img className='dropdown-calIcon' src={calenderImg} alt='dropdownButtonArrow' />}
                  clearIcon={null}
                onChange={date => setFromDate(date)} 
                format="dd/MM/yyyy"
                minDetail="decade"
                maxDate={new Date()}
                dayPlaceholder="DD"
                monthPlaceholder="MM"
                yearPlaceholder="YYYY"
                /> 
            </div>   */}
            {/* <div className="datePicker">
            <DatePicker value={toDate} onChange={date => setToDate(date)} 
                  calendarIcon={<img className='dropdown-calIcon' src={calenderImg} alt='dropdownButtonArrow' />}
                  clearIcon={null}
                  format="dd/MM/yyyy"
                  maxDate={new Date()}
                  minDetail="decade"
                  dayPlaceholder="DD"
                  monthPlaceholder="MM"
                  yearPlaceholder="YYYY"
            />
              </div>     
            </div> */}
            <Button onClick={handleRange} className="go">Go</Button>
          </div>

          <Row className="fund-widgets mt-3">
            <Col className="funds-collected-card">
              <p className="funds-widget-title">Total Fund Collected</p>
              <p className="funds-widget-amount">{currencySignInRange}{fundsCollectedInRange ? <CountUp
                start={0}
                decimals={2}
                end={fundsCollectedInRange}
                duration={2}

              /> : "-"}</p>
            </Col>
            <Col className="funds-withdrawn-card">
              <p className="funds-widget-title">Total Posts tagged</p>
              <p className="funds-widget-amount">{totalPostsInRange ? <CountUp
                start={0}
                end={totalPostsInRange}
                duration={2}
              /> : "-"}</p>
            </Col>
            <Col className="funds-balance-card">
              <p className="funds-widget-title">Total GB Recieved</p>
              <p className="funds-widget-amount">
                {totalGBsInRange ? <CountUp start={0} end={totalGBsInRange} duration={2} /> 
                : (liveDataInRange && liveDataInRange.total_golden_buzzers) 
                ? <CountUp start={0} end={liveDataInRange.total_golden_buzzers} duration={3} />
                : "-"}</p>
            </Col>
          </Row>
          <Row className="mt-5"> </Row>
          {/* {console.log(dateState)} */}
          {/* <div className="bar"> */}
          {/* </div> */}

          {/* <Row className="top-users">
            <Col className="top-users-header">Top 10 Users</Col>

            <Table className="table">
              <thead>
                <tr>
                  <th>Rank</th>
                  <th style={{width:'200px',textAlign:'start'}}>Name</th>
                  <th>Posts</th>
                  <th>Golden Buzzers Recieved</th>
                  <th>Pool Collected</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td style={{textAlign:'start'}}>
                    <img className="avatar mr-2" src={avatar} />
                    <span>Chandan</span>
                  </td>
                  <td>23</td>
                  <td>123</td>
                  <td className="poolAmount">$542</td>
                </tr>
                <tr>
                  <td>2</td>
                  <td style={{textAlign:'start'}}>
                  <img className="avatar mr-2" src={avatar} />
                    <span>Jacob</span>
                    </td>
                  <td>45</td>
                  <td>510</td>
                  <td className="poolAmount">$5100</td>
                </tr>
              </tbody>
            </Table>
          </Row> */}
        </div>
      </div>
      {/* <Footer /> */}
    </div>
  );
};

export default Dashboard;
