import { GENERATE_NEW_TOKEN } from '../../constants/action-types'

const initState = {}

const ActivityGenerateTokenReducer = ( state = initState, action ) => {
    if (action.type === GENERATE_NEW_TOKEN) {
        return {
            ...state,
            ...action.payload
        }
        
    }
    return state
}
export default ActivityGenerateTokenReducer;