import { REPORTED_POST } from '../../constants/action-types';

const initState = {
    arr:[]
}

const reportedPostReducer = (state = initState, action) => {
    if (action.type === REPORTED_POST) {
       return { 
        ...state,
        arr: [...state.arr, action.payload]
    }
    }
    return state
}
export default reportedPostReducer