import { GET_CHARITY_ID } from '../../constants/action-types'

const initState = ""

const CharityIDReducer = (state = initState, action) => {
    if (action.type === GET_CHARITY_ID) {
        return action.payload
    }
    return state
}
export default CharityIDReducer