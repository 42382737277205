import { GET_CHARITY_NAMES } from '../../constants/action-types'

const initState = {}

const CharityNamesReducer = (state = initState, action) => {
  if (action.type === GET_CHARITY_NAMES) {
    return {
      ...state,
      ...action.payload
    }
  }

  return state;
}

export default CharityNamesReducer;