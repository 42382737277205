import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
// import { BASEURL } from '../../config'
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  FormControl,
  InputGroup,
  Dropdown,
  DropdownButton,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import axiosCreateContent from "../../axios/createContentAxios";

import NavBar from "../Dashboard/DashboardNavbar";
import arrowLeft from "../../assets/images/arrow-left.svg";
import close from "../../assets/images/x.svg";
import "../../assets/scss/CheckoutForm/checkoutForm.scss";

import secureTickMark from "../../assets/images/secure-tick-mark.svg";
import sheild from "../../assets/images/sheild.svg";
// import rapydlogo from "../../assets/svg/rapyd.svg";
import LeanmoreFooter from "../LandingLearnMore/LeanmoreFooter";
import { adminCharityInfo } from "../../actions/AdminCharityInfo";

import { transferToCharity } from "../../api/TransactionsApi";
import { getCharityID } from "../../actions/storeCharityID";
import dropdownButtonArrow from "../../assets/svg/dropdownButtonArrow.svg";
import { loadCharityNames } from "../../actions/CharityList";
import "../../assets/scss/Dashboard/DropdownStyles.scss";
import { storeStripeCountries } from "../../actions/countryList";
import stripelogo from "../../assets/svg/stripe.svg";

const BodyStyle = () => {
  useEffect(() => {
    // document.body.classList.add(MODAL_OPEN_CLASS);
    document.getElementById("body").className = "bodyStyleModal";
    return () => {
      document.getElementById("body").className = " ";
    };
  }, []);
  return <></>;
};

const AdminForm = () => {
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(false);
  const [amount, setAmount] = useState();
  const [redirectUrl, setRedirectUrl] = useState("");
  const [processingTo, setProcessingTo] = useState(false);
  // const [walletSign, setWalletSign] = useState();
  const charityID_Value = useSelector(
    (state) => state.CharityIDReducer.charityID
  );
  const userType = useSelector(state => state.newUserToken.user_type);
  
  // const [charityID, setCharityID] = useState(() => charityID_Value);
  const [charityID, setCharityID] = useState();
  const [countryName, setCountryName] = useState("");
  const [countryID, setCountryID] = useState("");
  const [charity, setCharity] = useState("");
  const charityNames = useSelector((state) => state.charityNames.data);
  // const countriesList = useSelector((state) => state.countriesList.data);
  const stripeCountriesList = useSelector((state) => state.stripeCountries.data);
  const adminCharityCurrencyCode = useSelector(state => state.AdminCharityInfoIDReducer.country_currency_code)
  const dispatch = useDispatch();


  useEffect(()=>{
    dispatch(storeStripeCountries());
  },[])

  // const userCountryDetails = useSelector(state => state.countriesList.data)
  // const charityDetails = useSelector(state => state.newUserToken.cause_info);
  

  useEffect(() => {
    if (countryID) {
      dispatch(loadCharityNames(countryID));
    }
  }, [countryID]);

  useEffect(() => {
    if (userType === "superadmin" || userType === "admin") {
        // console.log(charityID)
        dispatch(adminCharityInfo(charityID))
    }
}, [charityID])

  const handleCountry = (e) => {
    const name = e ? e.split(",") : "";
    // console.log(name[1]);
    let countryExternalId = name[0];
    // console.log(countryExternalId);

    if (countryExternalId !== "") {
      setCountryID(countryExternalId);
      Object.values(stripeCountriesList).map((country) => {
        if (country.country_external_id === countryExternalId) {
          setCountryName(country.country_name);
          // setWalletSign(country.country_wallet_currency_sign)
          // console.log(country);
          setCharity("");
          setCharityID("");
        }
      });
    } else if (countryExternalId === "") {
      setCountryID("");
      setCountryName("");
      setCharity("");
      setCharityID("");
    }
  };

  const text_truncate = (str, length, ending) => {
    if (length == null) {
      length = 100;
    }
    if (ending == null) {
      ending = "...";
    }
    if (str.length > length) {
      return str.substring(0, length - ending.length) + ending;
    } else {
      return str;
    }
  };

  const handleCharity = (e) => {
    // console.log(e);
    if (e !== null) {
      const charity = e;
      const name = charity.split(",");
      setCharityID(name[0]);
      setCharity(name[1]);
      dispatch(getCharityID(name[0]));
    } else {
      setCharity("");
      setCharityID("");
    }
  };

  //API Call
  const handleSubmit = async (e, amount) => {
    e.preventDefault();
    setProcessingTo(true);
    // axiosCreateContent({
    //     url: `/v1/charities/payout`,
    const res = await transferToCharity(amount, charityID,adminCharityCurrencyCode);
    // console.log(res.response.data.response.status)
    // console.log(res);
    if (res && res.data && res.data.response.statusCode === 200) {
      setError(false);
      setOpen(true);
    } else {
      setError(true);
      setOpen(true);
    }
  };

  // const handleSubmit = (e, amount) => {
  //     e.preventDefault();
  //     setProcessingTo(true);
  //     axiosCreateContent({
  //         url: `/v1/rapyd/checkout`,
  //         method: "POST",
  //         headers: {
  //             Accept: "application/json",
  //             "Access-Control-Allow-Origin": "*",
  //             // 'Authorization': `Bearer ${tokenDetails.token}`,
  //             // 'userexternalid': tokenDetails.user_external_id,
  //         },
  //         data: {
  //             amount: amount,
  //         },
  //         withCredentials: true,
  //     })
  //         .then((res) => {
  //             setRedirectUrl(res.data.response.data.redirectUrl);
  //             setOpen(true);
  //         })
  //         .catch((err) => {
  //             console.log(err);
  //             setError(true);
  //         });
  // };

  //check min and max load of user
  const handleSetAmount = (e) => {
    setAmount(e.target.value);
    //     // console.log(e.target.value * 1);
    //     if (e.target.value * 1 < userDetails.country_min_load * 1) {
    //         // console.log('show')
    //         e.target.setCustomValidity(`Minimum balance update amount is ${userDetails.country_min_load}.`);
    //         setAmount(e.target.value)
    //     } else if (e.target.value * 1 > userDetails.country_max_load * 1) {
    //         e.target.setCustomValidity(`Maximum balance update amount is ${userDetails.country_max_load}`);
    //         setAmount(e.target.value)
    //     } else {
    //         // console.log('hide')
    //         e.target.setCustomValidity("");
    //         setAmount(e.target.value)
    //     }
  };

  const handleClose = () => {
    setProcessingTo(false);
    setOpen(false);
    // setCharityID("");
    setAmount("");
  };

  //Displaying the approriate currency symbol based on the users country

  let styles = open ? { display: "block" } : { display: "none" };

  return (
    <div style={{ position: "relative" }}>
      {processingTo && <BodyStyle />}
      {processingTo && <div className="modal-backdrop"></div>}

      <NavBar />
      <section className="stripePayment-content">
        <Container>
          <Row>
            <Col className="back-link mx-auto" sm={12} md={9} lg={9}>
              <Link to="/transactions" style={{ textDecoration: "none" }}>
                <img src={arrowLeft} alt="arrow-left" /> Back
              </Link>
            </Col>
            <Col className="mx-auto" sm={12} md={9} lg={9}>
              <div className="stripe-your-pool-page">
                <Row className="stripe-header">
                  <Col>
                    <p>Make Payment to the charities</p>
                  </Col>
                </Row>
                <Row className="stripe-body">
                  <Col className="mx-auto" sm={8} md={8} lg={8}>
                    <Form onSubmit={(e) => handleSubmit(e, amount)}>
                      <Row
                        className="admin-dropdowns"
                        style={{
                          justifyContent: "center",
                        }}
                      >
                        <div className="dropdown-container dropdown-country">
                          <img
                            className="dropdown-arrow"
                            src={dropdownButtonArrow}
                            alt="dropdownButtonArrow"
                          />

                          <DropdownButton
                            className="dropdown-btn"
                            onSelect={handleCountry}
                            title={
                              <span className="dropdown-span">
                                {countryName
                                  ? text_truncate(countryName, 18)
                                  : "Country"}
                              </span>
                            }
                          >
                            <div className="dropdown-options">
                              <Dropdown.Item eventkey=" ">
                                Country
                              </Dropdown.Item>
                              {/* {console.log(stripeCountriesList)} */}
                              {stripeCountriesList &&
                                Object.values(stripeCountriesList).map(
                                  (countryData, index) => (
                                    <Dropdown.Item
                                      eventKey={`${countryData.country_external_id},${countryData.country_name}`}
                                      key={countryData.country_external_id}
                                    >
                                      {countryData.country_name}
                                    </Dropdown.Item>
                                  )
                                )}
                            </div>
                          </DropdownButton>
                        </div>

                        {/* ------ Charity dropdown ------ */}
                        <div className="dropdown-container dropdown-charity">
                          <img
                            className="dropdown-arrow"
                            src={dropdownButtonArrow}
                            alt="dropdownButtonArrow"
                          />

                          <DropdownButton
                            disabled={countryName ? false : true}
                            className="dropdown-btn"
                            onSelect={handleCharity}
                            title={
                              <span className="dropdown-span">
                                {charity
                                  ? text_truncate(charity, 25)
                                  : "Charity"}
                              </span>
                            }
                          >
                            <Dropdown.Item eventkey=" ">Charity</Dropdown.Item>
                            {charityNames &&
                              charityNames.map((cause, index) => {
                                return (
                                  <Dropdown.Item
                                    eventKey={`${cause.cause_external_id}, ${cause.cause_name}`}
                                    key={cause.cause_external_id}
                                  >
                                    {cause.cause_name}
                                  </Dropdown.Item>
                                );
                              })}
                          </DropdownButton>
                        </div>
                      </Row>

                      <Form.Group>
                        <Form.Label>Enter Amount</Form.Label>
                        <InputGroup className="">
                          <InputGroup.Prepend>
                            <InputGroup.Text id="amount-addon1">
                            $
                            </InputGroup.Text>
                          </InputGroup.Prepend>

                          <FormControl
                            type="number"
                            placeholder="0.00"
                            aria-label="amount"
                            aria-describedby="amount-addon1"
                            aria-required="true"
                            required
                            onChange={(e) => handleSetAmount(e)}
                            // onChange={(e) => setAmount(e.target.value)}
                            value={amount}
                            // min={`${userDetails.country_min_load}`}
                            // max={`${userDetails.country_max_load}`}
                          />
                        </InputGroup>
                      </Form.Group>
                      {amount && charityID ? (
                        <Button
                          className="blue-buttonActive"
                          size="md"
                          variant="primary"
                          type="submit"
                        >
                          {" "}
                          {processingTo ? "Processing..." : `Proceed`}{" "}
                        </Button>
                      ) : (
                        <Button
                          className="blue-buttonActive"
                          size="md"
                          variant="primary"
                          type="submit"
                          disabled={true}
                        >
                          {processingTo ? "Processing..." : `Proceed`}
                        </Button>
                      )}
                      <Link className="cancel-button" to="/transactions">
                        Cancel
                      </Link>
                    </Form>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col sm={12} md={9} lg={9} className="mx-auto">
              <div style={{ textAlign: "center" }}>
                <img src={secureTickMark} alt="secure" />
                <span>Safe &amp; secured payments by </span>
                <img src={stripelogo} alt="stripe" />
              </div>
            </Col>
          </Row>
          <Row>
            <Col sm={12} md={9} lg={9} className="mx-auto">
              <div style={{ textAlign: "center" }}>
                <img src={sheild} alt="secure" />
                <span>3D Secure Payment Processing</span>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      {/* <LeanmoreFooter /> */}

      <div id="messageModal" className="modal in" role="dialog" style={styles}>
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title" style={{ textAlign: "left" }}>
              Transfer Successful
              </h4>
              <button type="button" className="close" onClick={handleClose}>
                <img src={close} alt="close-button" style={{ width: "100%" }} />
              </button>
            </div>
            <div className="modal-body">
              <p>The money has been transferred to the charity's wallet.</p>
            </div>
            <div className="modal-footer">
              <div className="btn btn-secondary" onClick={handleClose}>
                Close
              </div>

              {/* <Button variant="primary" className="buttonActive">
                {loader ? "Loading..." : `Yes, I'm sure`}
              </Button> */}
              {/* <a href={redirectUrl} className="btn btn-primary">
                                Yes, I'm sure
                            </a> */}
            </div>
          </div>
        </div>
      </div>
      {/* 
            <dialog open={error}>
                <p>
                    There seems to be a problem. Don't worry your money has not been
                    deducted yet.!
            </p>
            </dialog> */}
      {error && (
        <div
          id="messageModal"
          className="modal in"
          role="dialog"
          style={styles}
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title" style={{ textAlign: "left" }}>
                 Transfer Failed
                </h4>
                <button type="button" className="close" onClick={handleClose}>
                  <img
                    src={close}
                    alt="close-button"
                    style={{ width: "100%" }}
                  />
                </button>
              </div>
              <div className="modal-body">
                <p>
                  There seems to be a problem. Don't worry your money has not
                  been deducted yet.!
                </p>
              </div>
              <div className="modal-footer">
                <div className="btn btn-secondary" onClick={handleClose}>
                  Close
                </div>

                {/* <Button variant="primary" className="buttonActive">
                {loader ? "Loading..." : `Yes, I'm sure`}
              </Button> */}
                {/* <a href={redirectUrl} className="btn btn-primary">
                                Yes, I'm sure
                            </a> */}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AdminForm;
