import React from 'react'
import { Container, Spinner } from 'react-bootstrap'


const Loader = () => {
    return (
        <section className="loading" style={{paddingTop: "11rem"}}>
        <Container>
            <div className="text-center">
                <Spinner animation="border" role="status">
                    <span className="sr-only">Loading...</span>
                </Spinner>
            </div>
        </Container>
        </section>
    )
}

export default Loader
