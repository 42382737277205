import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import NavBarDashboard from "../Dashboard/DashboardNavbar";
import Footer from "../CharityFooter/Footer";
import {
  Table,
  Container,
  Row,
  Col,
  Card,
  Button,
  Form,
  DropdownButton,
  Dropdown,
  Nav,Navbar,NavLink,
  ButtonGroup,
} from "react-bootstrap";
// import "react-date-picker/dist/react-date-picker.css";
import { getLiveDataInRange, getLiveData, adminGetLiveData, adminGetLiveDataInRange } from "../../api/LiveData"
import SelectDatepicker from 'react-select-datepicker';
import CountUp from 'react-countup';
import '../../assets/scss/Dashboard/ActivityPage.scss';
import '../../assets/scss/Dashboard/DropdownStyles.scss';

// import css
import "../../assets/scss/Dashboard/Dashboard.scss";

//import svgs
import calenderImg from "../../assets/svg/calenderIcon.svg";
import { CollectedAmountList } from '../../api/AdminReportApis/CollectedAmount'

import { storeCountriesList } from "../../actions/countryList"
import { loadCharityNames } from "../../actions/CharityList"
import { getCharityID } from '../../actions/storeCharityID';
import { adminCharityInfo } from "../../actions/AdminCharityInfo";


import {PoolingRatio} from '../../api/AdminReportApis/PoolingRatio'
import { TotalUsers,ActiveUsers,InactiveUsers } from '../../api/AdminReportApis/UsersCount'
import {AvgAmountPerPost, AvgAmountLoaded} from '../../api/AdminReportApis/Averages'

const ReportsDashboard = (props) => {
  const [countryID, setCountryID] = useState("");
  const charityID_Value = useSelector(state => state.CharityIDReducer.charityID);
  const [charityID, setCharityID] = useState(() => charityID_Value);
  const charityNames = useSelector((state) => state.charityNames.data);
  const userType = useSelector(state => state.newUserToken.user_type);
  const countriesList = useSelector(state => state.countriesList.data);
  const [showDatePicker, setShowDatePicker] = useState(true);
  const [fromDate, setFromDate] = useState();
  const [showFromDate, setShowFromDate] = useState(false);
  const [showToDate, setShowToDate] = useState();
  const [toDate, setToDate] = useState(new Date());
  const [loading, setLoading] = useState(false)
  const [value, setValue] = useState(null);
  const charityDetails = useSelector(state => state.newUserToken.cause_info);
  const [dateValue, setDateValue] = useState("1");
  const [date, setDate] = useState("");
  const [overallCollectedAmt, setOverallCollectedAmt] = useState()
  

  
  // const [liveData, setLiveData] = useState({usersCount:null,poolRatio:null,avg:null});
  const [totalUsers, setTotalUsers] = useState();
  const [activeUsers, setActiveUsers] = useState();
  const [inactiveUsers, setInactiveUsers] = useState();
  const [poolRatio, setPoolRatio] = useState({});
  const [avgAmtPerPost, setAvgAmtPerPost] = useState();
  const [avgAmtLoaded, setAvgAmtLoaded] = useState();
  const [avg, setAvg] = useState({});

  // const liveData = useSelector((state) => state.dashboardDataReducer);

  const dispatch = useDispatch(); //Used to dispatch the actions from the component
  // const liveDataInRange = useSelector((state) => state.dashboardDataReducerInRange);

  const adminCharityImage = useSelector(state => state.AdminCharityInfoIDReducer.cause_image)
  const adminCharityName = useSelector(state => state.AdminCharityInfoIDReducer.cause_name)
  const adminCharityCountry = useSelector(state => state.AdminCharityInfoIDReducer.country_name)
  const [countryName, setCountryName] = useState(()=> adminCharityCountry ? adminCharityCountry : "");
  const [charity, setCharity] = useState(()=> adminCharityName ? adminCharityName : "");

  useEffect(()=>{
    let filterFromDate = new Date();
    filterFromDate.setHours(0);
    filterFromDate.setMinutes(0);
    filterFromDate.setSeconds(0);
    setFromDate(new Date(filterFromDate))
  },[])
  // useEffect(() => {
  //   if (userType === "superadmin" || userType === "admin") {
  //     // console.log(charityID)
  //     dispatch(adminCharityInfo(charityID))
  //     dispatch(adminGetLiveData(charityID))
  //   }
  // }, [charityID])
  useEffect(() => {
    async function fetchData() {
      if(fromDate && toDate){
      let offsetTimeFromDate = new Date(fromDate.getTime() - fromDate.getTimezoneOffset() * 60 * 1000);
      let offsetTimeToDate = new Date(toDate.getTime() - toDate.getTimezoneOffset() * 60 * 1000);
      setTotalUsers(await TotalUsers(offsetTimeFromDate,offsetTimeToDate))
      setActiveUsers(await ActiveUsers(offsetTimeFromDate,offsetTimeToDate))
      setInactiveUsers(await InactiveUsers(offsetTimeFromDate,offsetTimeToDate))
      setAvgAmtPerPost(await AvgAmountPerPost(offsetTimeFromDate,offsetTimeToDate))
      if (userType === "superadmin"){
        setPoolRatio(await PoolingRatio(offsetTimeFromDate,offsetTimeToDate))
        setAvgAmtLoaded(await AvgAmountLoaded(offsetTimeFromDate,offsetTimeToDate))
      }
      let ca = await CollectedAmountList(offsetTimeFromDate, offsetTimeToDate)
      setOverallCollectedAmt(ca.total_amount_collected_usd)
      }
  }
  fetchData();
}, [fromDate,toDate]);

  // useEffect(()=>{
  //  handleRange()
  // },[])

  useEffect(() => {
    if (countryID) {
      dispatch(loadCharityNames(countryID))
    }
  }, [countryID])


  const handleCountry = (e) => {
    const name = e ? e.split(",") : "";
    let countryExternalId = name[0];

    if (countryExternalId !== "") {
      setCountryID(countryExternalId);
      Object.values(countriesList).map((country) => {
        if (country.country_external_id === countryExternalId) {
          setCountryName(country.country_name);
          setCharity("");
          setCharityID("");
        }
      });
    } else if (countryExternalId === "") {
      setCountryID("");
      setCountryName("");
      setCharity("");
      setCharityID("");
    }
  };

  const text_truncate = (str, length, ending) => {
    if (length == null) {
      length = 100;
    }
    if (ending == null) {
      ending = "...";
    }
    if (str.length > length) {
      return str.substring(0, length - ending.length) + ending;
    } else {
      return str;
    }
  };

  const handleCharity = (e) => {
    if (e !== null) {
      const charity = e;
      const name = charity.split(",");
      setCharityID(name[0]);
      setCharity(name[1]);
      dispatch(getCharityID(name[0]));
    } else {
      setCharity("");
      setCharityID("");
    }
  };
  // let dateVar = new Date()
  // let dateVar2 = new Date()
    // const handleDate = (e) => {
    //   setDateValue(e);
    //   switch (e) {
    //     case "today":
    //       setDate("Today");
    //       setFromDate(new Date(dateVar.setDate(dateVar.getDate() - 1)).toISOString());
    //       setToDate(dateVar2.toISOString())
    //       break;
    //     case "week":
    //       setDate("This Week");
    //       setFromDate(new Date(dateVar.setDate(dateVar.getDate() - 7)).toISOString())
    //       setToDate( dateVar2.toISOString())
    //       break;
    //     case "month":
    //       setDate("This Month");
    //       setFromDate(new Date(dateVar.setDate(dateVar.getMonth() - 1)).toISOString())
    //       setToDate(dateVar2.toISOString())
    //       break;
    //     case "lifetime":
    //       setDate("Lifetime");
    //       setFromDate("")
    //       setToDate("")
    //       break;
    //     default:
    //       setDate("Lifetime");
    //   }
    // };
  const onFromDateChange = useCallback((date) => {
    setFromDate(date);
  }, []);

  const onToDateChange = useCallback((date) => {
    if(date && (date.toString().substring(0,15) === new Date().toString().substring(0,15))){
      setToDate(new Date());
    }
    else {
    setToDate(date);
    }
  }, []);



  //   total_fund_collected: "14.08"
  // total_fund_collected_currency: "GBP"
  // total_fund_collected_currency_sign: "p"
  // total_gloden_buzzers: "4"
  // total_posts_tagged: "55"

  // const {
  //   total_fund_collected: fundsCollected,
  //   total_fund_collected_currency_sign: currencySign,
  //   total_gloden_buzzers: totalGBs,
  //   total_posts_tagged: totalPosts
  // } = liveData

  const handleShowFromDate = () => {
    //  if(!fromDate){
    //   setFromDate(new Date())
    //  }
    setShowFromDate(!showFromDate)
    setShowToDate(false)
  }
  const handleShowToDate = () => {
    // if(!toDate){
    //   setToDate(new Date())
    //  }
    setShowToDate(!showToDate)
    setShowFromDate(false)
  }

  const handleRefresh = () => {
    setLoading(true)
    if (userType === "charity") {
      dispatch(getLiveData())
    }
    else if (userType === "superadmin" || userType === "admin") {
      dispatch(adminGetLiveData(charityID))
    }
    setTimeout(() => {
      setLoading(false)
    }, 500)
  }


  const handleRange = async () => {
    setShowFromDate(false)
    setShowToDate(false)
    if(fromDate && toDate){
    let offsetTimeFromDate = new Date(fromDate.getTime() - fromDate.getTimezoneOffset() * 60 * 1000);
    let offsetTimeToDate = new Date(toDate.getTime() - toDate.getTimezoneOffset() * 60 * 1000);
    setTotalUsers(await TotalUsers(offsetTimeFromDate,offsetTimeToDate))
    setActiveUsers(await ActiveUsers(offsetTimeFromDate,offsetTimeToDate))
    setInactiveUsers(await InactiveUsers(offsetTimeFromDate,offsetTimeToDate))
    if (userType === "superadmin"){
      setPoolRatio(await PoolingRatio(offsetTimeFromDate,offsetTimeToDate))
      setAvgAmtLoaded(await AvgAmountLoaded(offsetTimeFromDate,offsetTimeToDate))
    }
    setAvgAmtPerPost(await AvgAmountPerPost(offsetTimeFromDate,offsetTimeToDate))
    let ca = await CollectedAmountList(offsetTimeFromDate, offsetTimeToDate)
    setOverallCollectedAmt(ca.total_amount_collected_usd)
    }
}
  // console.log({liveData})
 
  return (
    <div className="dashboard-wrapper">
      <div style={{padding:"0px"}} className="dashboard-container">
        <div className="mx-auto container col-lg-8 col-md-10">
          
          <div style={{display:"flex"}}>
         { charityDetails && <img style={{ width: "5rem", height: "5rem", objectFit: "contain"}} src={charityDetails.cause_image} alt="" />}
          {charityDetails && <p className="title ml-4">{charityDetails.cause_name}</p>}
          </div>
          {/* <div className="live-data">
            <p className="live-data-head ">Users Count</p>
            {loading ? <Button className="refresh-button">Please wait..</Button> :
              <Button onClick={handleRefresh} className="refresh-button">Refresh</Button>}
          </div> */}
          <div className="fund-raised">
            <p className="fund-raised-head">Users Count</p>
            {/* <div className="admin-dropdowns" style={{display:"flex",justifyContent:"flex-end"}}>
                <div className="dropdown-container dropdown-month">
                    <img
                      className="dropdown-arrow"
                      src={calenderImg}
                      alt="dropdownButtonArrow"
                    />

                    <DropdownButton
                      className="dropdown-btn"
                      onSelect={handleDate}
                      title={
                        <span className="dropdown-span">
                          {date ? date : "Lifetime"}
                        </span>
                      }
                    >
                     <Dropdown.Item eventKey="today">Today</Dropdown.Item>
                        <Dropdown.Item eventKey="week">This week</Dropdown.Item>
                        <Dropdown.Item eventKey="month">This month</Dropdown.Item>
                        <Dropdown.Item eventKey="lifetime">Lifetime</Dropdown.Item>
                    </DropdownButton>
                  </div>
                
              
              </div> */}
             
            <div className="date-container">
              <div onClick={handleShowFromDate} className="datePicker">{fromDate ? fromDate.toString().substring(4, 15) : "From"}
                <img className='dropdown-calIcon' src={calenderImg} alt='dropdownButtonArrow' />
              </div>
              {showFromDate && <div className="selectDatePicker">
                <SelectDatepicker
                  value={fromDate}
                  onDateChange={onFromDateChange}
                  minDate={new Date(2018, 0, 1)}
                  maxDate={new Date()}
                  showLabels={false}
                  monthNames={["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]}
                  format={"day/month/year"}
                />
              </div>
              }
              {(fromDate && toDate && !showToDate) && ((toDate.getTime() - fromDate.getTime() <= 86400000) && (toDate.toString().substring(0,15) === new Date().toString().substring(0,15)) ) ? 
              <div style={{pointerEvents:"none",opacity:"0.7"}} className="datePicker">{toDate ? toDate.toString().substring(4, 15) : "To"}
                <img className='dropdown-calIcon' src={calenderImg} alt='dropdownButtonArrow' />
              </div> : 
              <div onClick={handleShowToDate} className="datePicker">{toDate ? toDate.toString().substring(4, 15) : "To"}
              <img className='dropdown-calIcon' src={calenderImg} alt='dropdownButtonArrow' />
            </div>
             }
              {showToDate &&
                <div className="selectDatePicker">
                  <SelectDatepicker
                    value={toDate}
                    onDateChange={onToDateChange}
                    minDate={fromDate ? fromDate : new Date(2018, 0, 1)}
                    maxDate={new Date()}
                    showLabels={false}
                    monthNames={["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]}
                    format={"day/month/year"}
                  />
                </div>
              }
            </div>
            <Button onClick={handleRange} className="go">Go</Button>
          
              </div>
          <Row className="fund-widgets">
            <Col className="funds-collected-card">
              <p className="funds-widget-title">Total Users</p>
              <p className="funds-widget-amount">{totalUsers ? <CountUp start={0}  end={totalUsers.total_users} duration={3} /> : "-"}</p>
            </Col>
            <Col className="funds-withdrawn-card">
              <p className="funds-widget-title">Active Users</p>
              <p className="funds-widget-amount">{activeUsers ? <CountUp start={0}  end={activeUsers.total_users} duration={3} /> : "-"}</p>
            </Col>
            <Col className="funds-balance-card">
              <p className="funds-widget-title">Inactive Users</p>
              <p className="funds-widget-amount">{inactiveUsers ? <CountUp start={0}  end={inactiveUsers.total_users} duration={3} /> : "-"}</p>
            </Col>
          </Row>
          {userType === "superadmin" && 
          <>
            <div className="live-data">
              <p className="live-data-head ">Pooling Ratio</p>
              {/* {loading ? <Button className="refresh-button">Please wait..</Button> :
                <Button onClick={handleRefresh} className="refresh-button">Refresh</Button>} */}
            </div>
          <Row className="fund-widgets mt-3">
           
              <Col className="funds-collected-card">
              <p className="funds-widget-title">Total amount loaded</p>
              <p className="funds-widget-amount"> { poolRatio.total_amount_loaded_usd ? <>$<CountUp start={0}  end={poolRatio.total_amount_loaded_usd} duration={3} /></>  :"-" }</p>
              </Col>
            
            
            <Col className="funds-withdrawn-card">
              <p className="funds-widget-title">Total amount pooled</p>
              <p className="funds-widget-amount">{poolRatio.total_amount_collected_usd ? <>$<CountUp start={0}  end={poolRatio.total_amount_collected_usd} duration={3} /></> :"-" } </p>
            </Col>
            <Col className="funds-balance-card">
              <p className="funds-widget-title">Pooling ratio</p>
              <p className="funds-widget-amount">{poolRatio.poolRatio ? <>1 : <CountUp start={0} decimals={2} end={isFinite(poolRatio.poolRatio) ? poolRatio.poolRatio : 0 } duration={3} /></>:"-" } </p>
            </Col>
          </Row>
           </> 
           }
           { (userType === "admin" || userType === "countryadmin") && 
           <>
                <div className="live-data">
                <p className="live-data-head ">Amount Pooled</p>
                </div>
                <Row className="fund-widgets mt-3">
                <Col lg={4} className="funds-withdrawn-card">
                <p className="funds-widget-title">Total amount pooled</p>
                <p className="funds-widget-amount">{overallCollectedAmt>=0 ? overallCollectedAmt :"-" } </p>
                </Col>
                </Row>
            </>
           }
          <div className="live-data">
            <p className="live-data-head ">Average</p>
            {/* {loading ? <Button className="refresh-button">Please wait..</Button> :
              <Button onClick={handleRefresh} className="refresh-button">Refresh</Button>} */}
          </div>
          <Row className="fund-widgets mt-3">
            <Col lg={4} className="funds-collected-card">
              <p className="funds-widget-title">Average Amount Per Post</p>
              <p className="funds-widget-amount"> {(avgAmtPerPost && isNaN(avgAmtPerPost.avg_post_amt_usd) === false) && isFinite(avgAmtPerPost.avg_post_amt_usd) ? <>$<CountUp start={0} decimals={2} end={avgAmtPerPost.avg_post_amt_usd} duration={3} /></> : 0} </p>
            </Col>
            {userType === "superadmin" &&
            <Col lg={4} className="funds-withdrawn-card">
              <p className="funds-widget-title">Average Amount Loaded</p>
              <p className="funds-widget-amount"> {(avgAmtLoaded && isNaN(avgAmtLoaded.average_amount_loaded) === false) && isFinite(avgAmtLoaded.average_amount_loaded) ? <>$<CountUp start={0} decimals={2} end={avgAmtLoaded.average_amount_loaded} duration={3} /></> : 0 }</p>
            </Col>
          }
          </Row>

           <Row className="mt-5"> </Row>
          {/* {console.log(dateState)} */}
          {/* <div className="bar"> */}
          {/* </div> */}
         </div>
      </div>
      {/* <Footer /> */}
    </div>
  );
};

export default ReportsDashboard;
