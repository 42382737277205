import React, { useState, useEffect, useRef } from "react";
import { Card } from "react-bootstrap";

// Slick slider imports
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

//image imports
import blueLogo from "../../../assets/images/blueLogo.svg";

const ContentSlider = ({ item }) => {
  const [imgCount, setImgCount] = useState(1);
  const videoRef = useRef(null)

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        // console.log(entry.intersectionRatio, entry.isIntersecting)
        if (entry.isIntersecting && entry.intersectionRatio >= 0.6 && videoRef && videoRef.current) {
          videoRef.current.play();
        }
        else if (entry.intersectionRatio < 0.6 && videoRef && videoRef.current) {
          videoRef.current.pause(); //pause video
        }
      }, { threshold: [0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1] });
    if (videoRef.current) {
      observer.observe(videoRef.current);
    }
  }, [videoRef])
  //-------------- Slick slider --------------//
  const settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <div>
      {/* <div className='image-count'>{item.content_url.length > 1 && imgCount + '/' + item.content_url.length}</div> */}
      <div className="image-count">
        {item.content_url.length > 1 &&
          imgCount + "/" + item.content_url.length}
      </div>

      <Slider
        {...settings}
        afterChange={(currentSlide) => {
          setImgCount(currentSlide + 1);
        }}
      >
        {
          item.content_url.map(media => {
            if (media.contentType === "image/jpeg" || media.contentType === "image/png") {
              return (
                <>
                  <Card.Img className="activity-image" src={media.signedUrl} />
                </>
              )

            } else {
              return (
                <div className="media-wrapper">
                  {media.isWebUpload ? (
                    <img
                      src={blueLogo}
                      alr="brand-logo"
                      className="video-brand-logo"
                    />
                  ) : null}

                  {/* <video
                  className="Activitypage-contentvideo"
                  id="myVideo"
                  src={media.signedUrl}
                  controls
                  autoPlay
                  muted
                ></video> */}
                  {/* <video ref={videoRef} src={media.signedUrl} className="Activitypage-contentvideo" type="video/mp4" controls muted ></video> */}
                  <video ref={videoRef} src={media.signedUrl} className="Activitypage-contentvideo" type="video/mp4" controls muted preload="auto" ></video>
                  {/* <video ref={videoMobileRef} src={media.signedUrl} className="Activitypage-contentmobilevideo" type="video/mp4" controls muted preload="auto"></video> */}
                </div>

              )
            }
          })
        }
      </Slider>
    </div>
  );
};

export default ContentSlider;
