import axiosInstance from 'axios'
import { BASEURL } from '../config'

export const Login = (Email, Password) => {
    return axiosInstance({
        url: `${BASEURL}/v1/login/verify/password`,
        method: "POST",
        data: {
            user_email_address : Email,
            user_password: Password
        },
        // withCredentials: true,
    })
        .then((response => response.data)
            // console.log("response", response)
        )
        .catch((err) => {
            // throw new Error(err);
            console.log(err)
        });
};