
import axios from 'axios'
import { BASEURL } from '../../config'

 export const CollectedAmountList = (from,to) => {
  from = new Date(from).toISOString()
  to = new Date(to).toISOString()
    return axios({
      url: `${BASEURL}/v1/adminDashboard/collected-amount`,
      method: "POST",
      headers: {
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      data: {
        "from_date":from,
        "to_date":to,
        "group_charity":true
      }
    })
      .then((response) => response.data.response.data)
      .catch((err) => console.error(err));
  };

  export const CollectedAmount = (from,to,cause_id) => {
    return axios({
      url: `${BASEURL}/v1/adminDashboard/collected-amount`,
      method: "POST",
      headers: {
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      data: {
        "from_date":from,
        "to_date":to,
        "cause_external_id":cause_id
      }
    })
      .then((response) => response.data.response.data)
      .catch((err) => console.error(err));
}