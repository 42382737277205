import { AMOUNT_DETAILS_IN_RANGE } from "../../constants/action-types";

const initState = {}

const dashboardDataReducerInRange = (state = initState, action) => {
    if (action.type === AMOUNT_DETAILS_IN_RANGE) {
        return {
            ...state,
            ...action.payload
        }
    }
    return state
}
export default dashboardDataReducerInRange