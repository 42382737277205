import React, { useState, useEffect, useCallback } from "react";
import avatar from "../../assets/svg/userIcon.svg";
import dropdownButtonArrow from '../../assets/svg/dropdownButtonArrow.svg';
import calenderImg from "../../assets/svg/calenderIcon.svg";

import {
  Table,
  Container,
  Row,
  Col,
  Card,
  Button,
  Form,
  DropdownButton,
  Dropdown,
  Nav, Navbar, NavLink,
  ButtonGroup,
} from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";

import { getCharityID } from '../../actions/storeCharityID';
import { CollectedAmountList, CollectedAmount } from '../../api/AdminReportApis/CollectedAmount'
import { TotalPostsList, TotalPosts } from '../../api/AdminReportApis/TotalPosts.js'
import { DefaultUsers } from '../../api/AdminReportApis/DefaultUsers.js'
import { AvgAmtPerCharity } from '../../api/AdminReportApis/AvgAmtPerCharity.js'
// import { adminCharityInfo } from "../../actions/AdminCharityInfo";
import SelectDatepicker from 'react-select-datepicker';


import { loadCharityNames,loadAllCharityNames } from "../../actions/CharityList"
import "../../assets/scss/Dashboard/Dashboard.scss";

// import "../../assets/scss/DashboardReports/reportsStyles.scss"
import '../../assets/scss/Dashboard/DropdownStyles.scss';
import '../../assets/scss/Dashboard/ActivityPage.scss';

const ListOfCharities = () => {
  // const adminCharityName = useSelector(state => state.AdminCharityInfoIDReducer.cause_name)
  // const adminCharityCountry = useSelector(state => state.AdminCharityInfoIDReducer.country_name)
  // const charityID_Value = useSelector(state => state.CharityIDReducer.charityID);
  const [countryID, setCountryID] = useState("");
  const [date, setDate] = useState("");
  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");
  const [charity, setCharity] = useState("");
  // const [charityNames, setcharityNames] = useState();
  const [countryName, setCountryName] = useState("");
  const countriesList = useSelector(state => state.countriesList.data);
  const [charityID, setCharityID] = useState("");
  const dispatch = useDispatch(); //Used to dispatch the actions from the component
  const [dateValue, setDateValue] = useState("1");
  const [fromDate, setFromDate] = useState();
  const [showFromDate, setShowFromDate] = useState(false);
  const [showToDate, setShowToDate] = useState();
  const [toDate, setToDate] = useState(new Date());
  const [collectedAmtList, setCollectedAmtList] = useState([])
  const [collectedAmt, setCollectedAmt] = useState([])
  const [totalPostsList, setTotalPostsList] = useState([])
  const [totalPosts, setTotalPosts] = useState([])
  const [defaultUsers, setDefaultUsers] = useState([])
  const [avgAmtPerCharity, setAvgAmtPerCharity] = useState([])
  const [overallPosts, setOverallPosts] = useState()
  const [overallCollectedAmt, setOverallCollectedAmt] = useState([])
  const charityNames = useSelector((state) => state.charityNames.data);
  const userType = useSelector(state => state.newUserToken.user_type);
  const countryAdminCountryId = useSelector(state => state.newUserToken.country_external_id);
  const countryAdminCountryName = useSelector(state => state.newUserToken.country_name);


  useEffect(()=>{
    if(countryAdminCountryName){
      setCountryName(countryAdminCountryName)
      // setCountryID(countryAdminCountryId)
      dispatch(loadCharityNames(countryAdminCountryId))
    }
  },[])

  useEffect(() => {
    if (userType === "superadmin" || userType === "admin") {
      if (countryID) {
        dispatch(loadCharityNames(countryID))
      }else{
        dispatch(loadAllCharityNames())
      }
    }
  }, [countryID])

  

  useEffect(()=>{
    let filterFromDate = new Date();
    filterFromDate.setHours(0);
    filterFromDate.setMinutes(0);
    filterFromDate.setSeconds(0);
    setFromDate(new Date(filterFromDate))
  },[])
  
  useEffect(() => {
    async function fetchData() {
      if(fromDate && toDate){
      let offsetTimeFromDate = new Date(fromDate.getTime() - fromDate.getTimezoneOffset() * 60 * 1000);
      let offsetTimeToDate = new Date(toDate.getTime() - toDate.getTimezoneOffset() * 60 * 1000);
      let du = await DefaultUsers(offsetTimeFromDate, offsetTimeToDate)
      setDefaultUsers(du.default_charity_usr_cnt)
      let tp = await TotalPostsList(offsetTimeFromDate, offsetTimeToDate)
      setTotalPostsList(tp.total_amount_charity)
      setOverallPosts(tp.total_posts)
      let ca = await CollectedAmountList(offsetTimeFromDate, offsetTimeToDate)
      setCollectedAmtList(ca.total_amount_collected_charity)
      setOverallCollectedAmt(ca.total_amount_collected_usd)
      let aa = await AvgAmtPerCharity(offsetTimeFromDate, offsetTimeToDate)
      setAvgAmtPerCharity(aa.avg_amt_charity_usd)
      }
    }
    fetchData();
  }, [collectedAmtList.length, totalPostsList.length, defaultUsers.length,fromDate,toDate]);

  const handleRange = async () => {
    setShowFromDate(false)
    setShowToDate(false)
    if(fromDate && toDate){
    let offsetTimeFromDate = new Date(fromDate.getTime() - fromDate.getTimezoneOffset() * 60 * 1000);
    let offsetTimeToDate = new Date(toDate.getTime() - toDate.getTimezoneOffset() * 60 * 1000);
    let du = await DefaultUsers(offsetTimeFromDate, offsetTimeToDate)
    setDefaultUsers(du.default_charity_usr_cnt)
    let tp = await TotalPostsList(offsetTimeFromDate, offsetTimeToDate)
    setTotalPostsList(tp.total_amount_charity)
    let ca = await CollectedAmountList(offsetTimeFromDate, offsetTimeToDate)
    setCollectedAmtList(ca.total_amount_collected_charity)
    let aa = await AvgAmtPerCharity(offsetTimeFromDate, offsetTimeToDate)
    setAvgAmtPerCharity(aa.avg_amt_charity_usd)
    }
  }

  useEffect(() => {
    async function fetchData() {
      if(fromDate && toDate){
        let offsetTimeFromDate = new Date(fromDate.getTime() - fromDate.getTimezoneOffset() * 60 * 1000);
        let offsetTimeToDate = new Date(toDate.getTime() - toDate.getTimezoneOffset() * 60 * 1000);
      let ca = await CollectedAmount(offsetTimeFromDate, offsetTimeToDate, charityID)
      setCollectedAmt(ca.total_amount_collected_charity[0])
      let tp = await TotalPosts(offsetTimeFromDate, offsetTimeToDate, charityID)
      setTotalPosts(tp.total_amount_charity[0])
    }
  }
    fetchData();
  }, [charityID,fromDate,toDate]);

  const onFromDateChange = useCallback((date) => {
    setFromDate(date);
  }, []);

  const handleShowToDate = () => {
    setShowToDate(!showToDate)
    setShowFromDate(false)
  }

  const onToDateChange = useCallback((date) => {
    if(date && (date.toString().substring(0,15) === new Date().toString().substring(0,15))){
      setToDate(new Date());
    }
    else {
    setToDate(date);
    }
  }, []);

  let defaultUsersCol = []
  let coolectedAmountCol = []
  let avgAmtPerCharityCol = []
  defaultUsersCol.length = totalPostsList && totalPostsList.length
  coolectedAmountCol.length = totalPostsList && totalPostsList.length
  avgAmtPerCharityCol.length = totalPostsList && totalPostsList.length
  defaultUsersCol.fill(0)
  coolectedAmountCol.fill(0)
  avgAmtPerCharityCol.fill(0)
  {totalPostsList && totalPostsList.map((cause, index) => {
    defaultUsers.map((item) => { if (item.cause_name === cause.cause_name) { return defaultUsersCol[index] = item.total_users_count } })
    collectedAmtList.map(item => { if (item.cause_external_id === cause.cause_external_id) { return coolectedAmountCol[index] = `$${item.amount_collected_usd.toFixed(2)}` }})
    avgAmtPerCharity.map(item => { if (item.cause_external_id === cause.cause_external_id) {return avgAmtPerCharityCol[index] = `$${item.average_amount}` }})
  })}

  let col00 = [];
  let col01 = [];
  let col1 = [];
  let col2 = [];
  let col3 = [];
  let col4 = [];
  if (charityNames) {

    col00.length = charityNames.length;
    col01.length = charityNames.length;
    col1.length = charityNames.length;
    col2.length = charityNames.length;
    col3.length = charityNames.length;
    col4.length = charityNames.length;
  }
  col00.fill(0);
  col01.fill(0);
  col1.fill(0);
  col2.fill(0);
  col3.fill(0);
  col4.fill(0);
  charityNames && charityNames.map((cause, index) => {
    //  <td style={{textAlign:'start'}}><img className="avatar mr-2" style={{width:"2rem",height:"2rem"}} src={cause.cause_image} />
    //   <span>{cause.cause_name}</span>
    //    </td>
    col00[index] = cause.cause_image
    col01[index] = cause.cause_name
    defaultUsers.map((item) => { if (item.cause_name === cause.cause_name) { return col1[index] = item.total_users_count } })
    totalPostsList.map(item => { if (item.cause_external_id === cause.cause_external_id) return col2[index] = item.total_posts })
    collectedAmtList.map(item => { if (item.cause_external_id === cause.cause_external_id) return col3[index] = `$${item.amount_collected_usd.toFixed(2)}` })
    avgAmtPerCharity.map(item => { if (item.cause_external_id === cause.cause_external_id) return col4[index] = `$${item.average_amount}` })
  })

  const handleCountry = (e) => {

    if (e) {
      const name = e ? e.split(",") : "";
      let countryExternalId = name[0];
      if (countryExternalId !== "") {
        setCountryID(countryExternalId);
        Object.values(countriesList).map((country) => {
          if (country.country_external_id === countryExternalId) {
            setCountryName(country.country_name);
            setCharity("");
            setCharityID("");
          }
        });
      }

    } else {
      dispatch(getCharityID(""));
      setCountryID("");
      setCountryName("");
      setCharity("");
      setCharityID("");
    }
  };
  const text_truncate = (str, length, ending) => {
    if (length == null) {
      length = 100;
    }
    if (ending == null) {
      ending = "...";
    }
    if (str.length > length) {
      return str.substring(0, length - ending.length) + ending;
    } else {
      return str;
    }
  };
  const handleCharity = (e) => {
    if (e !== null) {
      const charity = e;
      const name = charity.split(",");
      setCharityID(name[0]);
      setCharity(name[1]);
      dispatch(getCharityID(name[0]));
    } else {
      dispatch(getCharityID(""));
      setCharity("");
      setCharityID("");
    }
  };
  const handleShowFromDate = () => {
    //  if(!fromDate){
    //   setFromDate(new Date())
    //  }
    setShowFromDate(!showFromDate)
    setShowToDate(false)
  }
  return (
    <section style={{ backgroundColor: "#f2f2f2", fontFamily:"DM Sans", width: "100%", paddingTop: "4rem", minHeight: "700px" }}>
      <Container>

        <div className="charities-adminreport">
          <div className="charities-table-header">Charities</div>

          <Row style={{ justifyContent: "space-between", marginTop: "1rem" }}>

            <div className="admin-dropdowns-country-charity">


              {/* ------ Country dropdown ------ */}
              <div className="dropdown-container dropdown-country">
                <img
                  className="dropdown-arrow"
                  src={dropdownButtonArrow}
                  alt="dropdownButtonArrow"
                />

                <DropdownButton
                 disabled = {countryAdminCountryId ? true : false}
                  className="dropdown-btn"
                  onSelect={handleCountry}
                  title={
                    <span className="dropdown-span">
                      {countryName
                        ? text_truncate(countryName, 18)
                        : "Country"}
                    </span>
                  }
                >
                  <div className="dropdown-options">
                    <Dropdown.Item eventkey=" ">Country</Dropdown.Item>
                    {countriesList && Object.values(countriesList).map(
                      (countryData, index) => (
                        <Dropdown.Item
                          eventKey={`${countryData.country_external_id},${countryData.country_name}`}
                          key={countryData.country_external_id}
                        >
                          {countryData.country_name}
                        </Dropdown.Item>
                      )
                    )}
                  </div>
                </DropdownButton>
              </div>

              {/* ------ Charity dropdown ------ */}
              <div className="ml-2 dropdown-container dropdown-charity">
                <img
                  className="dropdown-arrow"
                  src={dropdownButtonArrow}
                  alt="dropdownButtonArrow"
                />

                <DropdownButton
                  disabled={countryName ? false : true}
                  className="dropdown-btn"
                  onSelect={handleCharity}
                  title={
                    <span className="dropdown-span">
                      {charity ? text_truncate(charity, 25) : "Charity"}
                    </span>
                  }
                >

                  <Dropdown.Item eventkey=" ">Charity</Dropdown.Item>
                  {charityNames && charityNames.map((cause, index) => {
                    return (
                      <Dropdown.Item
                        eventKey={`${cause.cause_external_id}, ${cause.cause_name}`}
                        key={cause.cause_external_id}
                      >
                        {cause.cause_name}
                      </Dropdown.Item>
                    )
                  })}
                </DropdownButton>
              </div>



              {/* Filter Date */}
              {/* <div className="admin-dropdowns">
                  <div className="dropdown-container dropdown-month">
                      <img
                        className="dropdown-arrow"
                        src={calenderImg}
                        alt="dropdownButtonArrow"
                      />
  
                      <DropdownButton
                        className="dropdown-btn"
                        onSelect={handleDate}
                        title={
                          <span className="dropdown-span">
                            {date ? date : "Lifetime"}
                          </span>
                        }
                      >
                        <Dropdown.Item eventKey="today">Today</Dropdown.Item>
                        <Dropdown.Item eventKey="week">This week</Dropdown.Item>
                        <Dropdown.Item eventKey="month">This month</Dropdown.Item>
                        <Dropdown.Item eventKey="lifetime">Lifetime</Dropdown.Item>
                      </DropdownButton>
                    </div>
                    </div>
                 */}
            </div>
            <div className="date-container-charityList">
              <div onClick={handleShowFromDate} className="datePicker">{fromDate ? fromDate.toString().substring(4, 15) : "From"}
                <img className='dropdown-calIcon' src={calenderImg} alt='dropdownButtonArrow' />
              </div>
              {showFromDate && <div className="selectDatePicker">
                <SelectDatepicker
                  value={fromDate}
                  onDateChange={onFromDateChange}
                  minDate={new Date(2018, 0, 1)}
                  maxDate={new Date()}
                  showLabels={false}
                  monthNames={["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]}
                  format={"day/month/year"}
                />
              </div>
              }
              {(fromDate && toDate && !showToDate) && ((toDate.getTime() - fromDate.getTime() <= 86400000) && (toDate.toString().substring(0,15) === new Date().toString().substring(0,15)) ) ? 
              <div style={{pointerEvents:"none",opacity:"0.7"}} className="datePicker">{toDate ? toDate.toString().substring(4, 15) : "To"}
                <img className='dropdown-calIcon' src={calenderImg} alt='dropdownButtonArrow' />
              </div> : 
              <div onClick={handleShowToDate} className="datePicker">{toDate ? toDate.toString().substring(4, 15) : "To"}
              <img className='dropdown-calIcon' src={calenderImg} alt='dropdownButtonArrow' />
            </div>
             }
              {showToDate &&
                <div className="selectDatePicker">
                  <SelectDatepicker
                    value={toDate}
                    onDateChange={onToDateChange}
                    minDate={fromDate ? fromDate : new Date(2018, 0, 1)}
                    maxDate={new Date()}
                    showLabels={false}
                    monthNames={["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]}
                    format={"day/month/year"}
                  />
                </div>
              }
              <Button onClick={handleRange} className="go ml-2">Go</Button>
            </div>
          </Row>

        </div>
        {charityID ? <Table className="table">
          <thead>
            <tr>
              <th style={{ width: '200px', textAlign: 'start' }}>Charity Name</th>
              <th>Total Posts Tagged</th>
             <th>Amount Pooled</th>
            </tr>
          </thead>
          <tbody>
            {charityID &&
              <tr>
                <td style={{ textAlign: 'start' }}><img className="mr-2" style={{ maxWidth: "6rem", minWidth: "2rem", height: "2rem" }} src={totalPosts && totalPosts.cause_image} />
                  <span>{totalPosts && totalPosts.cause_name}</span>
                </td>
                <td>{totalPosts && totalPosts.total_posts}</td>
               <td>{collectedAmt && `$ ${collectedAmt.amount_collected_usd.toFixed(2)}`}</td>
              </tr>
            }
          </tbody>
        </Table>
          : <Table className="table">
            <thead>
              <tr>
                <th>SL No.</th>
                <th style={{ width: '200px', textAlign: 'start' }}>Charity Name</th>
                <th>Default Users</th>
                <th>Total Posts Tagged</th>
              <th>Amount Pooled</th>
                <th>Average Amount</th>
              </tr>
            </thead>
            <tbody>

              {charityNames && charityNames.map((cause, index) => {

                return (
                  <tr>
                    <td>{index + 1}</td>
                    <td style={{ textAlign: 'start' }}><img className="mr-2" style={{ maxWidth: "6rem", minWidth: "2rem", height: "2rem" }} src={col00[index]} />
                      <span>{col01[index]}</span>
                    </td>
                    <td>{col1[index]}</td>
                    <td>{col2[index]}</td>
                    <td>{col3[index]}</td>
                    <td>{col4[index]}</td>
                    {/* <td>{avgAmtPerCharity && avgAmtPerCharity.map(item=> {if(item.cause_external_id === cause.cause_external_id) return `$${item.average_amount}` })}</td> */}
                  </tr>
                )
              })}
              {/* {(userType === "admin" || userType === "countryadmin") && */}
              <tr>
                <td></td>
                <td style={{ textAlign: "start",fontWeight:"500" }}>Total</td>
                <td></td>
             {(userType === "admin" || userType === "superadmin") ? <td style={{fontWeight:"500"}}>{overallPosts}</td> : <td></td>}
               <td style={{fontWeight:"500"}}>{`$${overallCollectedAmt}`}</td>
              </tr>
              {/* } */}

            </tbody>
          </Table>}

        {/* {countryID === "" && countryID === "" && 
         
         <Table className="table">
         <thead>
           <tr>
             <th>SL No.</th>
             <th style={{ width: '200px', textAlign: 'start' }}>Charity Name</th>
             <th>Default Users</th>
             <th>Total Posts Tagged</th>
             <th>Amount Pooled</th>
             <th>Average Amount</th>
           </tr>
         </thead>
         <tbody>

           {totalPostsList && totalPostsList.map((cause, index) => {

             return (
               <tr>
                 <td>{index + 1}</td>
                 <td style={{ textAlign: 'start' }}><img className="mr-2" style={{ maxWidth: "6rem", minWidth: "2rem", height: "2rem" }} src={cause.cause_image} />
                   <span>{cause.cause_name}</span>
                 </td>
                 <td>{defaultUsersCol[index]}</td>
                 <td>{cause.total_posts}</td>
                 <td>{coolectedAmountCol[index]}</td>
                 <td>{avgAmtPerCharityCol[index]}</td>
               </tr>
             )
           })}

           <tr>
             <td></td>
             <td style={{ textAlign: "start",fontWeight:"500" }}>Total</td>
             <td></td>
             <td style={{fontWeight:"500"}}>{overallPosts > 0 ? overallPosts : 0 }</td>
            <td style={{fontWeight:"500"}}>{overallCollectedAmt > 0 ? `$${overallCollectedAmt}` : 0}</td>
           </tr>

         </tbody>
       </Table>
          
          } */}

      </Container>
    </section>
  );
};

export default ListOfCharities;