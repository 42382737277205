import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import ReportPost from './ReportPost';
import Loader from '../../Loader';
import ContentSlider from './ContentSlider';
//css imports
import { Card, Button, Image, Form, Collapse, Modal } from 'react-bootstrap';
// import 'emoji-slider';
import { reportDeactivateApi } from "../../../api/ReportPostAPI"
//confetti npm
// import Confetti from 'react-confetti';
import ToastMessage from "../../ToastMessage";

//actions imports
// import { loadContent } from '../../../actions/activity';
import usePagination from '../../../actions/customHooks/usePagination'

//image imports
import CharityLogo from '../../../assets/images/charity logo.png';
import avatar from '../../../assets/images/avatar.png';
import dots from '../../../assets/svg/emojis/Group 302.svg';

const moneyIcon = (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12.7695 15.2937C12.7702 15.2926 12.7706 15.2919 12.7712 15.2908C12.7707 15.2917 12.7701 15.2927 12.7695 15.2937Z" fill="#34B53A" />
    <path d="M12.7793 15.2808C12.7774 15.284 12.7754 15.2874 12.7734 15.291C12.7757 15.2869 12.7776 15.2838 12.7793 15.2808Z" fill="#34B53A" />
    <path d="M12.7881 15.2691C12.7898 15.2659 12.79 15.2657 12.7881 15.2691V15.2691Z" fill="#34B53A" />
    <path d="M12.4738 7.48134C13.691 6.45887 14.5043 4.15541 14.0038 4.05466C13.3387 3.92074 11.8946 4.50795 11.1966 4.61141C10.2066 4.73094 9.12833 3.53785 8.52309 4.20288C8.03098 4.74359 8.8759 6.70999 10.1978 7.56665C6.254 9.50496 0.710744 19.2354 10.3812 19.9415C23.762 20.9184 17.0618 9.37018 12.4738 7.48134Z" fill="#34B53A" />
    <path d="M12.7665 15.301C12.7643 15.305 12.7623 15.3086 12.7607 15.3112C12.7621 15.3089 12.7641 15.3054 12.7665 15.301Z" fill="#34B53A" />
    <path d="M11.6352 16.6124V16.5773C11.4561 16.5754 11.2846 16.5656 11.1191 16.552C11.0295 16.7838 10.9029 17 10.7451 17.1928C11.0431 17.2415 11.345 17.2688 11.6469 17.2727L11.6352 16.6124Z" fill="white" />
    <path d="M10.8439 15.3234C10.6997 14.6339 10.1913 14.0729 9.51547 13.8606C9.32849 13.8041 9.13566 13.7749 8.94088 13.7749C8.45783 13.7769 7.99037 13.956 7.63393 14.2794C6.84119 15 6.7847 16.2291 7.50537 17.0218C8.22605 17.8146 9.45509 17.8711 10.2478 17.1504C10.653 16.7823 10.8848 16.2622 10.8828 15.7149C10.8828 15.5844 10.8692 15.4519 10.8439 15.3234Z" fill="white" />
    <path d="M11.6259 16.1856L11.6142 15.4961C11.4934 15.4941 11.3746 15.4902 11.2616 15.4824C11.2675 15.5603 11.2714 15.6363 11.2714 15.7123C11.2714 15.8642 11.2558 16.0181 11.2266 16.168C11.3551 16.1797 11.4895 16.1856 11.6259 16.1856Z" fill="white" />
    <path d="M13.7624 12.9626C13.628 13.0328 13.4859 13.0893 13.3398 13.1321C12.9015 13.2646 12.3269 13.3366 11.727 13.3366C11.1524 13.3366 10.6051 13.2704 10.1824 13.1477C10.0364 13.1087 9.89611 13.0542 9.76172 12.986L9.77535 13.5353C9.99545 13.6171 10.2 13.734 10.385 13.88C10.3908 13.882 10.3947 13.882 10.4006 13.8839C10.7746 13.9794 11.2459 14.03 11.727 14.03C12.2841 14.03 12.8314 13.9599 13.2268 13.8411C13.6495 13.7125 13.743 13.5781 13.743 13.5528C13.743 13.5256 13.741 13.5002 13.7624 13.473V12.9626Z" fill="white" />
    <path d="M13.2219 12.1889C12.8265 12.0682 12.2791 12 11.7221 12C11.1611 12 10.6352 12.0682 10.2418 12.1889C9.8308 12.3136 9.74121 12.4363 9.73926 12.4753C9.73926 12.4753 9.74121 12.4753 9.74121 12.4792C9.75094 12.522 9.86781 12.6545 10.2866 12.7752C10.6742 12.8882 11.1845 12.9505 11.7221 12.9505C12.2791 12.9505 12.8245 12.8823 13.2199 12.7635C13.6426 12.6369 13.738 12.5025 13.738 12.4753C13.738 12.4499 13.6445 12.3175 13.2219 12.1889Z" fill="white" />
    <path d="M15.9969 17.5787V16.9944C15.8664 17.0606 15.7301 17.1171 15.5879 17.1599C15.1516 17.2924 14.5809 17.3645 13.979 17.3645C13.4453 17.3645 12.9253 17.3041 12.5162 17.197C12.3487 17.1541 12.1871 17.0957 12.0312 17.0197L12.041 17.5846C12.041 17.5865 12.041 17.5865 12.041 17.5885C12.041 17.6177 12.1267 17.7482 12.5377 17.8748C12.9272 17.9936 13.4531 18.0598 14.016 18.0598C14.577 18.0598 15.1048 17.9916 15.4983 17.8689C15.9093 17.7404 15.9989 17.606 15.9989 17.5787H15.9969Z" fill="white" />
    <path d="M13.7625 14.0491C13.6281 14.1172 13.4878 14.1737 13.3418 14.2166C12.9035 14.349 12.3289 14.4211 11.7271 14.4211C11.431 14.4211 11.1369 14.4016 10.8447 14.3646C11.0025 14.5828 11.1194 14.8282 11.1934 15.0892C11.3609 15.1009 11.542 15.1087 11.729 15.1087C11.7349 15.1087 11.7426 15.1087 11.7485 15.1087C12.0679 14.7581 12.9269 14.5828 13.7625 14.5594V14.0491Z" fill="white" />
    <path d="M15.5722 16.0826C15.1379 16.2111 14.573 16.2812 13.9809 16.2812C13.4063 16.2812 12.859 16.215 12.4363 16.0923C12.2902 16.0514 12.1481 15.9969 12.0117 15.9287L12.0195 16.3767L12.0215 16.5403C12.1734 16.7292 12.8999 16.9727 13.979 16.9727C14.5419 16.9727 15.0736 16.9065 15.4729 16.7857C15.8936 16.6571 15.9852 16.5247 15.9852 16.4974C15.9852 16.476 15.9969 16.4526 15.9969 16.4332H15.9988V15.917C15.8625 15.9852 15.7203 16.0436 15.5722 16.0826Z" fill="white" />
    <path d="M15.4865 15.1387C15.0892 15.0179 14.5438 14.9536 13.9867 14.9536H13.9536C13.4355 14.9536 12.9427 15.012 12.5649 15.1192C12.1617 15.2321 12.0234 15.3607 12.0059 15.4133C12.0039 15.4172 12.0039 15.4191 12.0039 15.4211C12.0039 15.4211 12.0039 15.4211 12.0078 15.4289C12.0195 15.4698 12.1344 15.6022 12.5532 15.7249C12.9408 15.836 13.4511 15.8983 13.9887 15.8983C14.5438 15.8983 15.0697 15.834 15.469 15.7152C15.9014 15.5866 15.9988 15.4522 16.0007 15.423V15.4211C15.9988 15.3919 15.9014 15.2633 15.4865 15.1387Z" fill="white" />
  </svg>


)



const Contents = ({ countryID }) => {
  const userContentData = useSelector((state) => state.userContent);

  const [pageNo, setPageNo] = useState(1);

  const dispatch = useDispatch();
  const [show, setShow] = useState(false);

  const [showFullDesc, setShowFullDesc] = useState({ id: "" });
  const userDetails = useSelector((state) => state.userDetails);
  const reportedPostArr = useSelector(state => state.reportedPostReducer.arr)
  const [showReportBtn, setShowReportBtn] = useState("")

  const observer = useRef()
  const nextDate = userContentData.next;
  const token = useSelector(state => state.newUserToken.accessToken)
  const userexternalid = useSelector(state => state.newUserToken.db_user_external_id)
  const userCountryId = useSelector(state => state.newUserToken.country_external_id)
  const countries = useSelector(state => state.countriesList.data)
  const len = 90;
  const charityDetails = useSelector(state => state.newUserToken.cause_info);
  const countriesList = useSelector(state => state.countriesList.data);

  // const [walletSign, setWalletSign] = useState();

  // useEffect(() => {
  //   countriesList && countriesList.map((country) => {
  //     if (country.country_external_id === countryID) {
  //       setWalletSign(country.country_wallet_currency_sign)
  //     }
  //   })
  // }, [countryID])


  let { loading, error, contentsArray, hasMore } = usePagination(pageNo, token, userexternalid)

  for (let reportedPostId of reportedPostArr) {
    contentsArray = contentsArray.filter(item => item.content_external_id !== reportedPostId)
  }
  const lastElementInContent = useCallback(node => {
    if (loading) return
    if (observer.current) observer.current.disconnect()
    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && hasMore) {
        setPageNo(prevpageNo => prevpageNo + 1)
      }
    })
    if (node) observer.current.observe(node)
  }, [loading])

  if (!userContentData) return <Loader />
  // if (contentsArray.length === 0) return <Loader />

  // console.log(userDetails)
  const getPostedTime = (createdDate) => {
    let date1 = new Date(createdDate);
    let date2 = new Date();

    let delta = Math.abs(date2 - date1) / 1000;

    let days = Math.floor(delta / 86400);
    delta -= days * 86400;

    let hours = Math.floor(delta / 3600) % 24;
    delta -= hours * 3600;

    let minutes = Math.floor(delta / 60) % 60;
    delta -= minutes * 60;

    let month = Math.floor(days / 30)

    if (month) {
      if (month === 1) {
        return <p className="timeline">{month} month ago</p>
      }
      else {
        return <p className="timeline">{month} months ago</p>
      }
    } else if (days) {
      return <p className="timeline">{days} days ago</p>
    } else if (hours) {
      return <p className="timeline">{hours} hours ago</p>
    } else if (days === 0 && hours === 0) {
      return <p className="timeline">{minutes} minutes ago</p>
    }
  }
  const handleDeactivation = async (content_external_id) => {
    const res = await reportDeactivateApi(token, userexternalid, content_external_id);
    // dispatch(reportDeactivateApi(token, external_Id, content_external_id));
    // console.log(res)
    if (res.response.statusCode === 200) {
      setShow(true)
      setShowReportBtn(content_external_id)
      setTimeout(() => {
        setShow(false)
        // window.location.reload(true);
      }, 4000);
    }

  }
  const getCountryName = (countryId) => {
    return countries.map((country) => {
      if (country.country_external_id === countryId)
        return country.country_name
    })
  }

  let activityCards = contentsArray && contentsArray.length > 0 && contentsArray.map((item, index) => {
    // if (contentsArray.length != index ) {
    if (contentsArray.length === index + 1) {
      return (
        <>
          {/* <Card className="Activitypage-card" key={item.content_external_id} ref={lastElementInContent}> */}
          <Card className="Activitypage-card" ref={lastElementInContent}>
            <Card.Header className="activitypage-header">

              <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                  <Image className="profile-picture" src={avatar} roundedCircle />
                  <div style={{ marginLeft: "1rem", fontWeight: "500" }}>
                    <span>{item.username}</span>
                    <div style={{ marginBottom: "0", fontWeight: "400" }}>{getPostedTime(item.content_created_on)}</div>
                    {/* <div style={{ marginBottom: "0", fontWeight: "400" }}>10 min</div> */}
                  </div>
                </div>
                {/* <ReportPost contentExternalId={item.content_external_id} /> */}
              </div>

            </Card.Header>

            <Card.Body>
              {/* <Card.Text>{item.content_description}</Card.Text> */}

              {item.content_description ? (
                <>
                  {item.content_description.length <= len && (
                    <Card.Text>{item.content_description}</Card.Text>
                  )}
                  {item.content_description.length > len &&
                    showFullDesc.id !== item.content_external_id && (
                      <Card.Text>
                        {item.content_description.substring(0, len) + "..."}
                        <span
                          style={{ cursor: "pointer", color: "#2077B1" }}
                          onClick={() =>
                            setShowFullDesc({
                              ...showFullDesc,
                              id: item.content_external_id,
                            })
                          }
                        >
                          {" "}
                          See more
                        </span>
                      </Card.Text>
                    )}
                  {item.content_description.length > len &&
                    showFullDesc.id === item.content_external_id && (
                      <Card.Text>
                        {item.content_description}
                        <span
                          style={{ cursor: "pointer", color: "#2077B1" }}
                          onClick={() =>
                            setShowFullDesc({ ...showFullDesc, id: "" })
                          }
                        >
                          {" "}
                          See less
                        </span>
                      </Card.Text>
                    )}

                </>
              ) : null}



            </Card.Body>

            <div className='image-slider-container'>
              {/*----- Multiple content slider -----*/}
              <ContentSlider item={item} />
            </div>
            <div className="post_info">
              <div className="pool_count sub-text">{item.total_people_pooled} people pooled</div>
                <div className="pool_amount">{moneyIcon}<span className="amount_display">{item.total_amount_pooled_currency} {Number(item.total_amount_pooled).toFixed(2)}</span> <span className="sub-text">Pool Collected</span></div>
              <ReportPost contentId={item.content_external_id} />
              {/* <Button onClick={()=>handleRep()} id="report">Report</Button> */}
              {/* <span className="mr-5" style={{color:"#2077b1",cursor:"pointer"}} onClick={() => handleDeactivation(item.content_external_id)}>Report</span> */}

            </div>
            <div style={{ backgroundColor: "rgba(32, 119, 177, 0.08)" }} className="activity-page-charity">
              <img src={item.cause_image} style={{ height: "40px", minWidth: "40px", maxWidth: "60px" }} />
              <ul className="activity-page-content">
                <li className="activity-page-charityName">{item.cause_name}</li>
                <li className="activity-page-charityLocation">{countries && getCountryName(item.country_external_id)}</li>
              </ul>
            </div>

          </Card>
        </>
      );
    }
    else {
      return (
        <>
          {/* <Card className="Activitypage-card" key={item.content_external_id} ref={lastElementInContent}> */}
          <Card className="Activitypage-card">
            <Card.Header className="activitypage-header">

              <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                  <Image className="profile-picture" src={avatar} roundedCircle />
                  <div style={{ marginLeft: "1rem", fontWeight: "500" }}>
                    <span>{item.username}</span>
                    <div style={{ marginBottom: "0", fontWeight: "400" }}>{getPostedTime(item.content_created_on)}</div>
                    {/* <div style={{ marginBottom: "0", fontWeight: "400" }}>10 min</div> */}
                  </div>
                </div>
                {/* <ReportPost contentExternalId={item.content_external_id} /> */}
              </div>

            </Card.Header>

            <Card.Body>
              {/* <Card.Text>{item.content_description}</Card.Text> */}

              {item.content_description ? (
                <>
                  {item.content_description.length <= len && (
                    <Card.Text>{item.content_description}</Card.Text>
                  )}
                  {item.content_description.length > len &&
                    showFullDesc.id !== item.content_external_id && (
                      <Card.Text>
                        {item.content_description.substring(0, len) + "..."}
                        <span
                          style={{ cursor: "pointer", color: "#2077B1" }}
                          onClick={() =>
                            setShowFullDesc({
                              ...showFullDesc,
                              id: item.content_external_id,
                            })
                          }
                        >
                          {" "}
                          See more
                        </span>
                      </Card.Text>
                    )}
                  {item.content_description.length > len &&
                    showFullDesc.id === item.content_external_id && (
                      <Card.Text>
                        {item.content_description}
                        <span
                          style={{ cursor: "pointer", color: "#2077B1" }}
                          onClick={() =>
                            setShowFullDesc({ ...showFullDesc, id: "" })
                          }
                        >
                          {" "}
                          See less
                        </span>
                      </Card.Text>
                    )}

                </>
              ) : null}



            </Card.Body>

            <div className='image-slider-container'>
              {/*----- Multiple content slider -----*/}
              <ContentSlider item={item} />
            </div>
            <div className="post_info">
              <div className="pool_count sub-text">{item.total_people_pooled} people pooled</div>
                <div className="pool_amount">{moneyIcon}<span className="amount_display">{item.total_amount_pooled_currency} {Number(item.total_amount_pooled).toFixed(2)}</span> <span className="sub-text">Pool Collected</span></div>
              <ReportPost contentId={item.content_external_id} />
              {/* <Button onClick={()=>handleRep()} id="report">Report</Button> */}
              {/* <span className="mr-5" style={{color:"#2077b1",cursor:"pointer"}} onClick={() => handleDeactivation(item.content_external_id)}>Report</span> */}

            </div>
            <div style={{ backgroundColor: "rgba(32, 119, 177, 0.08)" }} className="activity-page-charity">
              <img src={item.cause_image} style={{ height: "40px", minWidth: "40px", maxWidth: "60px" }} />
              <ul className="activity-page-content">
                <li className="activity-page-charityName">{item.cause_name}</li>
                <li className="activity-page-charityLocation">{countries && getCountryName(item.country_external_id)}</li>
              </ul>
            </div>

          </Card>
        </>
      );
    }

  });



  const showMessage = () => {

    if (loading) {
      return <div style={{ height: "50px", fontWeight: "700" }}>Please Wait...</div>
    }
    if (error) {
      return <div style={{ height: "50px", fontWeight: "700" }}>Something went wrong. Please try reloading the page to logging in again</div>
    }
    if (!hasMore) {
      return <div style={{ height: "50px", fontWeight: "700" }}></div>
    }

  }

  return (
    <>
      {/* <Comments user_external_id={tokenDetails.user_external_id} token={tokenDetails.token} /> */}
      {contentsArray.length === 0 && <p>No data to show</p>}
      {activityCards}
      {showMessage()}
    </>

  )
}

export default Contents;


