import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import NavBar from "./DashboardNavbar"
import { Container, Row, Col, Card, NavLink, Nav, Dropdown, DropdownButton } from "react-bootstrap";

import '../../assets/scss/Dashboard/CharityProfile.scss'
// import "../../assets/scss/ContentNavBar/contentNavBar.scss";
import dropdownButtonArrow from '../../assets/svg/dropdownButtonArrow.svg';
import { getCharityID } from '../../actions/storeCharityID';

import data from './charityProfileData.json'
import { adminCharityInfo } from "../../actions/AdminCharityInfo";
import { loadCharityNames } from "../../actions/CharityList";

const location = (
    <svg width="11" height="14" viewBox="0 0 11 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5.07027 0C2.27451 0 0 2.27451 0 5.07025C0 8.53985 4.5374 13.6334 4.73058 13.8486C4.91204 14.0507 5.22884 14.0503 5.40997 13.8486C5.60315 13.6334 10.1405 8.53985 10.1405 5.07025C10.1405 2.27451 7.86601 0 5.07027 0ZM5.07027 7.62123C3.66366 7.62123 2.51932 6.47687 2.51932 5.07025C2.51932 3.66363 3.66368 2.51929 5.07027 2.51929C6.47687 2.51929 7.6212 3.66366 7.6212 5.07028C7.6212 6.47689 6.47687 7.62123 5.07027 7.62123Z" fill="#757575" />
    </svg>

)


const CharityProfile = () => {
    // const [countryName, setCountryName] = useState("");
    const [countryID, setCountryID] = useState("");
    // const [charity, setCharity] = useState("");
    const charityID_Value = useSelector(state => state.CharityIDReducer.charityID);

    const [charityID, setCharityID] = useState(() => charityID_Value);
    const charityNames = useSelector((state) => state.charityNames.data);
    const userType = useSelector(state => state.newUserToken.user_type);
    const countriesList = useSelector(state => state.countriesList.data);

    let charityDetails = useSelector(state => state.newUserToken.cause_info);
    const AdminCharityInfo = useSelector(state => state.AdminCharityInfoIDReducer);
    
    const adminCharityName = useSelector(state => state.AdminCharityInfoIDReducer.cause_name)
    const adminCharityCountry = useSelector(state => state.AdminCharityInfoIDReducer.country_name)
    const [countryName, setCountryName] = useState(()=> adminCharityCountry ? adminCharityCountry : "");
    const [charity, setCharity] = useState(()=> adminCharityName ? adminCharityName : "");
    const countryAdminCountryId = useSelector(state => state.newUserToken.country_external_id);
    const countryAdminCountryName = useSelector(state => state.newUserToken.country_name);
    const dispatch = useDispatch();
  
    //assign admin charity infor to charityDetails as the response is same
    if (userType === "superadmin" || userType === "admin" || userType === "countryadmin") {
        charityDetails = AdminCharityInfo;
    }

    useEffect(() => {
        if (userType === "superadmin" || userType === "admin" || userType === "countryadmin") {
            // console.log(charityID)
            dispatch(adminCharityInfo(charityID))
        }
    }, [charityID])

    useEffect(() => {
        if (countryID) {
            dispatch(loadCharityNames(countryID))
        }
    }, [countryID])

    const handleCountry = (e) => {
        const name = e ? e.split(",") : "";
        let countryExternalId = name[0];

        if (countryExternalId !== "") {
            setCountryID(countryExternalId);
            Object.values(countriesList).map((country) => {
                if (country.country_external_id === countryExternalId) {
                    setCountryName(country.country_name);
                    setCharity("");
                    setCharityID("");
                }
            });
        } else if (countryExternalId === "") {
            setCountryID("");
            setCountryName("");
            setCharity("");
            setCharityID("");
        }
    };

    const text_truncate = (str, length, ending) => {
        if (length == null) {
            length = 100;
        }
        if (ending == null) {
            ending = "...";
        }
        if (str.length > length) {
            return str.substring(0, length - ending.length) + ending;
        } else {
            return str;
        }
    };

    const handleCharity = (e) => {
        if (e !== null) {
            const charity = e;
            const name = charity.split(",");
            setCharityID(name[0]);
            setCharity(name[1]);
            dispatch(getCharityID(name[0]));
        } else {
            setCharity("");
            setCharityID("");
        }
    };

    return (
        <div style={{ backgroundColor: '#E5E5E5' }} className="outer-profile-container">
            <NavBar />
            <Container className="profile-container">
                <Row style={{backgroundColor:"white"}}>
                    <Col sm={12} md={10} lg={12} className="mx-auto">
                        {(userType == "admin" || userType == "superadmin" || userType == "countryadmin") &&
                            <>
                                <Row className="admin-dropdowns" style={{display:"flex",justifyContent:"center",marginTop:"3rem"}}>
                                {/* ------ Country dropdown ------ */}
                                <div className="dropdown-container dropdown-country">
                                    <img
                                        className="dropdown-arrow"
                                        src={dropdownButtonArrow}
                                        alt="dropdownButtonArrow"
                                    />

                                    <DropdownButton
                                    disabled = {countryAdminCountryId ? true : false}

                                        className="dropdown-btn"
                                        onSelect={handleCountry}
                                        title={
                                            <span className="dropdown-span">
                                                {countryName
                                                    ? text_truncate(countryName, 18)
                                                    : "Country"}
                                            </span>
                                        }
                                    >
                                        <div className="dropdown-options">
                                            <Dropdown.Item eventkey=" ">Country</Dropdown.Item>
                                            {countriesList && Object.values(countriesList).map(
                                                (countryData, index) => (
                                                    <Dropdown.Item
                                                        eventKey={`${countryData.country_external_id},${countryData.country_name}`}
                                                        key={countryData.country_external_id}
                                                    >
                                                        {countryData.country_name}
                                                    </Dropdown.Item>
                                                )
                                            )}
                                        </div>
                                    </DropdownButton>
                                </div>

                                {/* ------ Charity dropdown ------ */}
                                <div className="dropdown-container dropdown-charity">
                                    <img
                                        className="dropdown-arrow"
                                        src={dropdownButtonArrow}
                                        alt="dropdownButtonArrow"
                                    />

                                    <DropdownButton
                                        disabled={countryName ? false : true}
                                        className="dropdown-btn"
                                        onSelect={handleCharity}
                                        title={
                                            <span className="dropdown-span">
                                                {charity ? text_truncate(charity, 25) : "Charity"}
                                            </span>
                                        }
                                    >
                                        <Dropdown.Item eventkey=" ">Charity</Dropdown.Item>
                                        {charityNames && charityNames.map((cause, index) => {
                                            return (
                                                <Dropdown.Item
                                                    eventKey={`${cause.cause_external_id}, ${cause.cause_name}`}
                                                    key={cause.cause_external_id}
                                                >
                                                    {cause.cause_name}
                                                </Dropdown.Item>
                                            )
                                        })}
                                    </DropdownButton>
                                </div>
                                </Row>
                            </>
                        }
                    </Col>
                </Row>
                <Row>

                    <Col className="charity-info">
                        <div className="logo my-auto">
                            <img style={{ width: "13rem", height: "13rem", objectFit: "contain" }} src={charityDetails.cause_image} alt="" />
                        </div>
                        <div className="heading my-auto">
                            <div className="title ml-5">{charityDetails.cause_name}</div>
                            <div className="location ml-5"><span className="mx-2">{location}</span>{charityDetails.country_name}</div>
                        </div>
                    </Col>
                </Row>
                <Row className="info-bar">
                    <Col>
                        <Nav style={{ marginLeft: '18%'}}>
                            <Nav.Item>
                                <NavLink
                                    id="info"
                                    activeClassName="active-button"
                                    className="navbar__link"
                                >
                                    Info
                            </NavLink>
                            </Nav.Item>
                        </Nav>
                    </Col>
                </Row>
                <Row >
                    <Col sm={12} md={12} lg={8} className="mx-auto">
                        <Card className="details p-3 mb-3">
                            <Card.Title>Charity Details</Card.Title>
                            <Card.Text>
                                {charityDetails.cause_description}
                            </Card.Text>
                            <Card.Text>
                                <p style={{ color: '#575757', fontFamily: 'DM Sans', fontSize: '16px' }}>Find out more about charity at <a href={`${charityDetails.cause_url}`} target="_blank" rel="noopener noreferrer">{charityDetails.cause_url}</a>
                                </p>
                            </Card.Text>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default CharityProfile;