import React, { useState, useEffect, useCallback } from "react";
import {Row,Col,Table ,Button,Container,Dropdown,DropdownButton} from 'react-bootstrap'
import "../../assets/scss/DashboardReports/reportsStyles.scss"
import { useSelector, useDispatch } from "react-redux";
import calenderImg from "../../assets/svg/calenderIcon.svg";
import SelectDatepicker from 'react-select-datepicker';

import {LoadedAmount} from '../../api/AdminReportApis/LoadedAmount.js'
const AmountLoaded = () => {
  const [date, setDate] = useState("");
  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");
  const [collectedAmt, setCollectedAmt] = useState("");
  const countriesList = useSelector(state => state.countriesList.data);
  const [fromDate, setFromDate] = useState();
  const [showFromDate, setShowFromDate] = useState(false);
  const [showToDate, setShowToDate] = useState();
  const [toDate, setToDate] = useState(new Date());
  
  const [totalCollectedAmt, setTotalCollectedAmt] = useState("");

//   function Formatter(num) {
//     return Math.abs(num) > 999 ? Math.sign(num)*((Math.abs(num)/1000).toFixed(2)) + 'k' : Math.sign(num)*Math.abs(num)
// }

useEffect(()=>{
  let filterFromDate = new Date();
  filterFromDate.setHours(0);
  filterFromDate.setMinutes(0);
  filterFromDate.setSeconds(0);
  setFromDate(new Date(filterFromDate))
},[])



  useEffect(() => {
    async function fetchData() {
      if(fromDate && toDate){
        let offsetTimeFromDate = new Date(fromDate.getTime() - fromDate.getTimezoneOffset() * 60 * 1000);
        let offsetTimeToDate = new Date(toDate.getTime() - toDate.getTimezoneOffset() * 60 * 1000);
        let la = await LoadedAmount(offsetTimeFromDate,offsetTimeToDate)
        setTotalCollectedAmt(la.total_amount_loaded_usd)
        setCollectedAmt(la.total_loaded_country)
      }
  }
  fetchData();
  }, [fromDate,toDate]);

  const onFromDateChange = useCallback((date) => {
    setFromDate(date);
  }, []);

  const handleShowToDate = () => {
    setShowToDate(!showToDate)
    setShowFromDate(false)
  }
  const handleShowFromDate = () => {
   
    setShowFromDate(!showFromDate)
    setShowToDate(false)
  }
  const onToDateChange = useCallback((date) => {
    if(date && (date.toString().substring(0,15) === new Date().toString().substring(0,15))){
      setToDate(new Date());
    }
    else {
    setToDate(date);
    }
  }, []);

  const handleRange = async () => {
    setShowFromDate(false)
    setShowToDate(false)
    let offsetTimeFromDate = new Date(fromDate.getTime() - fromDate.getTimezoneOffset() * 60 * 1000);
    let offsetTimeToDate = new Date(toDate.getTime() - toDate.getTimezoneOffset() * 60 * 1000);
    let la = await LoadedAmount(offsetTimeFromDate,offsetTimeToDate)
    setCollectedAmt(la.total_loaded_country)
    setTotalCollectedAmt(la.total_amount_loaded_usd)
  }

    return (
      <div style={{backgroundColor:"#f2f2f2",fontFamily:"DM Sans", width:"100%",paddingTop:"4rem",minHeight:"700px"}}>
      <Container>
        
            <Row className="charities-adminreport">
            <Col lg={8} className="charities-table-header">Amount Loaded</Col>
            <Col lg={4} className="admin-dropdowns" style={{display:"flex",justifyContent:"flex-end"}}>
                
                {/* Filter Date */}
                {/* <div className="admin-dropdowns">
                  <div className="dropdown-container dropdown-month">
                      <img
                        className="dropdown-arrow"
                        src={calenderImg}
                        alt="dropdownButtonArrow"
                      />
  
                      <DropdownButton
                        className="dropdown-btn"
                        onSelect={handleDate}
                        title={
                          <span className="dropdown-span">
                            {date ? date : "Lifetime"}
                          </span>
                        }
                      >
                        <Dropdown.Item eventKey="today">Today</Dropdown.Item>
                        <Dropdown.Item eventKey="week">This week</Dropdown.Item>
                        <Dropdown.Item eventKey="month">This month</Dropdown.Item>
                        <Dropdown.Item eventKey="lifetime">Lifetime</Dropdown.Item>
                      </DropdownButton>
                    </div>
                    </div>
                 */}
                 <div className="date-container-charityList">
              <div onClick={handleShowFromDate} className="datePicker">{fromDate ? fromDate.toString().substring(4, 15) : "From"}
                <img className='dropdown-calIcon' src={calenderImg} alt='dropdownButtonArrow' />
              </div>
              {showFromDate && <div className="selectDatePicker">
                <SelectDatepicker
                  value={fromDate}
                  onDateChange={onFromDateChange}
                  minDate={new Date(2018, 0, 1)}
                  maxDate={new Date()}
                  showLabels={false}
                  monthNames={["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]}
                  format={"day/month/year"}
                />
              </div>
              }
              {toDate && fromDate && console.log(toDate,toDate.getTime() - fromDate.getTime())}
              {(fromDate && toDate && !showToDate) && ((toDate.getTime() - fromDate.getTime() <= 86400000) && (toDate.toString().substring(0,15) === new Date().toString().substring(0,15)) ) ? 
              <div style={{pointerEvents:"none",opacity:"0.7"}} className="datePicker">{toDate ? toDate.toString().substring(4, 15) : "To"}
                <img className='dropdown-calIcon' src={calenderImg} alt='dropdownButtonArrow' />
              </div> : 
              <div onClick={handleShowToDate} className="datePicker">{toDate ? toDate.toString().substring(4, 15) : "To"}
              <img className='dropdown-calIcon' src={calenderImg} alt='dropdownButtonArrow' />
            </div>
             }
              {showToDate &&
                <div className="selectDatePicker">
                  <SelectDatepicker
                    value={toDate}
                    onDateChange={onToDateChange}
                    minDate={fromDate ? fromDate : new Date(2018, 0, 1)}
                    maxDate={new Date()}
                    showLabels={false}
                    monthNames={["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]}
                    format={"day/month/year"}
                  />
                </div>
              }
            <Button onClick={handleRange} className="go ml-2">Go</Button>
            </div>
           </Col>
          
            <Table className="table">
              <thead>
                <tr>
                  <th>SL No.</th>
                  <th style={{width:'100px',textAlign:'start'}}>Country</th>
                  <th style={{textAlign:'start'}}>Amount Loaded in USD</th>
                  <th style={{textAlign:'start'}}>Amount Loaded in Local Currency</th>
                </tr>
              </thead>
              <tbody>
              {collectedAmt && collectedAmt.map((item,index) => {
                 return <tr>
                  <td>{index+1}</td>
                  <td style={{textAlign:'start'}}>
                    <span>{item.country_name}</span>
                  </td>
                  
                  <td style={{textAlign:'start',paddingLeft:"4rem"}}>$ {item.amout_loaded_usd}</td>
                  <td style={{textAlign:'start',paddingLeft:"4rem"}}>{item.country_wallet_currency_sign} {item.amount_loaded} {`(${item.country_currency_code})`}</td>
                </tr>

                  })}
                  <tr>
                    <td></td>
                    <td style={{width:'100px',textAlign:'start',fontWeight:"500"}}>Total</td>
                    <td style={{textAlign:"start",paddingLeft:"4rem",fontWeight:"500"}}>{ totalCollectedAmt ? `$ ${totalCollectedAmt}` : 0}</td>
                    <td></td>
                  </tr>
              </tbody>
            </Table>
          </Row>
          </Container>
          </div>
    );
   
};

export default AmountLoaded;


