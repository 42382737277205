import axios from 'axios';
import { BASEURL } from '../config';
import { All_COUNTRY_LIST,STRIPE_COUNTRY_LIST } from '../constants/action-types';

export const storeCountriesList = () => dispatch => {
    axios.post(`${BASEURL}/v1/adminDashboard/list-countries`)
        .then(allCountryList => {
            dispatch({
                type: All_COUNTRY_LIST,
                payload: allCountryList.data.response
            })
        })
        .catch(err => console.log(err))
}



// export const storeStripeCountries = () => dispatch => {
//     axios.post(`${BASEURL}/v1/adminDashboard/list-countries`)
//         .then(stripeCountryList => {
//             dispatch({
//                 type: STRIPE_COUNTRY_LIST,
//                 payload: stripeCountryList.data.response
//             })
//         })
//         .catch(err => console.log(err))
// }


export const storeStripeCountries = () => dispatch => {
    return axios({
        url: `${BASEURL}/v1/adminDashboard/list-countries`,
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Access-Control-Allow-Origin': '*',
            // 'Authorization': `Bearer ${token}`,
            // 'userexternalid': external_Id,
        },
        data: {
            "pool_source_id" : 1
        },
        withCredentials: true,
    })
    .then(stripeCountryList => {
        dispatch({
            type: STRIPE_COUNTRY_LIST,
            payload: stripeCountryList.data.response
        })
    })
    .catch(err => console.log(err))
}
