import axios from 'axios'
import { BASEURL } from '../config'
import {
  AMOUNT_DETAILS, AMOUNT_DETAILS_IN_RANGE
} from "../constants/action-types";

//Admin apis
export const adminGetLiveDataInRange = (from, to, cause_id) => {
  return axios({
    url: `${BASEURL}/v1/adminDashboard/charity/charity-statistics`,
    method: "POST",
    headers: {
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    },
    data: {
      cause_external_id: cause_id,
      from_date: from,
      to_date: to,
    }
  })
    // .then((response) =>
    // dispatch({
    //   type: AMOUNT_DETAILS_IN_RANGE,
    //   payload: response.data.response.data,
    // })
    .then((response) => response.data.response.data)
    .catch((err) => console.error(err));
};

export const adminGetLiveData = (cause_id) => dispatch => {
  return axios({
    url: `${BASEURL}/v1/adminDashboard/charity/charity-statistics`,
    method: "POST",
    headers: {
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    },
    data: {
      cause_external_id: cause_id
    }
  })
    .then((response) =>
      dispatch({
        type: AMOUNT_DETAILS,
        payload: response.data.response.data,
      })
    )
    .catch((err) => console.error(err));
};


//charity apis
export const getLiveDataInRange = (from, to) => {
  return axios({
    url: `${BASEURL}/v1/charityDashboard/charity/getTotalAmount`,
    method: "POST",
    headers: {
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    },
    data: {
      from_date: from,
      to_date: to,
    }
  })
    // .then((response) =>
    // dispatch({
    //   type: AMOUNT_DETAILS_IN_RANGE,
    //   payload: response.data.response.data,
    // })
    .then((response) => response.data.response.data)
    .catch((err) => console.error(err));
};

export const getLiveData = () => dispatch => {
  return axios({
    url: `${BASEURL}/v1/charityDashboard/charity/getTotalAmount`,
    method: "POST",
    headers: {
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    },
  })
    .then((response) =>
      dispatch({
        type: AMOUNT_DETAILS,
        payload: response.data.response.data,
      })
    )
    .catch((err) => console.error(err));
};


//Get all the details(without charity Id)
export const AllAdminStats = () => dispatch => {
  return axios({
    url: `${BASEURL}/v1/adminDashboard/charity/all-charity-statistics`,
    method: "POST",
    headers: {
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    }
  })
    .then((response) =>
      dispatch({
        type: AMOUNT_DETAILS,
        payload: response.data.response.data,
      })
    )
    .catch((err) => console.error(err));
};

export const AllAdminStatsInRange = (from,to) => {
  return axios({
    url: `${BASEURL}/v1/adminDashboard/charity/all-charity-statistics`,
    method: "POST",
    headers: {
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    },
    data: {
      from_date: from,
      to_date: to,
    }
  
  })
  .then((response) => response.data.response.data)
  .catch((err) => console.error(err));
};