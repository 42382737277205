import { Link } from "react-router-dom";
import React, { useEffect, useState ,useRef} from "react";
import { Card, Button, Container, Row, Image, Form, Alert, Col, InputGroup } from 'react-bootstrap';
import { useSelector,useDispatch } from 'react-redux';
import axios from "axios";


//components imports
import { GoogleLogin, FacebookLogin, Nexmo } from "../index";



import LoginNavbar from '../CharityNavbar/LoginNavbar';
import Footer from "../CharityFooter/Footer";
//css imports
// import "../../assets/scss/Login/loginPage.scss";

//CSS imports
// import '../../assets/scss/CharityLogin/loginPage.scss';
import '../../assets/scss/CharityLogin/createNewPassword.scss';


import x from '../../assets/svg/x.svg';


const CreateNewPassword = () => {

    const [Password, setPassword] = useState("");
    const [CurrentPassword, setCurrentPassword] = useState("");
    const [showPasswordIcon, setPasswordIcon] = useState(false);
    const [showReenterPasswordIcon, setReenterPasswordIcon] = useState(false);
    const [showCurrentPasswordIcon, setShowCurrentPasswordIcon] = useState(false);
    const [passwordMatchedmessage, setPasswordMatchedMessage] = useState("");
    const [ReTypedPassword, setReTypedPassword] = useState("");
    const [passwordMessage, setpasswordMessage] = useState("");
    
    const [changePasswordBtn, setChangePasswordBtn] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const [passwordRuleMsg, setPasswordRuleMsg] = useState("Use 8 or more characters with a mix of letters, numbers, symbols and not same as UserName");
    const [passwordValidation, setPasswordValidation] = useState("");
    const [pwdSuccessMsg, setPwdSuccessMsg] = useState("");

    const dispatch = useDispatch();
    const inputRef = useRef();

    const showPassword = <svg width="19" height="14" viewBox="0 0 19 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 7C1 7 4 1 9.25 1C14.5 1 17.5 7 17.5 7C17.5 7 14.5 13 9.25 13C4 13 1 7 1 7Z" stroke="#757575" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M9.25 9.25C10.4926 9.25 11.5 8.24264 11.5 7C11.5 5.75736 10.4926 4.75 9.25 4.75C8.00736 4.75 7 5.75736 7 7C7 8.24264 8.00736 9.25 9.25 9.25Z" stroke="#757575" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    </svg>

    const hidePassword = <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.5418 10.5417C10.3421 10.756 10.1012 10.928 9.83357 11.0472C9.56593 11.1665 9.27702 11.2306 8.98407 11.2357C8.69111 11.2409 8.40012 11.187 8.12844 11.0773C7.85677 10.9676 7.60998 10.8042 7.4028 10.597C7.19562 10.3899 7.03229 10.1431 6.92255 9.8714C6.81282 9.59972 6.75893 9.30873 6.7641 9.01577C6.76927 8.72282 6.83339 8.43391 6.95264 8.16628C7.07189 7.89864 7.24382 7.65777 7.45818 7.45802M13.32 13.3198C12.0768 14.2675 10.563 14.7925 9 14.818C3.90909 14.818 1 8.99984 1 8.99984C1.90465 7.31395 3.15937 5.84101 4.68 4.67984L13.32 13.3198ZM7.47273 3.3562C7.97333 3.23903 8.48586 3.18045 9 3.18166C14.0909 3.18166 17 8.99984 17 8.99984C16.5585 9.82574 16.032 10.6033 15.4291 11.3198L7.47273 3.3562Z" stroke="#757575" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M1 1L17 17" stroke="#757575" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    </svg>

    useEffect(() => {
    if ((Password === ReTypedPassword) && (Password.length > 0)) {
        setChangePasswordBtn(true);
        setIsLoading(false);
        setpasswordMessage("")
        setPasswordMatchedMessage("Password Match");
    }
    else if (ReTypedPassword.length > 0) {
        setChangePasswordBtn(false);
        setIsLoading(false);
        setpasswordMessage("Passwords don't match. Enter valid password")
        setPasswordMatchedMessage("");
    }
}, [Password, ReTypedPassword])

    useEffect(() => {
        if ((Password === ReTypedPassword) && (Password.length > 0)) {
            setChangePasswordBtn(true);
        }
}, [Password, ReTypedPassword])

    const myFunction = () => {
        var x = document.getElementById("myInput");
        if (x.type === "password") {
            x.type = "text";
            setPasswordIcon(true);

        } else {
            x.type = "password";
            setPasswordIcon(false);
           


        }
    }

    const handleCurrentPassword = () => {
        var x = document.getElementById("myOldPassword");
        if (x.type === "password") {
            x.type = "text";
            setShowCurrentPasswordIcon(true);

        } else {
            x.type = "password";
            setPasswordIcon(false);
            setShowCurrentPasswordIcon(false);


        }
    }

    const reEnterPassword = () => {
        var x = document.getElementById("ReEnterPasswordInput");
        if (x.type === "password") {
            x.type = "text";
            setReenterPasswordIcon(true);
        } else {
            x.type = "password";
            setReenterPasswordIcon(false);
        }
    }

    const passwordValidate = () => {
        if (Password.length > 0) {
            var lower = /(?=.*[a-z])/;
            var upper = /(?=.*[A-Z])/;
            var value = /(?=.*\d)/;
            var spclchar = /(?=.*[!@#$%^&*])/;
            if (Password.length < 8) {
                inputRef.current.focus();
                setPasswordValidation("Password should be atleast minimum 8 characters");
                setPasswordRuleMsg("");
                // setPwdSuccessMsg("");
                return false;
            }
            else if (!lower.test(Password)) {
                setPasswordValidation("Password should contain one LowerCase");
                setPasswordRuleMsg("");
                inputRef.current.focus();
                // setPwdSuccessMsg("");
                return false;
            }
            else if (!upper.test(Password)) {
                setPasswordValidation("Password should contain one UpperCase");
                setPasswordRuleMsg("");
                inputRef.current.focus();
                // setPwdSuccessMsg("");
                return false;
            }
            else if (!value.test(Password)) {
                setPasswordValidation("Password should contain atleast one Number");
                setPasswordRuleMsg("");
                inputRef.current.focus();
                // setPwdSuccessMsg("");
                return false;
            }
            else if (!spclchar.test(Password)) {
                setPasswordValidation("Password should contain atleast one special character");
                setPasswordRuleMsg("");
                inputRef.current.focus();
                // setPwdSuccessMsg("");
                return false;
            }
            else {
                setPasswordValidation("");
                setPwdSuccessMsg("Password is strong");
                setPasswordRuleMsg("");
                return true;
            }
        }

    }
  return (
      
    <div className="new-password-container">
      <LoginNavbar />
    
    <Container>
        <div className="row">
            <div className="col-sm-12">
                <Card className="card">
                    <Card.Header className="header">Create New Password
                     <Link to="/" className="close">
                            <img src={x} alt="close-button" /></Link></Card.Header>
                    <Card.Body>
                        {/* <Form className="password-form" onSubmit={(e) => sendLink(e, Email)}> */}
                        <Form className="password-form" >
                            {/* <Form.Control size="lg" type="email" id="myInput" placeholder="Email address" onChange={e => setEmail(e.target.value)} value={Email} /> */}
                            <Form.Group className="password">
                                <Form.Label className="password-form-label">Current Password</Form.Label>
                                <InputGroup className="mb-3">  
                                <Form.Control size="lg" type="password" id="myOldPassword" placeholder="**********" required  onChange={e => setCurrentPassword(e.target.value)} value={CurrentPassword}/>
                                <InputGroup.Append>
                                                <InputGroup.Text id="basic-addon1">{showCurrentPasswordIcon ? <span onClick={handleCurrentPassword}>{showPassword}</span> : <span onClick={handleCurrentPassword}>{hidePassword}</span>}</InputGroup.Text>
                                    </InputGroup.Append>
                                    </InputGroup>
                            </Form.Group>
                            <p className="forgotten-password"><a href="/charity-resetpasswordlink">Forgotten Password?</a></p>
                            <Form.Label className="password-form-label">New Password</Form.Label>
                            <InputGroup className="mb-3">       
                                    <Form.Control size="lg" type="password" id="myInput" placeholder="**********" onChange={e => setPassword(e.target.value)} value={Password}/>
                                    <InputGroup.Append>
                                                <InputGroup.Text id="basic-addon1">{showPasswordIcon ? <span onClick={myFunction}>{showPassword}</span> : <span onClick={myFunction}>{hidePassword}</span>}</InputGroup.Text>
                                    </InputGroup.Append>
                            </InputGroup>
                          
                            <Form.Label className="password-form-label">Re-Type New Password</Form.Label>
                            <InputGroup className="mb-3">
                                         <Form.Control size="lg" type="password" placeholder="**********" id="ReEnterPasswordInput" onChange={e => setReTypedPassword(e.target.value)} value={ReTypedPassword}/>
                                        <InputGroup.Append>
                                            <InputGroup.Text id="basic-addon2">{showReenterPasswordIcon ? <span onClick={reEnterPassword}>{showPassword}</span> : <span onClick={reEnterPassword}>{hidePassword}</span>}</InputGroup.Text>
                                        </InputGroup.Append>
                            </InputGroup>
                            <span className="msg" style={{ color: "red"}}>{passwordMessage}</span>
                            <span className="msg" style={{ color: "green" }}>{passwordMatchedmessage}</span>
                            <Button className="submit-btn" type="submit">Change Password</Button>
                        </Form>
                    </Card.Body>
                </Card>
            </div>
        </div>
    </Container>
    <Footer />
    </div>
   
  );
};

export default CreateNewPassword;
