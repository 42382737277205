import React, { useEffect, useState } from "react";
import { Container, Row, Col, Nav } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

// import { Link } from "react-router-dom";
// import { useSelector, useDispatch } from "react-redux";

import appleStore from "../../assets/svg/appstore_1.svg";
import googleStore from "../../assets/svg/google-play-badge_2.svg"
import flame from "../../assets/svg/flame.svg";

// import iphone from "../../assets/images/iphone.png";
// import android from "../../assets/images/android.png";
// import downArrow from "../../assets/images/down-arrow.png";

import LoginNavbar from "./LoginNavbar";
import LeanmoreFooter from "./LeanmoreFooter";
import "../../assets/scss/LandingLearnMore/landing.scss";
import IntroVideo from "./IntroVideo";
import PasswordScreen from "./PasswordScreen";

import { storeCountriesList } from "../../actions/countryList";
// import { loadCharityNames } from '../../actions/CharityList';

const LandingPage = (props) => {
  const [visible, setVisible] = useState(false);
  const dispatch = useDispatch();
  const tempPassword = useSelector(state => state.tempPassword);

  const isAuthorized = useSelector(state => state.isUserAuthorized.isAuthorized)

  // useEffect(() => {
  //   props.history.push('/login');
  // })

  useEffect(() => {
    if (isAuthorized) {
      props.history.push('/home');
    } else {
      props.history.push('/login');
    }
  }, [isAuthorized])

  // useEffect(() => {
  //   setVisible(tempPassword.tempToken);
  // })

  // useEffect(() => {
  //   if (tempPassword.tempToken) {
  //     // dispatch(loadCharityNames());
  //     dispatch(storeCountriesList());
  //   }
  // }, [tempPassword])

  return (
    <div></div>

  );
};

export default LandingPage;
