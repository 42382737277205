import React, { useEffect } from 'react';
import ActivityPage from './ActivityPage';
import NavBar from "./DashboardNavbar"

import '../../assets/scss/Dashboard/DashboardHome.scss'

const DashboardHome = () => {

    return (
        <>

            <div style={{ backgroundColor: "rgb(242, 242, 242)" }}>
                <div className="header" id="header">
                    <NavBar />
                </div>
                <ActivityPage />
            </div>
        </>
    )
}

export default DashboardHome 